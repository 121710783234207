<template>
  <div class="environment" :class="environment" v-if="environment !== 'production'" @click="showDebugInfo = !showDebugInfo">
    {{ environment.toUpperCase() }}
  </div>
  <textarea v-if="showDebugInfo" class="debuginfo" v-model="debugInfo" rows="10"></textarea>
  <GlobalHeader :environment="environment" />
  <Notification />
  <router-view />
  <div id="cookieConsent" v-if="environment === 'production' && cookieConsentVisibility">
    <p>
      このウェブサイトでは、Cookie（クッキー）を使用しています。
      Cookieにはウェブサイトの動作に必要不可欠なものと、ユーザーの利便性向上を目的とした任意のものがあります。
      任意のCookieの使用にご同意いただける場合は「同意する」ボタンを押下してください。
      Cookieに関する詳細は、<router-link to="/tos">利用規約</router-link>をご覧ください。
    </p>
    <button type="button" class="btn btn-primary" @click="cookieConsent(true)">同意する</button>
    <button type="button" class="btn btn-link" @click="cookieConsent(false)">同意しない</button>
  </div>
  <GlobalFooter :environment="environment" />
</template>

<style>
html, body {
  height: 100%;
}
#app {
  width: 100%;
  padding-bottom: 86px;
  position: relative;
  min-height: 100%;
  text-spacing-trim: space-all;
}

/** 環境判別 */
div.environment {
  width: 300px;
  padding: 6px 14px;
  color: var(--text-light);
  font-size: 16px;
  text-align: center;
  left: 50%;
  top: 0;
  border-radius: 0 0 4px 4px;
  display: none;
  position: fixed;
  z-index: var(--zindex-ultra-high);
  transform: translate(-50%, 0);
}
  div.environment.staging {
    background-color: orange;
    display: block;
  }
  div.environment.development {
    background-color: var(--color-green);
    display: block;
  }

textarea.debuginfo {
  width: 40vw;
  top: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  z-index: var(--zindex-ultra-high);
}

/** Cookie Consent */
#cookieConsent {
  padding: 20px;
  width: 100%;
  bottom: 0;
  left: 0;
  color: #fff;
  background-color: #000000cc;
  display: flex;
  position: fixed;
  z-index: var(--zindex-ultra-high);
  transition: var(--anim-speed);
}
  #cookieConsent:hover {
    background-color: #000000;
    transition: var(--anim-speed);
  }
#cookieConsent p {
  margin: auto 10px;
}
#cookieConsent a,
#cookieConsent a:hover,
#cookieConsent a:active,
#cookieConsent a:visited {
  color: var(--text-light);
}
#cookieConsent button {
  margin: auto 4px;
  height: fit-content;
  color: var(--text-light);
  white-space: nowrap;
}

/** reCAPPTCHA */
.grecaptcha-badge {
  bottom: 86px !important;
}

footer {
  width: 100%;
  height: 86px;
  position: absolute;
  bottom: 0;
}
</style>

<script>
import { computed, defineComponent, ref } from "vue";
import { useGtm } from "@gtm-support/vue-gtm";
import { useStore } from "vuex";
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
import Notification from "@/components/Notification.vue";

export default defineComponent({
  components: {
    GlobalHeader,
    GlobalFooter,
    Notification,
  },
  setup() {
    /** vuex::store */
    const store = useStore();

    // 環境判定
    const environment = process.env.VUE_APP_ENV_NAME;

    // デバッグ情報表示フラグ
    const showDebugInfo = ref(false);

    // デバッグ情報内容
    const debugInfo = computed(() => {
      return JSON.stringify(store.getters.getOwnInfo, null, 2) + "\n" +
             JSON.stringify(store.getters.getOwnPermissions, null, 2) + "\n" +
             JSON.stringify(store.getters.getAssignedCustomers, null, 2) + "\n" +
             JSON.stringify(store.getters.getIsHQ, null, 2) + "\n" +
             JSON.stringify(store.getters.getSystemDate, null, 2);
    });

    /** Google Tag Manager */
    const gtm = useGtm();

    /** Cookie同意状況 */
    const cookieConsentState = ref(
      window.localStorage.getItem("cookieConsentState") === 'true'
    );

    // 同意済みの場合はGTM有効可
    if (cookieConsentState.value) {
      gtm.enable(true);
    }

    /** Cookie同意確認表示有無 */
    const cookieConsentVisibility = ref(
      window.localStorage.getItem("cookieConsentState") === null
    );

    /** Cookie同意確認結果処理 */
    const cookieConsent = bool => {
      // 同意結果を反映
      cookieConsentState.value = bool;
      window.localStorage.setItem("cookieConsentState", bool);

      // 次回以降、同意確認を表示しない
      cookieConsentVisibility.value = false;

      // 本番環境かつ同意した場合のみGTM有効化
      if (environment === "production" && bool) {
        gtm.enable(true);
      }
    }

    return {
      environment,              // 環境判定
      showDebugInfo,            // デバッグ情報表示フラグ
      debugInfo,                // デバッグ情報内容
      cookieConsentState,       // Cookie同意状況
      cookieConsentVisibility,  // Cookie同意確認表示有無
      cookieConsent,            // Cookie同意確認結果処理
    }
  },
});
</script>

