<template>
  <div class="container-xl mx-auto">
    <p class="my-3">
      <router-link to="/dashboard">＜ダッシュボードへ戻る</router-link>
    </p>

    <h2>サポートメニュー</h2>
    <div class="m-3 mb-5 row ope-menu">
      <!-- サービスに関するよくある質問 -->
      <div class="mb-3 col-sm-3">
        <router-link to="/support/faq">
          <div class="p-4 card">
            <div class="card-img-top text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </div>
            <div class="p-0 card-body">
              <p class="card-title text-center">サービスに関するよくある質問</p>
            </div>
          </div>
        </router-link>
      </div>

      <!-- ご利用者向けマニュアル -->
      <div class="mb-3 col-sm-3">
        <router-link to="/support/manual">
          <div class="p-4 card">
            <div class="card-img-top text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-laptop" viewBox="0 0 16 16">
                <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"/>
              </svg>
            </div>
            <div class="p-0 card-body">
              <p class="card-title text-center">ご利用者向けマニュアル</p>
            </div>
          </div>
        </router-link>
      </div>

      <!-- 動画でみるhayabusaBanD紹介とマニュアル -->
      <div class="mb-3 col-sm-3">
        <router-link to="/support/movie">
          <div class="p-3 card">
            <div class="card-img-top text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" fill="currentColor" class="bi bi-film" viewBox="0 0 16 16">
                <path d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z"/>
              </svg>
            </div>
            <div class="p-0 card-body">
              <p class="card-title text-center">動画でみるhayabusaBanD<br>紹介とマニュアル</p>
            </div>
          </div>
        </router-link>
      </div>

      <!-- リリースノート -->
      <div class="mb-3 col-sm-3">
        <router-link to="/support/releasenotes">
          <div class="p-4 card">
            <div class="card-img-top text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
              </svg>
            </div>
            <div class="p-0 card-body">
              <p class="card-title text-center">リリースノート</p>
            </div>
          </div>
        </router-link>
      </div>

      <div clss="mb-3 col-sm-3">
        <!-- 空白スペース -->
      </div>

      <!-- 2行目 -->

      <!-- 困ったときは -->
      <div class="mb-3 col-sm-3">
        <router-link to="/support/help">
          <div class="p-4 card">
            <div class="card-img-top text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
              </svg>
            </div>
            <div class="p-0 card-body">
              <p class="card-title text-center">困ったときは</p>
            </div>
          </div>
        </router-link>
      </div>

      <!-- お問い合わせ -->
      <div class="mb-3 col-sm-3">
        <router-link to="/support/contact">
          <div class="p-4 card">
            <div class="card-img-top text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
              </svg>
            </div>
            <div class="p-0 card-body">
              <p class="card-title text-center">お問い合わせ</p>
            </div>
          </div>
        </router-link>
      </div>
    </div><!-- ope-menu -->

    <GlobalContact />

  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
}

article {
  margin: 10px 0;
  padding: 10px 0;
}

article h3 {
  position: relative;
  padding: 0.8rem 0;
  margin: 0px auto 20px;
  overflow: hidden;
  font-weight: bold;
  font-size: 16pt;
}

article h3:before,article h3:after {
  position: absolute;
  width: 100%;
  bottom: 0;
  content: '';
}

article h3:before {
  border-bottom: 5px solid #1e72b1;
}

article h3:after {
  border-bottom: 5px solid skyblue;
}

article h4 {
  padding: 0.25rem;
  color: var(--color-navy);
  font-size: 14pt;
  border-bottom: solid 1px #1e72b1;
}

article p {
  padding: 0 10px;
  font-size: 1.05rem;
}

article svg {
  margin: 20px;
}

/* ボタン */
.button-navy {
  padding: 10px 20px;
  width: 100%;
  color: var(--color-white);
  font-size: 16pt;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: var(--color-navy);
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  display: block;
  cursor: pointer;
}
.button-navy:hover {
  background-color: var(--color-navy-lighten);
  transition: var(--anim-speed);
}
.button-navy:active {
  background-color: var(--color-navy-darken);
}

.contact-frame{
  display: flex;
  width: 97%;
  margin: 20px 1em;
  padding: 15px 10px 0px;
  border: 1px solid #1e72b1;
  border-radius: 4px;
}
.contact-frame h4{
  border-bottom: 2px solid #1e72b1;
}
.col-md-6{
  padding-right:20px;
  padding-left:20px;
}

.font-small {
  font-size: 80%;
}
</style>

<script>
import { defineComponent } from "vue";

import GlobalContact from "@/components/GlobalContact.vue";
export default defineComponent({
  name: 'SpMenu',
  components: {
    GlobalContact,
  },
  setup() {
    return {
    }
  },
});
</script>