<template>
  <div class="container-xl mx-auto">
    <button type="button" class="ps-0 btn btn-link" @click="$router.back()">&lt;前のページに戻る</button>
    <h2>商標について</h2>
    <p class="my-3">
      「BanD」および「hayabusa BanD」の名称、ロゴは、日本国における株式会社本島ビジネスセンターの登録商標です。<br>
      BanDブランドの詳細は、株式会社本島ビジネスセンターの「<a href="https://www.mbcnet.co.jp/band/index.html" target="_blank" rel="noopener noreferrer">BanDブランドコンセプトについて</a>」をご覧ください。
    </p>

    <table border="1" class="table-bordered">
      <tr>
        <th>登録商標</th>
        <td>
          <img src="/img/band_logo.svg" alt="BanD logo">
        </td>
        <td><span class="fs-3 fw-bold">BanD</span></td>
        <td>
          <img src="/img/hayabusa.svg" alt="hayabusa logo">
        </td>
        <td><span class="fs-3 fw-bold">hayabusa BanD</span></td>
      </tr>
      <tr>
        <th>商標登録番号</th>
        <td>第6743209号</td>
        <td>第6743210号</td>
        <td>第6743211号</td>
        <td>第6743212号</td>
      </tr>
    </table>

  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
}

table {
  margin: 0 20px 20px;
  border-collapse: collapse;
  border: 2px solid #1e72b1;
}

table th{
  padding: 4px;
  text-align: center;
  border: 1px solid #1e72b1;
  background: #e9f6ff;
}

table td {
  padding: 10px;
  min-width: 150px;
  text-align: center;
  border: 1px solid #1e72b1;
}
</style>

<script>
export default {
  name: 'Trademark',
  components: {
  },
  setup() {
    /*eslint no-irregular-whitespace:0 */ // ESLintで全角スペースを警告しない
    return {
    }
  },
}
</script>