<template>
  <div class="container-xl mx-auto">
    <NavMenu />
    <div class="d-flex">
      <nav class="d-none d-sm-block">
        <ul>
          <router-link to="?">
            <li v-bind:class="{'active' : !$route.query.pane}">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z"/>
                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z"/>
              </svg>
              ユーザー情報
            </li>
          </router-link>
          <router-link to="?pane=password">
            <li v-bind:class="{'active' : $route.query.pane === 'password'}">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-key" viewBox="0 0 16 16">
                <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
                <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
              パスワード変更
            </li>
          </router-link>
          <router-link to="?pane=assign">
            <li v-bind:class="{'active' : $route.query.pane === 'assign'}">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-buildings" viewBox="0 0 16 16">
                <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z"/>
                <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z"/>
              </svg>
              閲覧先管理
            </li>
          </router-link>
        </ul>
      </nav>
      <main>
        <UserInfo v-if="!$route.query.pane" />
        <UpdatePassword v-if="$route.query.pane === 'password'" />
        <AssignCustomer v-if="$route.query.pane === 'assign'" />
      </main>
    </div>
  </div>
</template>

<style scoped>
nav {
  min-width: 15%;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav ul a,
nav ul a:active,
nav ul a:visited {
  color: var(--text-primary);
  text-decoration: none;
}

nav ul a li {
  padding: 20px 15px 20px 10px;
  background-color: var(--background-primary);
  border-top: 1px solid var(--border-gray);
  border-left: 2px solid var(--background-primary);
  cursor: pointer;
  transition: var(--anim-speed);
}
nav ul a:first-child li {
  border-top: none;
}
nav ul a li:hover {
  background-color: var(--background-secondary);
  transition: var(--anim-speed);
}
nav ul a li.active {
  border-left: 2px solid var(--theme-primary);
  cursor: default;
}

main {
  flex-grow: 1;
}
</style>

<script>
import { useRoute, useRouter } from "vue-router";
import NavMenu from "@/components/NavMenu.vue"
import UserInfo from "@/components/UserInfo.vue"
import UpdatePassword from "@/components/UpdatePassword.vue"
import AssignCustomer from "@/components/AssignCustomer.vue"

export default {
  name: "UserSettings",
  components: {
    NavMenu,
    UserInfo,
    UpdatePassword,
    AssignCustomer,
  },
  setup() {
    // vue-route::route
    const route = useRoute();
    // vue-router::router
    const router = useRouter();

    // PaneIDのチェック
    switch (route.query?.pane) {
      case undefined:
      case "password":
      case "assign":
        // 正しいID（何もしない）
        break;
      default:
        // Indexにフォールバックする
        router.replace({ name: "UserSettings" });
        break;
    }
  }
}
</script>
