<template>
  <!-- ローディングスピナー -->
  <Loading v-model:active="status.inprogress" :can-cancel="false" color="var(--color-blue)" />
  <!-- ローディングスピナー ここまで -->
  <div class="m-3">
    <a href="#" class="arrow mobile-back d-sm-none mb-3" @click="$router.back()">戻る</a>
    <h2>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-buildings" viewBox="0 0 16 16">
        <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z"/>
        <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z"/>
      </svg>
      閲覧先追加・削除
    </h2>
    <!-- エラーメッセージ -->
    <div v-if="status.message" v-bind:class="[status.isError ? 'alert-danger' : 'alert-success']" class="alert text-center" role="alert">
      {{ status.message }}
    </div>
    <!-- エラーメッセージ ここまで -->
    <div class="container">
      <h3>追加済み閲覧先({{ assignedCustomers.length ?? 0 }}件)</h3>
      <ul>
        <li v-for="(item, index) in assignedCustomers" :key="index">
          <p class="m-0">
            <span class="fw-bold">{{ item.customerName }}</span><br>
            &nbsp;{{ item.customerCode }}
          </p>
          <span v-if="item.customerCode === $store.getters.getOwnInfo.tenantCode" class="ms-2 badge bg-white text-dark border">
            メイン
          </span>
          <button
            class="ms-auto me-2 btn"
            v-bind:class="[item.customerCode === $store.getters.getOwnInfo.tenantCode ? 'btn-secondary' : 'btn-danger']"
            type="button"
            :disabled="item.customerCode === $store.getters.getOwnInfo.tenantCode"
            :title="[item.customerCode === $store.getters.getOwnInfo.tenantCode ? 'メインの閲覧先は削除できません' : '']"
            @click="confirmUnAssign(item.customerCode, item.customerName)"
          >
            削除
          </button>
        </li>
      </ul>
      <div class="accordion accordion-flush border" id="AssignedCustomer">
        <div class="accordion-item">
          <h4 class="accordion-header" id="AddNew">
            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#AddNewForm" aria-expanded="false" aria-controls="AddNewForm">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
              <span class="ps-1 fw-bold">閲覧先を追加する</span>
            </button>
          </h4>
          <div id="AddNewForm" class="accordion-collapse collapse" aria-labelledby="AddNew" data-bs-parent="#AssignedCustomer">
            <div class="accordion-body">
              <form @submit.prevent="assignNewCustomer" class="align-items-center">
                <div class="row mb-2">
                  <div class="col-sm-3">
                    <label class="col-form-label">追加するお客様番号</label>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="customerCode" required>
                  </div>
                  <div class="col-sm-4">
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-3">
                    <label class="col-form-label">ユーザー登録時認証コード</label>
                  </div>
                  <div class="col-sm-5">
                    <input type="text" class="form-control" v-model="authCode" required>
                  </div>
                  <div class="col-sm-4">
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 text-end">
                    <button type="submit" class="btn btn-primary">
                      追 加
                    </button>
                  </div>
                </div>
              </form>
            </div><!-- accordion-body -->
          </div>
        </div><!-- accordion-item -->
      </div>
    </div><!--container -->
  </div>

  <!-- 閲覧先を削除する確認ダイアログ -->
  <ConfirmDialog id="UnAssignConfirmDialog"
    title="閲覧先削除"
    :content="unAssignConfirmMessage"
    buttons="YesNo"
    :positiveAction="doUnAssignCustomer"
    :canCancelable="true"
    :isDangerousOperation="true"
  />
</template>

<style scoped>
/* 画面サイズ576px以下の時 */
@media (max-width: 576px) {
  /* 戻るボタン */
  .arrow{
	position: relative;
	display: inline-block;
	padding: 0 0 0 16px;
	color: #000;
	vertical-align: middle;
	text-decoration: none;
	font-size: 17px;
  border-bottom: 1px solid #696969;
}
.arrow::before,
.arrow::after{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	content: "";
	vertical-align: middle;
}
.mobile-back::before{
	box-sizing: border-box;
	width: 14px;
	height: 14px;
	border: 1px solid #696969;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.mobile-back::after{
	left: 5px;
	width: 6px;
	height: 6px;
	border-top: 1px solid #696969;
	border-right: 1px solid #696969;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
}
h2 {
  padding-bottom: 8px;
  color: var(--text-primary);
  font-size: 1.25rem;
  text-indent: 2px;
  border-bottom: 2px solid var(--theme-primary);
}

h2 svg {
  vertical-align: sub;
}

h3 {
  font-size: 1rem;
}

button.btn {
  padding: 2px 8px;
}

/** 閲覧先一覧 */
ul {
  margin: 0;
  padding: 0;
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: var(--border-gray);
}
li {
  padding: 10px;
  align-items: center;
  border-top: 1px solid var(--border-gray);
  display: flex;
}

/* アコーディオンボディー */
div.accordion-body {
  padding: 10px 20px;
}

/* 入力フォーム */
form input,
form select,
form textarea {
  color: var(--text-primary);
  font-family: var(--font-family) !important;
  font-size: 100%;
  border: 1px solid var(--border-gray);
  background-color: var(--background-primary);
}

form select option {
  color: var(--text-primary);
  background-color: var(--background-primary);
}

form input:focus,
form select:focus,
form textarea:focus,
input.indicate-focus:focus {
  border: 1px solid var(--color-blue);
  background-color: var(--hover) !important;
  box-shadow: 0 0 8px -4px var(--color-blue);
  outline: none;
}

/* 不活性ボタンのスタイル */
button.btn[disabled] {
    pointer-events: unset;
    cursor: not-allowed;
}

</style>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Loading from "vue-loading-overlay";
import utilities from "@/services/utilities";
import backend from "@/services/backendApi";

export default defineComponent({
  name: "AssignCustomer",
  components: {
    Loading,
    ConfirmDialog,
  },
  setup() {
    // vuex::store
    const store = useStore();
    
    // 共通ユーティリティ読み込み
    const util = utilities;

    // 処理ステータス
    const status = reactive({
      inprogress: false,  // 処理状況
      message: "",        // 結果メッセージ
      isError: false,     // エラーか否か
      executed: false,    // 実行済みか否か
    });

    // 得意先追加対象アカウントの取得
    const customerCode = ref("");   // 得意先コード
    const authCode = ref("");       // ユーザー登録時認証コード

    /** 追加済み閲覧先一覧 */
    const assignedCustomers = computed(() => {
      const own = store.getters.getAssignedCustomers?.find(
        i => i.customerCode === store.getters.getOwnInfo.tenantCode
      ) ?? [];

      const other = store.getters.getAssignedCustomers?.filter(
        i => i.customerCode !== store.getters.getOwnInfo.tenantCode
      ) ?? [];

      return [].concat(own, other);
    });

    // ロールアクセスコントロール追加処理
    const assignNewCustomer = () => {
      // ステータスを処理中に変更
      status.inprogress = true;
      
      // 通知クリア
      store.dispatch("removeNotification", "assignNewCustomer");

      // API呼び出し
      backend.assignNewCustomer(store.getters.getOwnInfo.accountId, customerCode.value, authCode.value)
        .then(async response => {
          // 成功
          // 実行済に変更
          status.executed = true;

          // store更新
          store.dispatch('saveAssignedCustomers', response.data.contents);

          // 更新成功 メッセージ表示
          store.dispatch("addNotification", {
            id: "assignNewCustomer",
            title: "閲覧先追加成功",
            message: response.data.message,
            type: "success",
            autoClose: true
          });

          // 入力値クリア
          customerCode.value = "";
          authCode.value = "";
        })
        .catch(error => {
          // 失敗
          store.dispatch("addNotification", {
            id: "assignNewCustomer",
            title: "閲覧先追加失敗",
            message: error.message,
            type: "danger"
          });

        }).finally(() => {
          // 処理中状態OFF
          status.inprogress = false;
        });
    }

    const unCustomerCode = ref("");   // 削除用得意先コード

    /** 閲覧先を削除する 確認ダイアログ表示処理 */
    const confirmUnAssign = (unCustCd, unCustName) => {
      unCustomerCode.value = unCustCd;

      // 確認メッセージ整形
      const msg = `閲覧先を削除すると、請求書の閲覧ができなくなります。\n以下の閲覧先を削除してもよろしいですか？\n\n`
                + `お客様番号：${unCustCd}\nお客様名称：${unCustName}`;
      unAssignConfirmMessage.value = msg;
      // 確認ダイアログを表示
      new Modal(document.getElementById("UnAssignConfirmDialog")).show();
    }

    /** 閲覧先を削除する 確認ダイアログメッセージ */
    const unAssignConfirmMessage = ref("閲覧先削除");

    // ロールアクセスコントロール削除処理
    const doUnAssignCustomer = () => {
      // ステータスを処理中に変更
      status.inprogress = true;

      // API呼び出し
      backend.unassigncustomer(store.getters.getOwnInfo.accountId, unCustomerCode.value)
        .then(async response => {
          // 成功
          // 実行済に変更
          status.executed = true;

          // store更新(洗い替え)
          store.dispatch('saveAssignedCustomers', response.data.contents);

          // 更新成功 メッセージ表示
          store.dispatch("addNotification", {
            id: "unassigncustomer",
            title: "閲覧先削除成功",
            message: response.data.message,
            type: "success",
            autoClose: true
          });
        }).catch(error => {
          // 失敗
          store.dispatch("addNotification", {
            id: "unassigncustomer",
            title: "閲覧先削除失敗",
            message: error.message,
            type: "danger"
          });
        }).finally(() => {
          // 処理中状態OFF
          status.inprogress = false;
          unCustomerCode.value = "";
        });
    }

    return {
      util,                     // ユーティリティ
      status,                   // ステータス
      customerCode,             // ロールアクセスコントロール追加対象得意先コード
      authCode,                 // 登録時認証コード
      assignedCustomers,        // 追加済み閲覧先一覧
      unCustomerCode,           // ロールアクセスコントロール削除対象得意先コード
      unAssignConfirmMessage,   // 閲覧先を削除する表示メッセージ
      assignNewCustomer,        // ロールアクセスコントロール得意先追加処理関数
      doUnAssignCustomer,       // 閲覧先を削除する処理
      confirmUnAssign,          // 閲覧先を削除する確認処理
    };
  }
});
</script>
