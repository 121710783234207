<template>
  <div class="toast-container position-fixed p-3" style="z-index: 99999">
    <div v-for="(item, index) in notifications" :key="index"
        :class="'border-' + item.type + ' primary text-' + item.type + ' primary toast fade ' + (item.show ? 'show' : '')"
        role="alert" aria-live="assertive" aria-atomic="true">
      <div v-if="item.title !== ''" :class="'toast-header border-' + item.type">
        <strong class="me-auto">{{ item.title }}</strong>
        <small class="text-muted text-body-secondary">{{ item.createdAt }}</small>
        <button type="button" class="btn-close" aria-label="閉じる" @click="close(index)"></button>
      </div>
      <div class="d-flex">
        <div class="toast-body">
          {{ item.message }}
        </div>
        <button v-if="item.title === ''" type="button" class="btn-close me-2 m-auto"
            @click="close(index)" aria-label="閉じる"></button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.toast {
  background-color: #fafafaf2;
}

.toast-container {
  top: 20px;
  right: 0;
  z-index: var(--zindex-high);
}

.toast-header {
  color: inherit;
}

.toast-body {
  width: 100%;
}
.toast-header + div > .toast-body {
  color: inherit;
  border-radius: 0 0 2px 2px;
}
</style>

<script>
import { defineComponent, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import utilities from "@/services/utilities";

export default defineComponent({
  name: "Notification",
  setup() {
    // vuex::store
    const store = useStore();

    // 共通ユーティリティ読み込み
    const util = utilities;

    // ストアの通知データを取得
    const notifications = ref(store.getters.getNotifications);

    // 通知データの変更を監視
    watchEffect(() => {
        notifications.value
                     .filter((item) => !item.accepted) // 未処理の通知データを抽出
                     .forEach((item) => {

                        // 同一IDの通知データは入れ替える
                        const existIndex = notifications.value.findIndex(itm => itm.accepted &&  itm.id === item.id);
                        if (existIndex > -1) {
                          // 古い通知データを削除
                          notifications.value.splice(existIndex, 1);
                        }

                        // 新たな通知を表示
                        item.show = true;

                        // 自動で非表示する通知の場合
                        if (item.autoClose) {
                          // closeTime経過後に非表示
                          setTimeout(() => {
                              item.show = false;
                          }, item.closeTime);
                        }

                        // 処理済みに変更
                        item.accepted = true;
                    });
    });

    // 通知 消去処理
    const close = index => {
      notifications.value.splice(index, 1);
    }

    return {
      util,
      notifications,
      close
    };
  },
});
</script>