<template>
  <div class="container-xl mx-auto">
    <p class="my-3">
      <router-link to="/support/index">＜サポートメニューへ戻る</router-link>
    </p>

    <div class="row">
      <div class="col-3">
        <nav id="navbar" class="overflow-auto flex-column align-items-stretch ps-2 pe-4 border-end" style="height:70vh">
          <nav class="nav nav-pills flex-column">
            <a class="nav-link active" href="#help">困ったときは</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ms-3 my-1" href="#help-01">パスワードを忘れてしまった場合</a>
              <a class="nav-link ms-3 my-1" href="#help-02">アカウントがロックされた場合</a>
              <a class="nav-link ms-3 my-1" href="#help-03">サービス利用ユーザーを追加したい場合</a>
              <a class="nav-link ms-3 my-1" href="#help-04">パスワード付きPDFをアップロードしたい場合</a>
            </nav>
          </nav>
        </nav>
      </div>
      <div class="col-9">
        <div class="p-5 overflow-y-auto" data-bs-spy="scroll" data-bs-target="#navbar" data-bs-smooth-scroll="true" tabindex="0" style="height:70vh">
          <div id="help">
            <h2>困ったときは（トラブルシューティング）</h2>
          </div>
          <div id="help-01">
            <article>
              <h3>パスワードを忘れてしまった場合</h3>
              <div class="row mb-3">
                <p class="mb-5">パスワードを忘れてしまった場合、パスワードをリセットし、新たなパスワードを設定することができます。</p>
                <div class="ti">
                  <p>①&nbsp;サインイン画面の「パスワードをお忘れの場合はこちらへ」をクリックします。</p>
                  <img src="/img/support/img_help_1.png" alt="トラブルシューティング パスワードを忘れてしまった場合" class="mb-5 frame_img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>②&nbsp;アカウントID(メールアドレス)を入力し、「パスワードをリセットする」をクリックします。<br>
                    お客様の連絡先メールアドレス宛にメールが届くので、お間違いのないようご入力ください。</p>
                  <div class="notice d-flex align-items-center border border-warning border-2 mb-5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <p class="my-3">&nbsp;&nbsp;&nbsp;サインアップ後に、連絡先メールアドレスを変更されている場合、入力したアカウントID(メールアドレス)宛にメールは届きませんのでご注意ください。</p>
                  </div>
                  <img src="/img/support/img_help_2.png" alt="トラブルシューティング パスワードを忘れてしまった場合" class="mb-5 frame_img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>③&nbsp;この画面に切り替わったら、お客様の連絡先メールアドレスの受信フォルダーを確認してください。</p>
                  <img src="/img/support/img_help_3.png" alt="トラブルシューティング パスワードを忘れてしまった場合" class="mb-3" @click="showLigthBox">
                </div>
                <div class="att ms-5 ml-1 mb-5 ps-4">
                  <p>※メールが届かない場合は、以下をご確認ください。</p>
                  <ul class="mb-1">
                    <li>入力したアカウントID(メールアドレス)で当サービスの利用申請が行われていますか？</li>
                    <li>入力したアカウントID(メールアドレス)は正しいですか？</li>
                    <li>メールが迷惑フォルダーに振り分けられていませんか？</li>
                    <li>ドメイン指定受信などの受信設定で当サービスからのメールがフィルターされていませんか？</li>
                  </ul>
                </div>
                <div class="ti">
                  <p>④&nbsp;「hayabusaband@mbcnet.co.jp」より、パスワード再設定のご連絡メールが届きます。メールを開きURLをクリックします。</p>
                  <img src="/img/support/img_help_4.png" alt="トラブルシューティング パスワードを忘れてしまった場合" class="mb-5 frame_img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>⑤&nbsp;メールに記載されている8桁のセキュリティコードを入力し、新しいパスワードを入力したら、「パスワードを再設定する」をクリックします。<br>
                    セキュリティコードの有効期限は30分です。30分以上経過した場合は、再度パスワードリセットお願いします。</p>
                  <img src="/img/support/img_help_5.png" alt="トラブルシューティング パスワードを忘れてしまった場合" class="mb-3 frame_img" @click="showLigthBox">
                </div>
                <div class="att ms-5 ml-1 mb-5 ps-4">
                  <p>※パスワードは、以下を確認し入力してください。</p>
                  <ul class="mb-1">
                    <li>10～100文字以内。</li>
                    <li>「英小文字」「英大文字」「数字」を含んでいること。</li>
                    <li>過去5世代前まで利用していたパスワードと一致しないこと。</li>
                  </ul>
                </div>
                <div class="ti">
                  <p>⑥&nbsp;「ダッシュボード画面へ」をクリックすると、ハヤブサバンドに自動でサインインされます。<br>
                    念のため、サインアウトしていただいた後に、サインインできるかご確認ください。</p>
                  <img src="/img/support/img_help_1.png" alt="トラブルシューティング パスワードを忘れてしまった場合" class="mb-5 frame_img" @click="showLigthBox">
                </div>
              </div>
            </article>
          </div>

          <div id="help-02">
            <article>
              <h3>アカウントがロックされた場合</h3>
              <div class="row mb-3">
                <p class="my-3">
                  サインインの際に一定回数以上パスワードを間違えると、10分間アカウントがロックされます。10分後に自動で解除されますので、時間をおいて再度お試しください。<br><br>
                  パスワードを忘れてしまった場合は、「パスワードをお忘れの場合はこちらへ」からパスワード変更を行うか、テナント管理者により、パスワードをリセットしてもらう必要があります。
                </p>
                <p class="ti2">※テナント管理者が自テナントのユーザーのパスワードをリセットする場合は、仮パスワードを対象ユーザーに通知する必要があります。</p>
                <div class="ms-5 ti">
                  <img src="/img/support/img_help_1.png" alt="トラブルシューティング パスワードを忘れてしまった場合" class="mb-5 frame_img" @click="showLigthBox">
                </div>
                <p>テナント管理者が自テナントのユーザーのパスワードをリセットする場合は、「管理者メニュー」を開き、「ユーザーマスター保守」をクリックします。該当ユーザーを選択するとユーザーマスターメンテ画面が開くので、「パスワードリセット」をクリックします。</p>
                <div class="ms-5 ti">
                    <img src="/img/support/img_help_7.png" alt="トラブルシューティング パスワードを忘れてしまった場合" class="mb-5 frame_img" @click="showLigthBox">
                </div>
                <p>「はい」を押下することでパスワードのリセットが完了します。</p>
                <div class="ms-5 ti">
                  <img src="/img/support/img_help_8.png" alt="トラブルシューティング パスワードを忘れてしまった場合" class="mb-5" @click="showLigthBox">
                </div>
              </div>
            </article>
          </div>

          <div id="help-03">
            <article>
              <h3>サービス利用ユーザーを追加したい場合</h3>
              <div class="row">
                <p class="my-3">
                  ご契約時にご記入いただきましたサービス利用ユーザー数より、ユーザーの追加が発生した場合は、お手数ではございますが、<br>
                  <a href="/support/contact">ハヤブサバンドサポート担当</a>からユーザー数変更のご依頼をお願いします。
                </p>
                <div class="ms-5 ti">
                  <img src="/img/support/img_help_9.png" alt="トラブルシューティング サービス利用ユーザーを追加したい場合" class="mb-5" @click="showLigthBox">
                </div>
              </div>
            </article>
          </div>

          <div id="help-04">
            <article>
              <h3>パスワード付きPDFをアップロードしたい場合</h3>
              <div class="row">
                <p class="my-3">
                  パスワードを解除せずにPDFのアップロードを行った場合は、帳票詳細画面でPDFを表示する際に、下図のようにその都度パスワードを入力する必要があります。
                </p>
                <div class="ms-5 ti">
                  <img src="/img/support/img_help_10.png" alt="トラブルシューティング パスワード付きPDFをアップロードしたい場合" class="mb-5" @click="showLigthBox">
                </div>
                <p class="mb-3">
                  PDFのパスワードを解除してアップロードを行う場合は、以下の「PDFのパスワード解除方法（Windowsの場合）」の手順を参考に、ハヤブサバンドへアップロードしていただくことも可能です。
                  <br>
                  ※&nbsp;パスワード付きPDFのパスワード解除に関する操作は、お客様の判断・責任の上で実施してください。
                </p>
                
                <div class="notice d-flex align-items-center border border-warning border-2 mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <p class="my-3">&nbsp;【OCRオプションをご契約されているお客様の場合】<br>
                      パスワード付きPDFはOCR処理ができません。そのため、アップロード自体は完了しますが、「OCRエラー」が表示されます。この場合、帳票詳細画面で帳票の内容を手動で入力する必要があります。
                      パスワード付きPDFのOCR処理を行いたい場合は、パスワードを解除後、PDFを生成し、差替え・再アップロードしてください。
                    </p>
                </div>
                <div class="notice d-flex align-items-center border border-warning border-2 mb-5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                  <p class="my-3">&nbsp;【代行入力オプションをご契約されているお客様の場合】<br>
                    代行入力では、パスワード付きPDFの入力を行うことができません。そのため、<u>必ずパスワード解除を行ったPDFファイルのアップロードをお願いします</u>。
                  </p>
                </div>
                <h4>PDFのパスワード解除方法（Windowsの場合）</h4>
                <div class="ti">
                  <p>①&nbsp;対象のPDFファイルを開き、設定されているパスワードを入力して、内容が閲覧できる状態にします。</p>
                  <div class="ms-5 ti">
                  <img src="/img/support/img_help_11.png" alt="トラブルシューティング パスワード付きPDFをアップロードしたい場合" class="mb-5" @click="showLigthBox">
                </div>
                </div>
                <div class="ti">
                  <p>②&nbsp;PDFの印刷を選択し、対象プリンターを「Microsoft Print to PDF」やその他PDF出力が可能なプリンターを指定し、新たなPDFファイルとして保存を行います。</p>
                  <div class="ms-5 ti">
                  <img src="/img/support/img_help_12.png" alt="トラブルシューティング パスワード付きPDFをアップロードしたい場合" class="mb-5" @click="showLigthBox">
                </div>
                <div class="ms-5 ti">
                  <img src="/img/support/img_help_13.png" alt="トラブルシューティング パスワード付きPDFをアップロードしたい場合" class="mb-5" @click="showLigthBox">
                </div>
                </div>
                <div class="ti">
                  <p>③&nbsp;上記の方法により、パスワードが解除されたPDFの生成が可能です。</p>
                </div>
              </div>
            </article>
          </div>

        </div>
      </div>
    </div>

    <!-- LightBox -->
    <vue-easy-lightbox
      :visible="isShowingLightBox"
      :imgs="lightBoxImages"
      @hide="isShowingLightBox = false"
    />
  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
  position: relative;
  margin-bottom: 20px;
  padding: 0.5rem 0;
  border-bottom: 5px solid;
}

h2:before {
  position: absolute;
  bottom: -0.6rem;
  left: 0rem;
  right: 0rem;
  border-bottom: 1px solid;
  content: '';
}

article {
  margin: 10px 0;
  padding: 10px 0;
}

article h3 {
  position: relative;
  padding-top: 0.6rem;
  padding-bottom: 0.28rem;
  margin: 10px auto 20px;
  font-weight: bold;
  font-size: 16pt;
  border-bottom: 5px solid skyblue;
}

article h3:after {
  position: absolute;
  border-bottom: 5px solid #1e72b1;
  content: " ";
  display: block;
  bottom: -4px;
  width: 15%;
}

h4 {
  margin-bottom: 4px;
  color: var(--color-navy);
  font-size: 14pt;
  position: relative;
  padding: 0.6rem 1rem;
  margin-bottom: 0.2rem;
  margin: 0px 0px 20px;
  overflow: hidden;
  font-weight: bold;
  font-size: 16pt;
  display: inline-block;
  border-top: solid 2px #5989cf;
  border-bottom: solid 2px #5989cf;
  width: auto;
}

h5 {
  margin-bottom: 4px;
  color: var(--color-navy);
  font-size: 12pt;
}

article svg {
  margin: 20px;
}

.notice {
  padding:0 20px;
  margin:0 5em 0 0;
  width:85%;
  border-radius: 20px;
  background-color: rgb(255,227,157);
}
.notice svg {
  margin:0 20px 0 0;
}

.advice {
  padding:0 20px;
  margin:0 5em 0 0;
  width:85%;
  border-radius: 20px;
}

.ti {
  text-indent: -1.3em;
  margin-left: 1.5em;
}
.ti2 {
  text-indent: -1.0em;
  margin-left: 1.0em;
}
li.list-group-item {
  text-indent: -1.3em;
  margin-left: 1.5em;
  border:none;
}
li.list-group-item ul {
  padding-left: 0em
}
li.list-group-item ul li {
  list-style: disc;
  padding-left: 0em;

  text-indent: -0.3em;
  margin-left: 1.5em;
}
table {
  border: 1px solid rgb(68,114,196);
}
table th {
  background-color: rgb(68,114,196);
}
table tr:nth-child(2n+1) {
  background-color: rgb(217,226,243);
}

table.num {
	counter-reset: rowCount;
}
table.num > tbody > tr {
	counter-increment: rowCount;
}
table.num > tbody > tr > td:first-child::before {
    content: counter(rowCount);
}
table.num td {
  border-right: 1px solid rgb(68,114,196);
}

.border-double {
  border-bottom: 3px double #666;
}

img {
  width: 60%;
  margin-left: 5%;
}

.frame_img {
  width: 60%;
  margin-left: 5%;
  border: 1px solid #666;
}

.att {
  width: 80%;
}

.overflow-y-auto {
  /** bootstrap5.3へ更新前の暫定対応 */
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<script>
import { ref } from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'SpHelp',
  components: {
    VueEasyLightbox
  },
  setup() {
    /** LightBox表示フラグ */
    const isShowingLightBox = ref(false);

    /** LightBoxで表示する画像のURL文字列、またはURLの配列 */
    const lightBoxImages = ref([]);

    /** LightBoxを表示する処理 */
    const showLigthBox = (event) => {
      // クリックされた画像のソース属性を、対象画像としてセットする
      lightBoxImages.value = event.currentTarget.src;
      
      // LightBoxを表示する
      isShowingLightBox.value = true;
    }

    return {
      isShowingLightBox,
      lightBoxImages,
      showLigthBox
    }
  },
}
</script>