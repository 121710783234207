import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import App from './App.vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import loading from 'vue-loading-overlay';
import router from './router';
import store from './store';
import "bootstrap/dist/css/bootstrap.min.css";
import 'vue-loading-overlay/dist/vue-loading.css';

createApp(App).use(store)                   // vuex
              .use(router)                  // vue-router
              .use(loading, {               // vue-loading-overlay
                color: 'var(--color-blue)',
                canCancel: false,
              })
              .use(VueEasyLightbox)         // vue-easy-light-box
              .use(createGtm({              // vue-gtm
                id: 'GTM-PWT3PZT',
                enabled: false,             // 同意取得後に有効化
              }))
              .use(VueReCaptcha, {          // vue-recaptchav3
                siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
                loaderOptions: {
                  useRecaptchaNet: true,
                  autoHideBadge: true,
                }
              })
              .mount('#app');
