<template>
  <div class="container-xl mx-auto">
    <!-- ローディングスピナー -->
    <Loading v-model:active="status.inprogress" :can-cancel="false" color="var(--color-blue)" />

    <!-- フォーム -->
    <form @submit.prevent="requestChangePassword" v-if="!status.executed" class="mb-3"><!-- 入力画面 -->
      <h2>パスワードをお忘れの場合</h2>
      <p class="my-3">下記の入力欄にアカウントID（メールアドレス）を入力し、「パスワードをリセットする」をクリックしてください。<br>
                      ご登録の連絡先メールアドレスにパスワード再設定メールをお送りします。</p>
      <table class="mb-3">
        <tr>
          <th>アカウントID（メールアドレス）</th>
          <td>
            <input type="email" class="wide" v-model="accountId" maxlength="100" autocomplete="username" required>
            <div class="form-text">例：sample@xxxxx.jp（半角英数字でご入力ください）</div>
          </td>
        </tr>
      </table>

      <!-- エラーメッセージ -->
      <div v-if="status.isError" class="alert alert-danger text-center" role="alert">
        {{ status.message }}
      </div>

      <button type="submit" class="button-navy">パスワードをリセットする</button>
      <router-link to="/signin" class="button">サインイン画面に戻る</router-link>
    </form>
    <div v-else><!-- 完了画面 -->
      <h2>メールを送信しました</h2>
      <p>ご入力いただいたアカウントの連絡先メールアドレスに、パスワード再設定メールを送信しました。<br>
         メールをご確認いただき、記載の手順にしたがってパスワードの再設定を行ってください。</p>
      <p>メールが届かない場合は以下をご確認ください。</p>
      <ul>
        <li type="disc">入力したアカウントID（メールアドレス）で当サービスの利用申請が行われていますか？</li>
        <li type="disc">入力したアカウントID（メールアドレス）は正しいですか？</li>
        <li type="disc">メールが迷惑メールフォルダーに振り分けられていませんか？</li>
        <li type="disc">ドメイン指定受信などのメール受信設定で当サービスからのメールがフィルターされていませんか？</li>
      </ul>
      <p style="font-weight:bold;">※本画面は閉じていただき、メールに記載のURLよりパスワード再設定を行ってください。</p>
    </div>
  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
}

/* 入力フォーム */
form input:not([type="radio"]):not([type="checkbox"]):not([type="date"]) {
  padding: 0.25rem;
  background-color: #ffe6e6;
}

form input,
form select,
form textarea {
  color: var(--text-primary);
  font-family: var(--font-family) !important;
  font-size: 100%;
  border: 1px solid var(--border-gray);
  background-color: var(--background-primary);
}

form select option {
  color: var(--text-primary);
  background-color: var(--background-primary);
}

form input:focus,
form select:focus,
form textarea:focus,
input.indicate-focus:focus {
  border: 1px solid var(--color-blue);
  background-color: var(--hover) !important;
  box-shadow: 0 0 8px -4px var(--color-blue);
  outline: none;
}

form .validate input:invalid,
form .validate select:invalid,
form .validate textarea:invalid {
  color: var(--color-red);
  border: 1px solid var(--color-red);
  background-color: var(--background-error);
  box-shadow: 0 0 8px -4px var(--color-red);
  outline: none;
}

input.wide {
  width: 90%;
}

/* テーブル */
table {
  width: 100%;
  border: 1px solid #ccc;
}
table th {
  padding: 0.75rem 1.25rem;
  font-weight: normal;
  border-bottom: 1px solid #ccc;
  background-color: #eee;
}
table td {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #ccc;
}

/* 個人情報の取り扱いについて */
ul {
  padding: 0.75rem 1.75rem;
  border: 1px solid #ccc;
}

/* ボタン */
button,
.button {
  margin: 10px auto;
  padding: 0.4rem 0.6rem;
  width: 50%;
  color: var(--text-primary);
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: var(--color-gray);
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  display: block;
  cursor: pointer;
}
button:hover,
.button:hover {
  background-color: var(--color-gray-lighten);
}
button:active,
.button:active {
  background-color: var(--color-gray-darken);
}
button:disabled,
.button:disabled {
  color: var(--text-secondary) !important;
  background-color: var(--color-gray) !important;
  cursor: not-allowed;
}
button.button-navy {
  color: var(--color-white);
  background-color: var(--color-navy);
}
button.button-navy:hover {
  background-color: var(--color-navy-lighten);
}
button.button-navy:active {
  background-color: var(--color-navy-darken);
}
</style>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useReCaptcha } from 'vue-recaptcha-v3';
import Loading from "vue-loading-overlay";
import utilities from "@/services/utilities"
import backend from '@/services/backendApi';

export default defineComponent({
  name: "ForgotPassword",
  components: {
    Loading
  },
  setup() {
    // 共通ユーティリティ読み込み
    const util = utilities;

    // reCAPTCHA
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    // 処理ステータス
    const status = reactive({
      inprogress: false,  // 処理状況
      message: "",        // 結果メッセージ
      isError: false,     // エラーか否か
      executed: false,    // 実行済みか否か
    });

    // 対象アカウントID
    const accountId = ref("");

    // パスワード変更要求処理
    const requestChangePassword = async () => {
      // ステータスを初期化し、処理中に変更
      status.inprogress = true;
      status.message = "";
      status.isError = false;

      // reCAPTCHAトークン取得
      await recaptchaLoaded();
      const recaptchaToken = await executeRecaptcha('password_reset');

      // API呼び出し
      backend.requestChangePassword(accountId.value, recaptchaToken)
        .then(response => {
          // 成功
          status.message = response.data.message;
          // 実行済に変更
          status.executed = true;
        })
        .catch(error => {
          // 失敗
          status.message = error.message;
          status.isError = true;
        }).finally(() => {
          // 処理中状態OFF
          status.inprogress = false;
        });
    }

    return {
      util,                     // ユーティリティ
      status,                   // ステータス
      accountId,                // リセット対象アカウントID
      requestChangePassword,    // パスワード変更要求処理
    };
  },
  mounted() {
    // reCAPTCHAバッジ表示
    setTimeout(() => {
      this.$recaptchaInstance.value.showBadge();
    }, 1000);
  },
  unmounted() {
    // reCAPTCHAバッジ非表示
    this.$recaptchaInstance.value.hideBadge();
  }
});
</script>
