<template>
  <div
    class="modal fade"
    :id="$props.id"
    :data-bs-backdrop="canCancelable ? 'true' : 'static'"
    :data-bs-keyboard="canCancelable"
    tabindex="-1"
    @click.self="$emit('onCancel')"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header py-2" v-if="title">
          <h1 class="modal-title fs-5">{{ title }}</h1>
        </div><!-- /.modal-header -->
        <div class="modal-body">
          <p class="m-0">{{ content }}</p>
        </div><!-- /.modal-body -->
        <div class="modal-footer py-1">
          <button
            v-if="option.showPositive"
            type="button"
            class="btn btn-sm"
            v-bind:class="[isDangerousOperation ? 'btn-danger' : 'btn-primary']"
            data-bs-dismiss="modal"
            @click="positiveAction"
          >
            {{ option.positiveString }}
          </button>
          <button
            v-if="option.showNegative"
            type="button"
            class="btn btn-sm btn-secondary"
            data-bs-dismiss="modal"
            @click="negativeAction"
          >
            {{ option.negativeString }}
          </button>
          <button
            v-if="option.showCancel"
            type="button"
            class="btn btn-sm btn-simple"
            data-bs-dismiss="modal"
            @click="$emit('onCancel')"
          >
            {{ option.cancelString }}
          </button>
        </div><!-- /.modal-footer -->
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</template>

<style scoped>
.modal-body p {
  white-space: pre-line;
}
</style>

<script>
import { onBeforeUnmount, onMounted } from "vue";

export default {
  props: {
    /* ID */
    id : {
      type: String,
      default: "ConfirmDialog"
    },
    /* タイトル */
    title: {
      type: String,
      default: "",
    },
    /* 本文（必須） */
    content: {
      type: String,
      default: "",
      required: true,
    },
    /* 表示するボタンの種類 */
    buttons: {
      type: String,
      default: "OK",
    },
    /* 実行する操作が危険か否か（削除等） */
    isDangerousOperation: {
      type: Boolean,
      default: false,
    },
    /* 肯定的なボタンを押した際に実行する処理 */
    positiveAction: {
      type: Function,
      default: () => {},
      required: true,
    },
    /* 否定的なボタンを押した際に実行する処理 */
    negativeAction: {
      type: Function,
      default: () => {},
    },
    /* 肯定的なボタンに表示する文章 */
    positiveString: {
      type: String,
      default: "はい",
    },
    /* 否定的なボタンに表示する文章 */
    negativeString: {
      type: String,
      default: "いいえ",
    },
    /* キャンセルボタンに表示する文章 */
    cancelString: {
      type: String,
      default: "キャンセル",
    },
    /* ダイアログ外クリックで閉じられるダイアログか否か */
    canCancelable: {
      type: Boolean,
      default: false,
    },
    /* ダイアログが閉じられた際に実行する処理 */
    hiddenEventAction: {
      type: Function,
      default: () => {},
    },
  },
  emits: [
    "onCancel",
  ],
  setup(props) {
    /** ボタンオプション取得処理 */
    const getButtonOption = (value) => {
      const options = {
        showPositive: false,
        showNegative: false,
        showCancel: false,
        positiveString: "",
        negativeString: "",
        cancelString: "",
      };

      // 表示するボタンを制御
      switch (value) {
        case "YesNo":
          options.showPositive = true;
          options.showNegative = true;
          break;
        case "YesNoCancel":
          options.showPositive = true;
          options.showNegative = true;
          options.showCancel = true;
          break;
        case "OKCancel":
          options.showPositive = true;
          options.showCancel = true;
          options.positiveString = "OK";
          break;
        default:
          // OK only
          options.showPositive = true;
          options.positiveString = "OK";
          break;
      }

      // ボタンタイトルを変更
      options.positiveString = props.positiveString;
      options.negativeString = props.negativeString;
      options.cancelString = props.cancelString;

      return options;
    };

    /** オプション */
    const option = getButtonOption(props.buttons);

    /** ダイアログが閉じられた際に実行する処理 */
    const hiddenEventAction = () => {
      props.hiddenEventAction();
    }

    /** マウント時 */
    onMounted(() => {
      document.getElementById(props.id)
              .addEventListener("hidden.bs.modal", hiddenEventAction);
    });

    /** アンマウント時 */
    onBeforeUnmount(() => {
      document.getElementById(props.id)
              .removeEventListener("hidden.bs.modal", hiddenEventAction);
    });

    return {
      option,
    };
  },
};
</script>