<template>
  <div class="container-xl mx-auto">
    <p class="my-3">
      <router-link to="/support/index">＜サポートメニューへ戻る</router-link>
    </p>

    <h2 id="contact" class="mt-3">動画でみるhayabusaBanDの紹介とマニュアル</h2>

    <article class="mb-5">
      <h3>帳票保管・閲覧クラウド hayabusaBanD&nbsp;&nbsp;ご紹介</h3>
      <div class="m-3 row">
        <div class="mb-3 col-sm-4">
          <h4 class="text-center">～ハヤブサバンドのご紹介～</h4>
          <iframe src="https://www.youtube.com/embed/bV2GJNqEFf8?si=wbMR7vK2bra--j-8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </article>

    <article class="mb-5">
      <h3>hayabusaBanDオンラインマニュアル</h3>
      <div class="m-3 row">
        <div class="mb-3 col-sm-4">
          <h4 class="text-center">～サインアップ編～</h4>
          <iframe src="https://www.youtube.com/embed/tCiB_MEEkGQ?si=yD8ecucmXlmH0aD1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="mb-3 col-sm-4">
          <h4 class="text-center">～パスワードを忘れた場合編～</h4>
          <iframe src="https://www.youtube.com/embed/1s4tV2GRuzQ?si=UOSNWpu77OxFTmS4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="mb-3 col-sm-4">
          <h4 class="text-center">～帳票保管編～</h4>
          <iframe src="https://www.youtube.com/embed/IQBCLQntnPo?si=gJP2f2ndQ8Nmvo3y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>

      <div clss="mb-3 col-sm-3">
        <!-- 空白スペース -->
      </div>

      <!-- 2行目 -->
      <div class="mx-3 my-5 row">
        <div class="mb-3 col-sm-4">
          <h4 class="text-center">～グループ機能の利用方法～</h4>
          <iframe src="https://www.youtube.com/embed/-UEVb7RF80c?si=bI8xH_udH-tUgMxF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="mb-3 col-sm-4">
          <h4 class="text-center">～帳票閲覧機能編～</h4>
          <iframe src="https://www.youtube.com/embed/YNXWtG1h1aQ?si=HSm1ZhF3y2t2b7SO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </article>

  </div>
</template>

<style scoped>
iframe {
  width:100%;
  height:calc(100% + 10px);
}
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
  position: relative;
  margin-bottom: 20px;
  padding: 0.5rem 0;
  border-bottom: 5px solid;
}

h2:before {
  position: absolute;
  bottom: -0.6rem;
  left: 0rem;
  right: 0rem;
  border-bottom: 1px solid;
  content: '';
}

article {
  margin: 10px 0;
  padding: 10px 0;
}

article h3 {
  position: relative;
  padding-top: 0.6rem;
  padding-bottom: 0.28rem;
  margin: 10px auto 20px;
  font-weight: bold;
  font-size: 16pt;
  border-bottom: 5px solid skyblue;
}

article h3:after {
  position: absolute;
  border-bottom: 5px solid #1e72b1;
  content: " ";
  display: block;
  bottom: -4px;
  width: 10%;
}

h4 {
  margin-bottom: 4px;
  color: var(--color-navy);
  font-size: 14pt;
}
h5 {
  margin-bottom: 4px;
  color: var(--color-navy);
  font-size: 12pt;
}

article svg {
  margin: 20px;
}

</style>

<script>
export default {
  name: 'SpMovie',
  components: {
  },
  setup() {
    return {
    }
  },
}
</script>