<template>
  <div class="container-xl mx-auto">
    <p class="my-3">
      <router-link to="/support/index">＜サポートメニューへ戻る</router-link>
    </p>

    <!-- main contents -->
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <nav id="navbar" class="overflow-auto flex-column align-items-stretch ps-2 pe-4 border-end" style="height:70vh">
          <nav class="nav nav-pills flex-column">
            <a class="nav-link active" href="#ElectronicBook">電子帳簿保存法に関して</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ms-5 my-1" href="#ElectronicTrading">電子取引での利用に関して</a>
              <a class="nav-link ms-5 my-1" href="#ScannerSaving">スキャナ保存での利用に関して</a>
            </nav>
            <a class="nav-link" href="#Hayabusaband">hayabusaBanDでできること</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ms-5 my-1" href="#Storage">帳票保管機能</a>
              <a class="nav-link ms-5 my-1" href="#View">帳票閲覧機能</a>
              <a class="nav-link ms-5 my-1" href="#Option">オプションサービスに関して</a>
              <a class="nav-link ms-5 my-1" href="#Recommendation">動作推奨環境</a>
            </nav>
            <a class="nav-link" href="#StartUp">hayabusaBanDスタートアップガイド</a>
            <a class="nav-link" href="#SignUp">サインアップ編</a>
            <a class="nav-link" href="#Maintenance">マスター管理編</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ms-5 my-1" href="#Administrator">テナント管理者によるユーザーの管理</a>
              <a class="nav-link ms-5 my-1" href="#UserMaster">ユーザーマスター保守</a>
              <a class="nav-link ms-5 my-1" href="#Roleaccess">ユーザー権限保守</a>
              <a class="nav-link ms-5 my-1" href="#AuthorizationList">権限別可能操作一覧表</a>
              <a class="nav-link ms-5 my-1" href="#CaseStudies">権限設定のケーススタディ</a>
              <a class="nav-link ms-5 my-1" href="#Group">グループマスター保守</a>
              <a class="nav-link ms-5 my-1" href="#Partner">取引先マスター保守</a>
              <a class="nav-link ms-5 my-1" href="#EvidenceSettings">帳票定義マスター保守</a>
              <a class="nav-link ms-5 my-1" href="#EvidenceTag">帳票タグマスター保守</a>
            </nav>
            <a class="nav-link" href="#Evidence">帳票保管機能編</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ms-5 my-1" href="#EvidenceFlow">帳票保管の流れ</a>
              <a class="nav-link ms-5 my-1" href="#StorageList">帳票保管一覧画面について</a>
              <a class="nav-link ms-5 my-1" href="#EvidenceNocontract">帳票保管の方法</a>
              <a class="nav-link ms-5 my-1" href="#EvidenceContract">（代行入力オプションご契約のお客様）帳票保管の方法</a>
              <a class="nav-link ms-5 my-1" href="#EvidenceSearch">帳票の検索</a>
              <a class="nav-link ms-5 my-1" href="#NarrativeMap">ナラティブマップの活用</a>
              <a class="nav-link ms-5 my-1" href="#ScannerAlert">電子帳簿保存法のスキャナ要件に関わる警告に関して</a>
              <a class="nav-link ms-5 my-1" href="#Convenience">帳票保管の便利な機能</a>
            </nav>
            <a class="nav-link" href="#Invoice">帳票閲覧機能編</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ms-5 my-1" href="#InvoiceUsage">帳票閲覧の方法</a>
              <a class="nav-link ms-5 my-1" href="#InvoiceLinkage">（保管機能契約済のお客様）当社発行請求書・納品書の帳票保管への保存方法</a>
            </nav>
            <a class="nav-link" href="#Users">ユーザー設定に関して</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ms-5 my-1" href="#UsersTag">ユーザーデフォルトタグ設定</a>
              <a class="nav-link ms-5 my-1" href="#UsersPassword">パスワード変更</a>
            </nav>
            <a class="nav-link" href="#Mobile">モバイル端末でハヤブサバンドを利用する</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ms-5 my-1" href="#MobileUtilization">モバイル端末での帳票保管の利用方法</a>
            </nav> 
          </nav>
        </nav>
      </div>

      <div class="col-md-9 col-sm-12">
        <div class="p-5 overflow-y-auto" data-bs-spy="scroll" data-bs-target="#navbar" data-bs-smooth-scroll="true" tabindex="0" style="height:70vh">

          <div id="ElectronicBook">
            <h2>電子帳簿保存法に関して</h2>
            <article class="row mb-3">
              <p class="my-3">
                ハヤブサバンドをご利用いただく前に、電子帳簿保存法で求められている要件のご理解と、ハヤブサバンドが担保している要件をご確認いただき、
                お客様の運用上問題ないことを検討した上でご利用をお願いいたします。
              </p>
              <div class="notice2 d-flex align-items-center border border-warning border-2 mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <p class="my-3">当マニュアルに含まれる資料は、一般的な情報提供を目的としてのみ提示されているものであり、法律上またはその他の専門的な助言として機能するものではありません。<br><br>
                  また、当マニュアルに記載されている内容に関して、正確かつ最新であるよう努めておりますが、電子帳簿保存法に関する詳細や、お客様個別の事案に関するご質問は、お手数ではございますが、所轄の税務署、専門の税理士や法律顧問にご相談いただくようお願い申し上げます。
                </p>
              </div>
              <div class="ti mb-5">
                <p>電子帳簿保存法の全体像</p>
                <img src="/img/support/img_electronic_book01.png" alt="電子取引関係" class="mb-5" @click="showLigthBox">
              </div>
            </article>

            <article id="ElectronicTrading">
              <h3>電子取引での利用に関して</h3>
              <p>電子取引で受領した帳票類をハヤブサバンド上に保管いただく場合は以下の要件をご確認ください。<br>
              （参照：<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/07denshi/02.htm" target="_blank">国税庁 電子帳簿保存法一問一答【電子取引関係】問9</a>）
            </p>
            <img src="/img/support/img_electronic_trading01.png" alt="電子取引関係" class="mb-5 frame-img" @click="showLigthBox">
            <p>電子取引データ保存に関する詳しい情報は国税庁WEBサイト（<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/tokusetsu/01.htm" target="_blank">https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/tokusetsu/01.htm</a>）をご参照ください。</p>
            <p>ハヤブサバンドは、「データの授受に関しては人の手を介するシステム」にあたるため、不当な訂正・削除を防止するための事務処理規程を用意し、<span style="border-bottom: double 4px red; color: red">改ざん防止のための措置を講じることが必要</span>です。(参照：<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/pdf/00023006-044_03-5.pdf#page=35" target="_blank">国税庁 電子帳簿保存法一問一答【電子取引関係】問39</a>)</p>
            <p>事務処理規程は国税庁のWEBサイトにサンプルがございますので、事前にご準備いただいた上で電子取引データ保存の運用を行ってください。</p>
            
            <ul class="check mb-5">
              <li>電子取引データの訂正及び削除の防止に関する事務処理規程（法人の例）<br>
                （<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_d.docx" target="_blank">https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_d.docx</a>）</li>
              <li>電子取引データの訂正及び削除の防止に関する事務処理規程（個人事業者の例）<br>
                （<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_e.docx" target="_blank">https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_e.docx</a>）</li>
            </ul>
            </article>

            <article id="ScannerSaving" class="mb-3">
              <h3>スキャナ保存での利用に関して</h3>
              <p>取引先から紙で受領した帳票類を、卓上スキャナ、複合機、またはモバイル端末などのスキャン・撮影機能を利用してハヤブサバンド上に保管いただく場合は以下の要件をご確認ください。<br>
                <span class="fw-bold" style="color: red; font-size: 14px;">※令和６年１月１日以後にスキャナ保存が行われる国税関係書類に関しての要件です。令和6年１月１日より前に保存する国税関係書類については上記要件以外の別要件があります。詳しくは国税庁WEBサイトをご参照ください。</span><br>
                (参照：<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/pdf/00023006-044_03-3.pdf#page=16" target="_blank">国税庁 電子帳簿保存法一問一答【スキャナ保存関係】問9</a>)<br>
              </p>
              <ul>
                <li>重要書類（資金や物の流れに直結・連動する書類）：契約書、領収書、請求書、納品書等</li>
                <li>一般書類（資金や物の流れに直結・連動しない書類）：見積書、検収書、注文書、納品書(控え)等</li>
              </ul>
              <img src="/img/support/img_electronic_trading02.png" alt="スキャナ保存に係る要件" class="mb-5 frame-img" @click="showLigthBox">
              <p>スキャナ保存に関する詳しい情報は国税庁WEBサイト（<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/tokusetsu/03.htm" target="_blank">https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/tokusetsu/03.htm</a>）をご参照ください。</p>
              <p>また、事務処理規程は国税庁のWEBサイトにサンプルがございますので、事前にご準備いただいた上でスキャナ保存の運用を行ってください。</p>
              
              <ul class="check">
                <li>スキャナによる電子化保存規程（<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_a.docx" target="_blank">https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_a.docx</a>）</li>
                <li>国税関係書類に係る電子計算機処理に関する事務の手続を明らかにした書類（<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_b.docx" target="_blank">https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_b.docx</a>）</li>
              </ul>
            </article>
          </div>

          <div id="Hayabusaband" class="mb-5">
            <h2>hayabusaBanD（ハヤブサバンド）でできること</h2>
            <div class="row mb-3">
              <p class="my-3">ハヤブサバンドは、帳票専用のストレージサービスです。<br>
                取引情報などをもとに必要な帳票を検索し、素早く確認することができます。</p>
              <div class="ti2">
                <img src="/img/support/img_hayabusa01.png" alt="3つのメリット" class="mb-3" @click="showLigthBox">
              </div>
              <h5>【特徴】</h5>
              <div class="ml-3">
                <ul class="mb-1 pl-3">
                  <li>導入しやすい価格で、電子帳票を保管することが可能です。</li>
                  <li>クラウド上で一元管理できるので、時間やコスト、スペースの低減になります。</li>
                  <li>弊社から発行された請求書もすぐにご覧いただけます。</li>
                </ul>
              </div>
            </div>
          </div>

          <article id="Storage" class="mb-5">
            <h3>帳票保管機能</h3>
            <div class="ml-3">
              <ul class="check mb-1">
                <li>OCRによって入力作業が軽減できます。</li>
                <li>検索3要件（取引日・取引先名・取引金額を指定しての検索、範囲指定、複数組み合わせ）で検索ができ、自由なタグ設定でも絞り込み検索が可能です。</li>
                <li>訂正・削除の履歴を保持し、不正を防止します。</li>
              </ul>
            </div>
            <div class="ti2">
              <img src="/img/support/img_storage01.png" alt="帳票保管機能" class="mb-3 mt-2" @click="showLigthBox">
            </div>
          </article>
          
          <article id="View" class="mb-5">
            <h3>帳票閲覧機能</h3>
            <div class="ml-3">
              <ul class="check mb-1">
                <li>弊社からの請求書の発行をお知らせします。</li>
                <li>シンプルな画面で簡単に操作いただけます。</li>
              </ul>
            </div>
            <div class="ti2">
              <img src="/img/support/img_view01.png" alt="帳票閲覧機能" class="mb-3 mt-2" @click="showLigthBox">
            </div>
          </article>

          <article id="Option" class="mb-5">
            <h3>オプションサービスに関して</h3>
            <h5>【OCRオプション】</h5>
            <p class="my-3">データを保管する際、「取引日」 ・ 「取引先」 ・ 「取引金額」は電子帳簿保存法の必須項目となります。<br>
              OCRオプションをご利用いただくと、データ入力の際に自動的に帳票データ内の文字を認識し、入力項目への初期セットを行う事ができます。お客様は、データの内容を補完入力していただくだけで帳票の保管が完了します。（100%のOCR読取精度を保証するものではありません。）</p>
            <div class="ti">
              <img src="/img/support/img_option01.png" alt="OCRオプション" class="mb-5" @click="showLigthBox">
            </div>
            <h5>【代行入力サービス】</h5>
            <p class="my-3">お客様にてアップロードしていただいた帳票に、「帳票種類」の選択と、電帳法での検索項目に該当する「取引日」 ・ 「取引先名称」 ・ 「取引金額」の３項目を弊社で代行入力します。<br>
              面倒な入力作業は弊社にお任せください。</p>
            <p class="my-3"><span class="border-double">※オプションサービスの導入をご検討の場合は、担当営業、または、<a href="/support/contact">ハヤブサバンドサポート担当</a>までお問い合わせください。</span></p>
          </article>

          <article id="Recommendation" class="mb-5">
            <h3>動作推奨環境</h3>
            <p class="my-4">ハヤブサバンドでの動作推奨環境は、下記のWEBブラウザーです。<br>
              <span class="small">※下記以外のWEBブラウザーに関しては動作確認を行っておりませんので、ご了承ください。</span>
            </p>
            <p>【PC】</p>
            <table class="table">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white">ブラウザー名</th>
                  <th scope="col" class="text-center text-white">入手方法</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">Google Chrome（最新版）</td>
                  <td><a href="https://www.google.com/intl/ja/chrome/" target="_blank">https://www.google.com/intl/ja/chrome/</a></td>
                </tr>
                <tr>
                  <td class="text-center">Microsoft Edge（最新版）</td>
                  <td><a href="https://www.microsoft.com/ja-jp/edge" target="_blank">https://www.microsoft.com/ja-jp/edge</a></td>
                </tr>
              </tbody>
            </table>
            <p class="mt-5">【モバイル端末】</p>
            <table class="table">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white">端末</th>
                  <th scope="col" class="text-center text-white">ブラウザー名</th>
                  <th scope="col" class="text-center text-white">入手方法</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">Android</td>
                  <td class="text-center">Google Chrome（最新版）</td>
                  <td>Google Playストア</td>
                </tr>
                <tr>
                  <td class="text-center">Android</td>
                  <td class="text-center">Microsoft Edge（最新版）</td>
                  <td>Google Playストア</td>
                </tr>
                <tr>
                  <td class="text-center">iPhone</td>
                  <td class="text-center">Apple Safari（最新版）</td>
                  <td>App Store</td>
                </tr>
              </tbody>
            </table>
          </article>

          <div id="StartUp">
            <h2>hayabusaBanDスタートアップガイド</h2>
            <article class="row mb-3">
              <p class="my-3">
                ハヤブサバンドご契約後から帳票保管を行うまでの流れを簡単にご説明します。<br>
                詳細手順は、以下の各章をご参照ください。</p>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <h6>①&nbsp;サインアップ</h6>
                  ご契約後、「お客様番号発行通知書」を電子メールにてお届けします。メール記載のURLより新規ご登録をお願いします。<br>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                  </svg>
                  「<a href="#SignUp">サインアップ編</a>」へ
                </li>
                <li class="list-group-item">
                  <h6>②&nbsp;ユーザーの追加</h6>
                  一番はじめにサインアップしたユーザーは、自テナント内の設定が可能な「テナント管理者」に自動設定されます。<br>
                  サインイン後、テナント管理者が他ユーザーの追加を行う必要があります。他にハヤブサバンドをご利用いただくユーザーがいる場合は、「管理者メニュー」の「ユーザーマスター保守」からユーザーの追加を行ってください。<br>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                  </svg>
                  「<a href="#Maintenance">マスター管理編</a>」の「<a href="#Administrator">テナント管理者によるユーザーの管理</a>」、および、「<a href="#UserMaster">ユーザーマスター保守</a>」へ
                </li>
                <li class="list-group-item">
                  <h6>③&nbsp;ユーザーへの権限設定</h6>
                  ユーザーには、ハヤブサバンド内での様々な操作を可能にする権限を付与する必要があります。「管理者メニュー」の「ユーザー権限保守」から設定を行ってください。<br>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                  </svg>
                  「<a href="#Maintenance">マスター管理編</a>」の「<a href="#Roleaccess">ユーザー権限保守</a>」へ
                </li>
                <li class="list-group-item">
                  <h6>④&nbsp;グループの作成とユーザーの割り当て</h6>
                  グループを設定することで、ユーザーが所属するグループ内だけにデータアクセスを制限することが可能です。<br>
                  「管理者メニュー」の「グループマスター保守」から設定を行ってください。<br>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                  </svg>
                  「<a href="#Maintenance">マスター管理編</a>」の「<a href="#Group">グループマスター保守</a>」へ
                </li>
                <li class="list-group-item">
                  <h6>⑤&nbsp;帳票定義マスターの準備</h6>
                  「請求書」、「領収書」などのお客様が保管する帳票の種類を事前に準備しておく必要があります。<br>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                  </svg>
                  「<a href="#Maintenance">マスター管理編</a>」の「<a href="#EvidenceSettings">帳票定義マスター保守</a>」へ
                </li>
                <li class="list-group-item">
                  <h6>⑥&nbsp;帳票タグマスターの準備</h6>
                  帳票を保管する際に、タグを付けることができ、タグで絞込検索が可能になります。その際のタグを自由に作成できます。<br>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                  </svg>
                  「<a href="#Maintenance">マスター管理編</a>」の「<a href="#EvidenceTag">帳票タグマスター保守</a>」へ
                </li>
                <li class="list-group-item">
                  <h6>⑦&nbsp;帳票保管の実施</h6>
                  <div class="ml-3">
                    <ul class="mb-1">
                      <li>「 【保管】保管帳票一覧」画面を開き「新規アップロード」ボタンを押下し、帳票のアップロードを行います。</li>
                      <li>アップロードされた帳票を一覧から選択して、帳票イメージを見ながら、必要項目を入力します。入力内容に間違いがなければ、「確定」ボタンを押下し、入力内容を確定します。</li>
                    </ul>
                  </div>
                  ※承認機能を利用する場合は、「承認」を行います。「帳票承認設定保守」から変更が可能です。<br>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1z"/>
                  </svg>
                  「<a href="#Evidence">帳票保管機能編</a>」へ
                </li>
              </ul>
            </article>
          </div>

          <div id="SignUp" class="mb-5">
            <h2>サインアップ編</h2>
            <p class="my-3">ハヤブサバンドへのサインアップ手順をご説明いたします。お手元にご契約後にお渡しした「お客様番号発行通知書」をご用意ください。</p>
            <div class="ti">
              <p>①&nbsp;ご契約後に、ハヤブサバンドサポート担当より「お客様番号発行通知書」が発行され、メールで届きます。メール、または、お客様番号発行通知書に記載されているサービスサイトURL（<a href="https://hayabusaband.motojima-idc.com" target="_blank">https://hayabusaband.motojima-idc.com</a>）にアクセスし、「初めての方はこちら」をクリックします。</p>
              <img src="/img/support/img_signup01.png" alt="お客様番号発行通知書" class="mb-5 frame-img" @click="showLigthBox">
              <img src="/img/support/img_signup02.png" alt="サインイン" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="my-3 ti">
              <p>②&nbsp;新規ご利用登録画面の各入力欄に、アカウントIDとして利用するメールアドレス、氏名（漢字・カナ）、「お客様番号発行通知書」に記載のお客様番号、ユーザー登録時認証コードをご入力ください。</p>
              <img src="/img/support/img_signup03.png" alt="新規ご利用登録画面" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="my-3 ti">
              <p>③&nbsp;弊社「個人情報保護方針」および「情報セキュリティ方針」、ハヤブサバンドサービスの「利用規約」をご一読の上、「個人情報の取り扱いおよび利用規約に同意する」にチェックし、「入力内容を確認する」をクリックしてください。</p>
              <img src="/img/support/img_signup04.png" alt="利用規" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="my-3 ti">
              <p>④&nbsp;新規ご利用登録確認画面が表示されますので、ご入力いただいた内容を確認の上、「この内容で登録する」をクリックします。</p>
              <img src="/img/support/img_signup05.png" alt="新規ご利用登録確認画面" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="my-3 ti">
              <p>⑤&nbsp;ご入力いただいたメールアドレス宛に、「【株式会社本島ビジネスセンター】帳票保管・閲覧クラウド(hayabusaBanD)からサービス仮登録のご連絡」というタイトルのメールが届きます。メール内に本登録用URL、および、仮パスワードが記載されていることをご確認ください。</p>
              <img src="/img/support/img_signup06.png" alt="メール「【株式会社本島ビジネスセンター】帳票保管・閲覧クラウド(hayabusaBanD)からサービス仮登録のご連絡」" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="my-3 ti">
              <p>⑥&nbsp;メール内に記載されている仮パスワードをコピーし、本登録用URLをクリックしますと本登録画面が表示されます。<br>
                仮登録メールに記載されている仮パスワードと新たに設定するパスワードをご入力ください。入力後、「本登録を完了する」をクリックします。</p>
              <img src="/img/support/img_signup07.png" alt="本登録完了画面" class="mb-5" @click="showLigthBox">
            </div>
            <div class="my-3 ti">
              <p>⑦&nbsp;本登録完了画面が表示されるとサインアップは終了です。本登録完了は、メールでも通知されます。<br>
                「ダッシュボード画面へ」をクリックするとサインイン後の画面に遷移します。</p>
              <img src="/img/support/img_signup08.png" alt="メール「【株式会社本島ビジネスセンター】帳票保管・閲覧クラウド(hayabusaBanD)からサービス仮登録のご連絡」" class="mb-5 frame-img" @click="showLigthBox">
              <img src="/img/support/img_signup09.png" alt="メール「本登録完了」" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="my-3 ti">
              <p>⑧&nbsp;これでハヤブサバンドがご利用いただけます。次回からは、ご登録いただいたアカウントID、パスワードでサインイン画面（<a href="https://hayabusaband.motojima-idc.com" target="_blank">https://hayabusaband.motojima-idc.com</a>）よりサインインを行ってください。</p>
              <img src="/img/support/img_signup10.png" alt="ハヤブサバンド利用可能" class="mb-3 frame-img" @click="showLigthBox">
            </div>
          </div>

          <div id="Maintenance">
            <h2>マスター管理編</h2>
          </div>
          <article id="Administrator" class="mb-3">
            <h3>テナント管理者によるユーザーの管理</h3>
            <p class="my-3">ハヤブサバンドでは各お客様の契約単位／お客様毎のご利用環境を「テナント」という言葉で表現します。テナントはお客様の環境内でアクセスできるお客様ご自身のデータを定義し、テナントをまたいだデータアクセスを制限する境界として機能します。</p>
            <div class="my-3 ti">
              <img src="/img/support/img_administrator01.png" alt="テナント管理者によるユーザーの管理" class="mb-5" @click="showLigthBox">
            </div>
            <p>ハヤブサバンドではテナント内にサービス利用ユーザーを複数人設定することができます。<br><br>
              また、各テナント内のユーザーをお客様側で一元管理できるようにテナント内には「テナント管理者」という役割を設けて、所属ユーザーの権限や所属グループの管理を行っていただきます。</p>
            <div class="my-3 ti">
              <img src="/img/support/img_administrator02.png" alt="テナント管理者によるユーザーの管理" class="mb-5" @click="showLigthBox">
            </div>
            <p>ハヤブサバンドではテナント内に「グループ」と呼ばれるテナント内でのデータアクセス境界を設定することもできます。<br>
              グループを設定することで同一グループに所属しているユーザー内でのみデータ共有を行うといった運用が可能になります。（グループ機能の利用は任意です。）</p>
            <div class="my-3 ti">
              <img src="/img/support/img_administrator03.png" alt="テナント管理者によるユーザーの管理" class="mb-5" @click="showLigthBox">
            </div>
            <p>テナント管理者ユーザーは、「管理者メニュー」タブからユーザーの管理（ユーザー情報、権限、グループ）を行うことができます。</p>
            <div class="my-3 ti">
              <img src="/img/support/img_administrator04.png" alt="テナント管理者によるユーザーの管理" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="notice border border-primary border-2 mb-5">
              <p class="my-3"><b>テナント管理者に関する仕様は以下の通りです。</b></p>
              <ul class="list-main">
                <li>▶&nbsp;&nbsp;各テナントで<span>一番最初にサインアップが行われたユーザー</span>を自動的にテナント管理者として設定します。</li>
                <li>▶&nbsp;&nbsp;テナント管理者には無条件で、ユーザー権限として<span>「テナントマスター管理者」</span>権限、および、<span>「テナントユーザー管理者」</span>権限が設定されます。</li>
                <li>▶&nbsp;&nbsp;テナント管理者を別のユーザーに設定する場合は、「テナントユーザー管理者」権限保持ユーザーが、ユーザーマスター保守画面より、<span>「テナント管理者への昇格」</span>をON にすることで、テナント管理者に設定することができます。</li>
                <li>▶&nbsp;&nbsp;各テナントに<span>「テナント管理者は1人以上3人以下」</span>のルールで設定する必要があります。テナント内にテナント管理者が3人いる状態で、追加のユーザーをテナント管理者に設定することや、テナント内の最後のテナント管理者を降格させることはできません。</li>
                <li>▶&nbsp;&nbsp;すでにテナント管理者に設定されているユーザーが、自分自身をテナント管理者から降格させることはできません。他のテナント管理者に依頼し、ユーザーマスター保守画面から降格処理を行う必要があります。</li>
                <li>▶&nbsp;&nbsp;テナント管理者をユーザーマスターから削除することはできません。削除するにはテナント管理者から降格させる必要があります。</li>
                <li>▶&nbsp;&nbsp;テナント管理者から降格させると同時に、<span>「テナントマスター管理者」</span>権限、および、<span>「テナントユーザー管理者」</span>権限が削除され、ユーザーマスター保守や、ユーザー権限保守へのアクセス権は失効します。</li>
                <li>▶&nbsp;&nbsp;テナント管理者ではないが、テナント内のユーザーの管理を委任する場合は、ユーザー権限保守画面より、該当ユーザーに<span>「テナントユーザー管理者」</span>権限を設定してください。</li>
                <li>▶&nbsp;&nbsp;テナントユーザー管理者権限を保持するユーザー数に制限は設けていませんが、テナント内ユーザーの全個人情報にアクセスできる権限ですので、<span>「最小特権の原則」</span>に従い設定していただくことが望ましいです。</li>
              </ul>
            </div>
          </article>

          <article id="UserMaster" class="mb-3">
            <h3>ユーザーマスター保守</h3>
            <p class="my-3">ユーザーマスター保守では、お客様テナント内において、ハヤブサバンドを利用する全ユーザーアカウントに関する各種設定を変更することができます。</p>
            <div class="my-3 ti">
              <img src="/img/support/img_usermaster01.png" alt="ユーザーマスター保守" class="mb-5" @click="showLigthBox">
            </div>
            <table class="table num mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white" width="5%">No.</th>
                  <th scope="col" class="text-center text-white" width="25%">入力項目</th>
                  <th scope="col" class="text-center text-white" width="70%">入力内容</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center"></td>
                  <td>氏名（漢字・カナ）</td>
                  <td>メンテナンス対象のアカウントの氏名をそれぞれ40文字以内で入力してください。</td>
                </tr>
                <tr>
                  <td class="text-center"></td>
                  <td>表示名</td>
                  <td>欄外の【補足説明】をご参照ください。</td>
                </tr>
                <tr>
                  <td class="text-center"></td>
                  <td>連絡先メールアドレス</td>
                  <td>登録していただくと、アカウントIDとは別のアドレスでもサービスからの通知を受け取ることができます。</td>
                </tr>
                <tr>
                  <td class="text-center"></td>
                  <td>スタートアップページ</td>
                  <td>サービスにログインしたとき、最初に開くページを選択できます。</td>
                </tr>
                <tr>
                  <td class="text-center"></td>
                  <td>テナント管理者への昇格</td>
                  <td>
                    テナント管理者権限を持つユーザーは、他のユーザーにテナント管理者権限を与えることができます。<br>
                    ただし、テナント管理者は1テナント内に1人以上3人以下という制限があります。<br>
                    また、テナント管理者ユーザーを削除することはできません。ユーザーを削除するにはまずテナント管理者から降格させる必要があります。
                  </td>
                </tr>
              </tbody>
            </table>
            <h5>【補足説明】</h5>
            <p>表示名は、帳票詳細画面などで使用されます。誰がアップロードしたのかを分かりやすく表示させることができる、いわばニックネームのようなものです。また代行入力契約顧客の場合は、弊社側に本名を提示せずに利用することができます。</p>
            <div class="my-3 ti">
              <img src="/img/support/img_usermaster02.png" alt="ユーザーマスター保守" class="mb-5" @click="showLigthBox">
            </div>
            <p>テナントのユーザーにセルフサインアップを依頼せずに、テナント管理者であれば、ユーザーマスター保守からユーザーの追加を行うこともできます。「新規追加」ボタンよりユーザーの追加を行ってください。</p>
            <div class="my-3 ti">
              <img src="/img/support/img_usermaster03.png" alt="ユーザーマスター保守" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <p>ただし、ユーザー数には上限があり、契約時に設定した人数が上限となります。上限に達している場合はユーザーの追加を行うことはできません。上限ユーザー数を増やす場合は、<a href="/support/contact">ハヤブサバンドサポート担当</a>までお問い合わせください。</p>
            <div class="my-3 ti">
              <img src="/img/support/img_usermaster04.png" alt="ユーザーマスター保守" class="mb-5 frame-img" @click="showLigthBox">
            </div>
          </article>

          <article id="Roleaccess" class="mb-3">
            <h3>ユーザー権限保守</h3>
            <p class="my-3">ユーザー権限保守では、ユーザーごとに与える権限を設定することができます。</p>
            <div class="ti">
              <p>①&nbsp;「ユーザー権限保守」を押下し、ご自身のテナントをクリックします。</p>
              <img src="/img/support/img_roleaccess01.png" alt="ユーザー権限保守" class="mb-5 frame-img" @click="showLigthBox">
              <img src="/img/support/img_roleaccess02.png" alt="ユーザー権限保守" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>②&nbsp;権限を付与するユーザーを選択します。</p>
              <img src="/img/support/img_roleaccess03.png" alt="ユーザー権限保守" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>③&nbsp;付与する権限にチェックをつけ、「更新」ボタンを押下してください。</p>
              <img src="/img/support/img_roleaccess04.png" alt="ユーザー権限保守" class="mb-5 frame-img" @click="showLigthBox">
            </div>
          </article>

          <article id="AuthorizationList" class="mb-3">    
            <h3>権限別可能操作一覧表</h3>
            <div class="mb-4 ti">
              <h4><b>&nbsp;&nbsp;●テナントの管理に必要な権限</b></h4>
              <img src="/img/support/img_roleaccess05.png" alt="ユーザー権限保守" class="mb-5" @click="showLigthBox">
            </div>
            <div class="mb-4 ti">
              <h4><b>&nbsp;&nbsp;●当社発行請求書の閲覧に必要な権限</b></h4>
              <img src="/img/support/img_roleaccess06.png" alt="ユーザー権限保守" class="mb-5" @click="showLigthBox">
            </div>
            <div class="mb-4 ti">
              <h4><b>&nbsp;&nbsp;●帳票保管機能の利用に必要な権限（承認機能なしのお客様）</b></h4>
              <img src="/img/support/img_roleaccess07.png" alt="ユーザー権限保守" class="mb-5" @click="showLigthBox">
            </div>
            <div class="mb-4 ti">
              <h4><b>&nbsp;&nbsp;●帳票保管機能の利用に必要な権限（承認機能ありのお客様）</b></h4>
              <img src="/img/support/img_roleaccess08.png" alt="ユーザー権限保守" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti">
              <h4><b>&nbsp;&nbsp;●帳票保管機能の利用に必要な権限（代行入力オプションご契約のお客様）</b></h4>
              <img src="/img/support/img_roleaccess09.png" alt="ユーザー権限保守" class="mb-5" @click="showLigthBox">
            </div>
          </article>

          <article id="CaseStudies" class="mb-3">
            <h3>権限設定のケーススタディ</h3>
            <div class="mb-4 ti">
              <p>例１.「○○支店のＡさんに帳票のアップロードを行ってもらうが、本部のBさんがデータ入力を一括で行いたい。尚、課長のCさんが一次承認を行い、部長のDさんが二次承認（最終承認）をして、帳票の確定を行いたい。」というシチュエーションの場合</p>
              <p>＜Aさんの権限＞&nbsp;&nbsp;～アップロードユーザー～<br><br>
                Ａさんは、帳票のアップロードのみ行います。必要な権限の、「保管サービス利用者」、「帳票アップロード者」の2項目にチェックを付け、「更新」ボタンを押下して権限を付与します。</p>
              <img src="/img/support/img_roleaccess10.png" alt="権限設定のケーススタディ" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="mb-4 ti">
              <p>＜Bさんの権限＞&nbsp;&nbsp;～エントリーユーザー～<br><br>
                Ｂさんは、Aさんがアップロードした帳票を見ながら、必須項目を入力まで行います。<br>
                必要な権限の、「保管サービス利用者」、「帳票データ代理入力者」の2項目にチェックを付け、「更新」ボタンを押下して権限を付与します。<br>
                （※この時、Bさんも帳票をアップロードする場合は、「帳票アップロード者」の項目も併せてチェックを付けてください。）</p>
              <img src="/img/support/img_roleaccess11.png" alt="権限設定のケーススタディ" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="mb-4 ti">
              <p>＜Cさんの権限＞&nbsp;&nbsp;～一次承認者～<br><br>
                Cさんは、Bさんが入力したデータ内容を確認して、一次承認まで行います。<br><br>
                必要な権限の、「保管サービス利用者」、「帳票データ一次承認者」の2項目にチェックを付け、「更新」ボタンを押下して、権限を付与します。</p>
              <img src="/img/support/img_roleaccess12.png" alt="権限設定のケーススタディ" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>＜Dさんの権限＞&nbsp;&nbsp;～二次承認者（最終承認者）～<br><br>
                Dさんは、Cさんが一次承認したデータ内容を確認して、二次承認（最終承認）を行います。必要な権限の、「保管サービス利用者」、「帳票データ二次承認者」の2項目にチェックを付け、「更新」ボタンを押下して、権限を付与します。</p>
              <img src="/img/support/img_roleaccess13.png" alt="権限設定のケーススタディ" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
          </article>

          <article id="Group" class="mb-3">
            <h3>グループマスター保守</h3>
            <p class="mt-3 mb-5">グループマスター保守では、お客様テナント内に「グループ」と呼ばれる帳票データへのアクセス制限を設定することができます。<br>
              グループ機能は任意機能であり、グループを設定せずに帳票保管機能をご利用いただくことも可能です。また、後からグループを設定し、利用することも可能です。</p>
            <h4>グループの概念</h4>
            <div class="ti">
              <p class="my-3">グループの設定例とともに、グループの概念をご説明します。</p>
              <p>グループ機能を利用するには、</p>
              <ul>
                <li>▶&nbsp;&nbsp;グループを作成する。（初回、および、随時）</li>
                <li>▶&nbsp;&nbsp;作成したグループにユーザーを割り当てる。（初回、および、随時）</li>
                <li>▶&nbsp;&nbsp;帳票アップロード時に、その帳票にアクセス可能なグループを設定する。</li>
              </ul>
              <p>といった手順を実施します。</p>
            </div>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group01.png" alt="グループマスター保守" class="mt-3 mb-5" @click="showLigthBox">
            </div>
            <p class="mt-2 mb-5">上図で表す通り、グループ機能を利用することで、ユーザーはグループの境界を越えて帳票データにアクセスを行うことはできません（営業部、開発部）。<br>
              「特権グループ」は特殊なグループで、グループ境界を越えて全帳票にアクセスを行いたいような部門や部署に設定することができます。<br>
              ここでは経理部グループが「特権グループ」に当たります。また、開発部Fさんのように、複数のグループにまたがって所属することも可能です。</p>
            <h4>グループとユーザー権限の関係</h4>
            <p class="my-3">次にグループ設定を行ったテナントでユーザー権限がどのように機能するのかをご説明します。</p>
            <p>下図にある通り、グループに所属するユーザーの場合、ユーザーに設定された権限はそのグループ内でのみ機能します。<br>
              例えば、「帳票代理入力者」権限ですが、グループを設定していないテナントの場合、そのテナント内の全帳票への読取や入力を行うことができますが、グループに所属するユーザーの場合は、自身が所属するグループ内の帳票データにのみ読取や入力を行うことができます。他のユーザー権限に関しても同様です。<br><br>
              また、グループに所属しないユーザー（ここでは「グループ無所属Xさん」）がアップロードした帳票にはグループアクセス権が付与されません。<br>
              この場合は、各ユーザーのユーザー権限をもとに帳票データへのアクセス可否が判定されます。<br>
              つまり、全帳票を読み取ることができるユーザー権限（帳票データ閲覧者や帳票データ代理入力者権限）があれば、誰でもアクセスできてしまいますのでご注意ください。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group02.png" alt="グループマスター保守" class="mt-3 mb-5" @click="showLigthBox">
            </div>
            <h4>グループマスター設定手順</h4>
            <h5>●&nbsp;新規グループの作成</h5>
            <p>①&nbsp;管理者メニューから「グループマスター保守」を起動し、「新規追加」ボタンをクリックします。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group03.png" alt="グループマスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <p>②&nbsp;グループマスターの新規追加画面が表示されるので以下の表に従い入力を行い、「登録」ボタンをクリックします。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group04.png" alt="グループマスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <table class="table num mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white" width="5%">No.</th>
                  <th scope="col" class="text-center text-white" width="25%">入力項目</th>
                  <th scope="col" class="text-center text-white" width="70%">入力内容</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center"></td>
                  <td>グループ名</td>
                  <td>グループ名を自由に入力します。</td>
                </tr>
                <tr>
                  <td class="text-center"></td>
                  <td>特権グループ</td>
                  <td>「ON」の場合（特権グループ）：グループ境界を無視して全帳票にアクセス可能なグループに設定します。「特権グループへの昇格確認」ダイアログが表示されるので「はい」をクリックしてください。<br>
                      「OFF」（一般グループ）：グループ内のデータへのアクセスのみ可能なグループに設定します。</td>
                </tr>
              </tbody>
            </table>
            <p>③&nbsp;お客様のご利用環境に合わせて、グループを準備してください。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group05.png" alt="グループマスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <h5>●&nbsp;グループへのユーザー割り当て</h5>
            <p class="mb-3">追加したグループにはユーザーを割り当てる必要があります。以下の手順に従ってください。</p>
            <p>①&nbsp;グループ一覧の左側に表示されている自テナントのユーザーをマウスでドラッグし、ユーザーを割り当てたいグループまで移動しドロップします。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group06.png" alt="グループマスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <p>②&nbsp;対象のグループにユーザーが割り当てられたことを確認してください。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group07.png" alt="グループマスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <p>※「Ctrl」キーを押下しながら、割り当て対象ユーザーをクリックすることで、複数のユーザーを一括でグループに割り当てることも可能です。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group08.png" alt="グループマスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <h5>●&nbsp;グループからのユーザーの除外</h5>
            <p class="mb-3">グループに割り当てられたユーザーをグループから除外する場合は以下の手順に従ってください。（グループからユーザーを除外しても、ユーザーアカウントの削除は行われません。）</p>
            <p>①&nbsp;グループから除外するユーザーにマウスカーソルを当てると、<img src="/img/support/img_group09.png" alt="グループマスター保守" class="p-img">アイコンが表示されるのでクリックを行います。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group10.png" alt="グループマスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <p>②&nbsp;ユーザーのグループからの除外確認メッセージが表示されます。よろしければ、「はい」をクリックしてください。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_group11.png" alt="グループマスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
          </article>

          <article id="Partner">
            <h3>取引先マスター保守</h3>
            <div class="mb-3">
              <p>取引先マスター保守では、よく取引を行う取引先名を登録しておくことで、帳票情報入力時の負担を軽減することが可能です。<br>
                「新規追加」ボタンを押下し、内容の入力を行います。</p>
              <img src="/img/support/img_partner01.png" alt="取引先マスター保守" class="mt-3 mb-2 frame-img" @click="showLigthBox">
              <img src="/img/support/img_partner02.png" alt="取引先マスター保守" class="mt-3 mb-5" @click="showLigthBox">
              <p>設定の詳細は下記の表をご参照ください。</p>
              <table class="mb-2 table num">
                <thead>
                  <tr class="table-primary">
                    <th scope="col" class="text-center text-white" width="5%">No.</th>
                    <th scope="col" class="text-center text-white" width="22%">入力項目</th>
                    <th scope="col" class="text-center text-white" width="73%">入力内容</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center"></td>
                    <td>取引先番号</td>
                    <td>自動採番されます。入力はできません。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>事業者番号</td>
                    <td>（OCRオプション契約済のお客様の場合）<br>
                        OCRされた適格請求書発行事業者登録番号でこの値を索引することで取引先名を取得することが可能です。別途OCRモデル構築時に適格請求書発行事業者登録番号のOCR設定が必要です。<br>
                        （OCRオプション未契約のお客様の場合）<br>
                        未入力可能です。（現在、サービスでは利用していません。）
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>取引先名</td>
                    <td>取引先名称を入力してください。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>取引先名カナ</td>
                    <td>未入力可能です。（現在、サービスでは利用していません。）</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>OCR検索用取引先名</td>
                    <td>（OCRオプション契約済のお客様の場合）<br>
                        帳票のOCRを行ったときに取引先名の一部しか正確に読み取らない時に、OCR検索用文字列を設定しておくことで、実際の取引先名に読替を行います。<br>
                        例）OCRで取引先名を「株式会社本島ビジネ」と毎回読み取る場合、OCR検索用文字列に「株式会社本島ビジネ」と登録しておき、取引先名に正式な「株式会社本島ビジネスセンター」と設定しておけば自動的に読替を行います。<br>
                        （OCRオプション未契約のお客様の場合）<br>
                        非表示になります。入力することはできません。
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>郵便番号</td>
                    <td>未入力可能です。（現在、サービスでは利用していません。）</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>住所1</td>
                    <td>未入力可能です。（現在、サービスでは利用していません。）</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>住所2</td>
                    <td>未入力可能です。（現在、サービスでは利用していません。）</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>メールアドレス</td>
                    <td>未入力可能です。（現在、サービスでは利用していません。）</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>電話番号</td>
                    <td>未入力可能です。（現在、サービスでは利用していません。）</td>
                  </tr>
                </tbody>
              </table>
              <p class="mb-5">入力が終わりましたら、「登録」ボタン ・ 「更新」ボタンを押下してください。</p>
            </div>
          </article>

          <article id="EvidenceSettings">
            <h3>帳票定義マスター保守</h3>
            <div class="row mb-3">
              <p class="my-3">帳票定義マスター保守では、お客様が帳票保管対象として取り扱う帳票の種類を設定することができます。<br>
                新しく登録する際は、「新規追加」ボタンを押下してください。</p>
              <div class="my-3 ms-5 ti">
                <img src="/img/support/img_settings01.png" alt="帳票定義マスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
                <img src="/img/support/img_settings02.png" alt="帳票定義マスター保守" class="mt-3 mb-3 frame-img" @click="showLigthBox">
              </div>
              <div class="mb-5 ms-5 ti">
                <p class="my-3">設定の詳細は下記の表をご参照ください。入力が終わりましたら、「登録」ボタンを押下してください。</p>
                <img src="/img/support/img_settings03.png" alt="帳票定義マスター保守" class="mt-3 mb-5" @click="showLigthBox">
              </div>
              <table class="table num mb-5">
                <thead>
                  <tr class="table-primary">
                    <th scope="col" class="text-center text-white" width="5%">No.</th>
                    <th scope="col" class="text-center text-white" width="25%">入力項目</th>
                    <th scope="col" class="text-center text-white" width="70%">入力内容</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center"></td>
                    <td>帳票名</td>
                    <td>保管する帳票の名称を入力してください。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>表示順</td>
                    <td>保管帳票詳細画面で帳票種類選択時の表示順を設定することができます。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>授受区分</td>
                    <td>他社からの受領と自社発行の控えのどちらかを選択してください。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>帳票保管年数</td>
                    <td>対象帳票の保管年数を設定します。帳票詳細画面で入力された「取引日付」から、帳票が完全に消去されるまでの年数を1～20の範囲で入力してください。<br>
                    <span class="fw-bold" style="color: red;">※ナラティブマップをご利用されるお客様は、ここで設定した保管年数よりも優先的にナラティブマップの帳票保管年数が適用されます。</span></td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>デフォルト帳票タグ</td>
                    <td>（OCRオプション契約済のお客様の場合）帳票をアップロードした時点でチェックをつけたタグが自動でセットされます。<br>
                    （OCRオプション未契約のお客様の場合）帳票詳細画面から帳票種類を選択すると、その帳票種類に紐づけられたタグがセットされます。
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>スキャナ保存要件に関する設定：解像度警告表示</td>
                    <td>「警告を表示しない」を「オン」にした場合、電子帳簿保存法のスキャナ保存の解像度要件を満たさない帳票がアップロードされても帳票保管機能の帳票詳細画面で警告表示が行われなくなります。電帳法スキャナ保存対象外の帳票の場合、「オン」に設定してください。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>スキャナ保存要件に関する設定：階調警告表示</td>
                    <td>「警告を表示しない」を「オン」にした場合、電子帳簿保存法のスキャナ保存の階調要件を満たさない帳票がアップロードされても帳票保管機能の帳票詳細画面で警告表示が行われなくなります。電帳法スキャナ保存対象外の帳票の場合、「オン」に設定してください。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>帳票種類／OCR取得判定値</td>
                    <td>（OCRオプション契約済のお客様の場合）OCRが帳票種類の判別に成功した時に扱う帳票種類と、OCRで文字を読み取った際に設定した精度以上でないと帳票詳細画面に表示されないという基準値を設定できます。</td>
                  </tr>
                </tbody>
              </table>
              <div class="notice2 d-flex align-items-center border border-warning border-2 mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <p class="my-3">【帳票保管年数に関して】<br>
                  ナラティブマップをご利用いただくお客様は、帳票定義マスター保守画面にて設定を行った帳票保管年数とナラティブマップで設定されている帳票保管年数設定の違いにより、帳票の実際の保管年数が変わる可能性があります。
                  詳しくは、<a href="#MapRetentionPeriod">[帳票保管機能編]－[ナラティブマップの活用]－[ナラティブマップのその他の機能]の「【ナラティブマップの保管期間設定】」</a>をご参照ください。
                </p>
              </div>
              <h5 id="OcrEvidenceSettings">（OCRオプション契約済のお客様）OCR用設定に関して</h5>
              <p class="mb-3">OCRオプションご契約済みのお客様は帳票定義マスターでOCR解析に関わる設定値を調整することが可能です。</p>
              <div class="my-3 ms-5 ti">
                <img src="/img/support/img_settings04.png" alt="帳票定義マスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
              </div>
              <table class="table num">
                <thead>
                  <tr class="table-primary">
                    <th scope="col" class="text-center text-white" width="5%">No.</th>
                    <th scope="col" class="text-center text-white" width="25%">設定項目</th>
                    <th scope="col" class="text-center text-white" width="70%">設定内容</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center"></td>
                    <td>帳票判別判定値</td>
                    <td>OCRが帳票種類を自動的に判別成功した時に、設定した信頼度以上のときのみ判別した帳票種類として扱う閾値を設定します。
                        閾値より信頼度が低かった場合は、帳票データの全項目で手入力が必要です。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>金額OCR取得判定値</td>
                    <td>取引金額のOCRに成功した場合、設定した信頼度以上のときのみ読み取った金額として扱う閾値を設定します。
                        閾値より信頼度が低かった場合は、取引金額は手入力が必要です。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>取引先OCR取得判定値</td>
                    <td>取引先名のOCRに成功した場合、設定した信頼度以上のときのみ読み取った取引先名として扱う閾値を設定します。
                        閾値より信頼度が低かった場合は、取引先名は手入力が必要です。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>取引日OCR取得判定値</td>
                    <td>取引日のOCRに成功した場合、設定した信頼度以上のときのみ読み取った取引日として扱う閾値を設定します。
                        閾値より信頼度が低かった場合は、取引日は手入力が必要です。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>件名OCR取得判定値</td>
                    <td>件名・摘要欄などのOCRに成功した場合、設定した信頼度以上のときのみ読み取った件名・摘要欄として扱う閾値を設定します。
                        閾値より信頼度が低かった場合は、件名・摘要欄は手入力が必要です。</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td>登録番号OCR取得判定値</td>
                    <td>適格請求書発行事業者登録番号のOCRに成功した場合、設定した信頼度以上のときのみ読み取った登録番号として扱う閾値を設定します。
                        閾値より信頼度が低かった場合は、登録番号を利用して取引先名は決定されずに取引先名のOCR結果が利用されます。</td>
                  </tr>
                </tbody>
              </table>
              <p class="mt-5">※OCR時の取引先名の決定方法</p>
              <div class="mb-3 ms-5 ti">
                <img src="/img/support/img_settings05.png" alt="帳票定義マスター保守" class="mb-5" @click="showLigthBox">
              </div>
              <p class="my-3">設定内容の確認は「OCR動作テスト」機能をご利用ください。<br>
                ※&nbsp;OCR動作テストの実行回数もOCRオプションの月額課金に従い課金カウント対象となりますので、大量のテスト実行には十分ご留意ください。</p>
              <div class="ti">
                <p>①&nbsp;帳票定義マスターの「OCR動作テスト」をクリックします。</p>
                <div class="my-3 ms-5 ti">
                  <img src="/img/support/img_settings06.png" alt="帳票定義マスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
                </div>
              </div>
              <div class="ti">
                <p>②&nbsp;ファイルアップロードダイアログが表示されますので、「ファイルを選択」ボタンを押下しテスト対象ファイルを選択するか、このダイアログ内にテスト対象ファイルをドラッグ＆ドロップを行ってください。</p>
                <div class="my-3 ms-5 ti">
                  <img src="/img/support/img_settings07.png" alt="帳票定義マスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
                </div>
              </div>
              <div class="ti">
                <p>③&nbsp;OCRテスト結果が表示されます。内容をご確認いただき、OCR取得判定値の調整を行ってください。<br>
                  なお、読取項目の変更など、OCRモデル自体の改善が必要な場合は、担当営業、または、ハヤブサバンドサポート担当へご連絡お願いします。</p>
                <div class="my-3 ms-5 ti">
                  <img src="/img/support/img_settings08.png" alt="帳票定義マスター保守" class="mt-3 mb-5" @click="showLigthBox">
                </div>
              </div>
            </div>
          </article>

          <article id="EvidenceTag" class="mb-3">
            <h3>帳票タグマスター保守</h3>
            <p class="my-3">帳票タグマスター保守では、帳票につけるタグを作成することが可能です。「新規追加」ボタンを押下し、内容の入力を行います。</p>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_evidencetag01.png" alt="帳帳票タグマスター保守" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ml-3">
              <ul class="check mb-1">
                <li>背景や文字の色を自由に設定することができます。</li>
                <li>入力が終わりましたら、「登録」ボタンを押下してください。</li>
              </ul>
            </div>
            <div class="my-3 ms-5 ti">
              <img src="/img/support/img_evidencetag02.png" alt="帳帳票タグマスター保守" class="mt-3 mb-3" @click="showLigthBox">
            </div>
          </article>

          <div id="Evidence">
            <h2>帳票保管機能編</h2>
          </div>
          <article id="EvidenceFlow" class="mb-3">
            <h3>帳票保管の流れ</h3>
            <p class="my-3">帳票の保管は、下記の図の流れで進めていきます。お客様のご契約内容・承認設定により複数のパターンが考えられますので、お客様の環境に合わせてご参照ください。</p>
            <img src="/img/support/img_flow01.png" alt="帳票保管機能編 帳票保管の流れ" class="mt-3 mb-2" @click="showLigthBox">
            <img src="/img/support/img_flow02.png" alt="帳票保管機能編 帳票保管の流れ" class="mt-3 mb-2" @click="showLigthBox">
            <img src="/img/support/img_flow03.png" alt="帳票保管機能編 帳票保管の流れ" class="mt-3 mb-2" @click="showLigthBox">
            <img src="/img/support/img_flow04.png" alt="帳票保管機能編 帳票保管の流れ" class="mt-3 mb-5" @click="showLigthBox">
          </article>
          
          <article id="StorageList" class="mb-3">
            <h3>帳票保管一覧画面について</h3>
            <p class="my-3">帳票保管一覧画面の表示項目をご説明します。</p>
            <img src="/img/support/img_storagelist01.png" alt="帳票保管機能編 帳票保管一覧画面について" class="mt-3 mb-5 frame-img" @click="showLigthBox">
            <table class="mb-2 table">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white" width="5%">No.</th>
                  <th scope="col" class="text-center text-white" width="22%">項目</th>
                  <th scope="col" class="text-center text-white" width="73%">説明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">&#9312;</td>
                  <td>未確定数</td>
                  <td>未確定（ステータス表示が「確定済」ではない）帳票の合計数を表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9313;</td>
                  <td>未承認数(1次)</td>
                  <td>(承認機能ありのお客様の場合のみ表示)<br>
                      1次承認を行う必要がある帳票の合計数を表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9314;</td>
                  <td>未承認数(2次)</td>
                  <td>(承認機能あり、かつ、2次承認設定のお客様の場合のみ表示)<br>
                      2次承認を行う必要がある帳票の合計数を表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9315;</td>
                  <td>本日保管数</td>
                  <td>本日確定された帳票の合計数を表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9316;</td>
                  <td>当月保管数</td>
                  <td>当月、確定された帳票の合計数を表示します。また、前月比として前月保管数と比較した、当月保管数の割合をパーセントで表示します。<br>
                      ※確定後削除された帳票は含みません。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9317;</td>
                  <td>前月保管数</td>
                  <td>前月中に確定された帳票の合計数を表示します。<br>
                      ※確定後削除された帳票は含みません。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9318;</td>
                  <td>総保管数</td>
                  <td>お客様テナントの確定済み帳票の合計数を表示します。<br>
                      ※確定後削除された帳票は含みません。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9319;</td>
                  <td>使用容量</td>
                  <td>お客様テナントにアップロードされている帳票ファイルの総ファイルサイズを表示します。<br>
                      ※この項目はリアルタイムで集計は行われず、1日4回、特定の時刻に集計を行います。<br>
                      ※未確定のファイルも集計されます。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9320;</td>
                  <td>表示切替スイッチ</td>
                  <td>全データ表示モード：ユーザーがアクセスすることのできる帳票を全て表示します。<br>
                      エントリー／承認モード：ユーザーに付与されている権限に合わせ、そのユーザーが処理（入力や承認等）を行うことができる帳票のみ表示されます。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9321;</td>
                  <td>新規アップロードボタン</td>
                  <td>アップロード権限が付与されているユーザーのみ表示されます。帳票ファイルのアップロードを行うことができます。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9322;</td>
                  <td>絞り込み検索</td>
                  <td>帳票データの絞り込み検索を行うことができます。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9323;</td>
                  <td>アクティビティ履歴ボタン</td>
                  <td>テナント内のユーザーが行った操作を時系列で表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9324;</td>
                  <td>更新ボタン</td>
                  <td>表示画面の再読み込みを行います。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9325;</td>
                  <td>No</td>
                  <td>現在一覧表示されている帳票の番号を1から採番します。ある帳票ファイルに固定で振られる番号ではないため、一覧表示内容が変わればこの番号自体も変わるのでご注意ください。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9326;</td>
                  <td>ステータス</td>
                  <td>現在の帳票がどのような状況にあるのかを表示します。詳細は、下部の「帳票ステータス一覧」、および、「帳票警告アイコン一覧」をご参照ください。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9327;</td>
                  <td>種類</td>
                  <td>帳票の帳票種類（請求書、領収書、etc・・・）を表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9328;</td>
                  <td>取引日</td>
                  <td>帳票の取引日を表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9329;</td>
                  <td>取引先名称</td>
                  <td>帳票の取引先名を表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9330;</td>
                  <td>取引金額</td>
                  <td>帳票の取引金額を表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#9331;</td>
                  <td>タグ</td>
                  <td>帳票にセットされているタグを表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#12881;</td>
                  <td>グループ</td>
                  <td>(グループ機能をご利用されているお客様)<br>
                      グループが設定されている帳票にはアイコンが表示されます。マウスカーソルを当てることで帳票に設定されているグループをポップアップ表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#12882;</td>
                  <td>摘要</td>
                  <td>「件名・摘要」が入力されている帳票にはアイコンが表示されます。マウスカーソルを当てることで「件名・摘要」の内容をポップアップ表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#12883;</td>
                  <td>マップ</td>
                  <td>ナラティブマップ設定済みの帳票にはアイコンが表示されます。マウスカーソルを当てることで帳票に設定されているマップコードをポップアップ表示します。また、アイコンをクリックすることでナラティブマップ画面に遷移します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#12884;</td>
                  <td>アップロード日</td>
                  <td>帳票ファイルのアップロード日を表示します。</td>
                </tr>
                <tr>
                  <td class="text-center">&#12885;</td>
                  <td>ダウンロードボタン</td>
                  <td>このボタンをクリックすることで、帳票ファイルをダウンロードすることが可能です。</td>
                </tr>
              </tbody>
            </table>
            <p class="mb-5">①～⑦は自身が所属するグループの帳票件数が表示されます。⑧の「使用容量」は、お客様テナント全体の値が表示され、自身が所属していないグループの使用分も含まれますのでご注意ください。</p>
            <h4>帳票保管ステータス一覧</h4>
            <table class="mb-2 table">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white">ステータス</th>
                  <th scope="col" class="text-center text-white">説明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><img src="/img/support/img_state01.png" alt="帳票保管ステータス一覧" class="state-img"></td>
                  <td>入力権限保持ユーザーによる帳票の入力が必要な場合に表示されます。</td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state02.png" alt="帳票保管ステータス一覧" class="state-img"></td>
                  <td>入力権限保持ユーザーによる入力が完了し、一次承認権限を保持するユーザーによる承認を待っている場合に表示されます。</td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state03.png" alt="帳票保管ステータス一覧" class="state-img"></td>
                  <td>一次承認権限保持ユーザーによる一次承認が完了し、二次承認権限を保持するユーザーによる承認を待っている場合に表示されます。</td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state04.png" alt="帳票保管ステータス一覧" class="state-img"></td>
                  <td>入力・承認すべての作業が完了し、本登録となった場合に表示されます。</td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state05.png" alt="帳票保管ステータス一覧" class="state-img"></td>
                  <td>一次承認権限保持ユーザーによって、アップロード権限を保持するユーザーに差戻され、差替えアップロードが必要な場合に表示されます。</td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state06.png" alt="帳票保管ステータス一覧" class="state-img"></td>
                  <td>一次承認権限保持ユーザーによって、入力権限を保持するユーザーに差戻され、入力が必要な場合に表示されます。</td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state07.png" alt="帳票保管ステータス一覧" class="state-img"></td>
                  <td>二次承認権限保持ユーザーによって、アップロード権限を保持するユーザーに差戻され、差替えアップロードが必要な場合に表示されます。</td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state08.png" alt="帳票保管ステータス一覧"></td>
                  <td>二次承認権限保持ユーザーによって、入力権限を保持するユーザーに差戻され、入力が必要な場合に表示されます。</td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state09.png" alt="帳票保管ステータス一覧" class="state-img"></td>
                  <td>二次承認権限保持ユーザーによって、一次承認権限を保持するユーザーに差戻され、承認またはアップロード権限 / 入力権限を保持するユーザーに差戻しが必要な場合に表示されます。</td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state10.png" alt="帳票保管ステータス一覧" class="state-img"></td>
                  <td>(OCRオプションをご契約されているお客様の場合のみ表示)<br>
                      OCR処理に失敗した場合に表示されます。</td>
                </tr>
              </tbody>
            </table>
            <p class="mb-5">※代行入力ご契約のお客様の場合は、上記表記とは若干異なりますのでご注意ください。</p>
            <h4>帳票警告アイコン一覧</h4>
            <p>帳票保管一覧画面上に表示される警告アイコンの意味をご説明します。</p>
            <table class="mb-5 table">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white" width="10%">アイコン</th>
                  <th scope="col" class="text-center text-white" width="20%">表示条件</th>
                  <th scope="col" class="text-center text-white" width="65%">説明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><img src="/img/support/img_state_duplicate01.png" alt="帳票警告アイコン一覧" class="state-img"></td>
                  <td>重複ファイルを検知</td>
                  <td>既にアップロードされているファイルと同一のファイルが再びアップロードされた場合に表示されます。帳票詳細画面でファイル内容が同一か確認し、同一内容のファイルであれば削除を行ってください。
                    <img src="/img/support/img_state_duplicate02.png" alt="帳票警告アイコン一覧" class="state-img mt-4" @click="showLigthBox">
                  </td>
                </tr>
                <tr>
                  <td><img src="/img/support/img_state_scanner01.png" alt="帳票警告アイコン一覧" class="state-img"></td>
                  <td>スキャナ保存要件チェック警告ファイル</td>
                  <td>アップロードされた帳票ファイルが、電子帳簿保存法のスキャナ保存要件を満たさない可能性がある場合に表示されます。帳票詳細画面で、詳しい警告状況（解像度・階調）を確認し、差替えアップロードなどの適切な対処を行ってください。
                    <img src="/img/support/img_state_scanner02.png" alt="帳票警告アイコン一覧" class="state-img frame-img mt-4" @click="showLigthBox">
                  </td>
                </tr>
              </tbody>
            </table>
          </article>

          <article id="EvidenceNocontract" class="mb-3">
            <h3>帳票保管の方法</h3>
            <div class="ti">
              <p>①&nbsp;保管帳票一覧画面に遷移し、新規アップロードボタンを押下します。</p>
              <img src="/img/support/img_nocontract01.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>②&nbsp;以下の新規帳票アップロードダイアログ画面が表示されますので、「ファイルを選択」ボタンを押下しアップロード対象ファイルを選択するか、このダイアログ内にアップロード対象ファイルのドラッグ＆ドロップを行ってください。<br><br>
                グループに所属するユーザーの場合は、所属するグループがアップロード先グループとして初期セットされています。グループへのアップロードを行わない場合は、グループ名をクリックすることでアップロード先から除外することが可能です。</p>
              <img src="/img/support/img_nocontract02.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div>
              <div id="UploadMode" class="subtitle">（1）&nbsp;モードに関して</div>
              <div class="subtext">帳票アップロード時には、3つのモードを切り替え可能です。お客様のご利用環境や、対象帳票に合わせて選択を行ってください。</div>
              <img src="/img/support/img_nocontract03.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <table class="mb-5 table">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white" width="15%">モード名称</th>
                  <th scope="col" class="text-center text-white" width="50%">画面内容</th>
                  <th scope="col" class="text-center text-white" width="35%">説明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>自動判別</td>
                  <td><img src="/img/support/img_nocontract04.png" alt="帳票保管機能編 帳票保管の方法" class="state-img my-2" @click="showLigthBox"></td>
                  <td>アップロードされた帳票ファイルの内容から「電子取引」、「スキャナ保存」の自動判別を行います。<br>
                    <span class="fw-bold" style="color: red;">※通常はこのモードを利用していただき、自動判別が意図しない結果になり警告表示が行われてしまうなどの時には別のモードでアップロードしていただくことを推奨します。</span>
                  </td>
                </tr>
                <tr>
                  <td>電子取引としてアップロード</td>
                  <td><img src="/img/support/img_nocontract05.png" alt="帳票保管機能編 帳票保管の方法" class="state-img my-2" @click="showLigthBox"></td>
                  <td>「電子取引」データとして帳票アップロードを行う時に利用します。このモードでアップロードされた帳票ファイルは、電子取引データとして保管され、解像度や階調のチェックは行われず警告表示を行いません。</td>
                </tr>
                <tr>
                  <td>スキャナ保存としてアップロード</td>
                  <td><img src="/img/support/img_nocontract06.png" alt="帳票保管機能編 帳票保管の方法" class="state-img my-2" @click="showLigthBox"></td>
                  <td>「スキャナ保存」データとして帳票アップロードを行う時に利用します。このモードでアップロードされた帳票ファイルは解像度や階調のチェックが強制的に行われ、スキャナ保存要件を満たさない場合は、警告表示が行われます。</td>
                </tr>
              </tbody>
            </table>
            <div>
              <div class="subtext">「次回もこのモードを利用する」にチェックをつけることで、現在のアップロードモードを保存することができ、次回の新規アップロードボタン押下時に保存したモードでダイアログが表示されます。「閉じる」ボタン押下時にモードは保存されます。</div>
              <img src="/img/support/img_nocontract07.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div>
              <div id="UploadGroup" class="subtitle">（2）&nbsp;アップロード先グループ設定に関して</div>
              <div class="subtext">グループに所属するユーザーの場合は、所属するグループがアップロード先グループとして初期セットされています。アップロード先グループを変更する場合は、「グループを編集する」ボタンをクリック後、グループ名を選択することでアップロード先グループの割り当て／除外が可能です。</div>
              <img src="/img/support/img_nocontract08.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div>
              <div class="subtitle">（3）&nbsp;マップコード設定に関して</div>
              <div class="subtext">マップコードを指定してアップロードを行うこともできます。マップコードを指定した場合、アップロードごとに同一のマップコードが帳票ファイルに設定されます。マップコード入力欄横の虫眼鏡アイコンをクリックすることで、マップコード検索ダイアログを表示し、マップコードを検索、および、設定することもできます。
                マップコードに関しては<a href="#NarrativeMap">[帳票保管機能編]の「ナラティブマップの活用」</a>ページをご参照ください。</div>
              <img src="/img/support/img_nocontract09.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>③&nbsp;緑のチェックマークがついたらファイルのアップロード完了です。「閉じる」ボタンを押下してください。</p>
              <img src="/img/support/img_nocontract10.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>④&nbsp;ダイアログを閉じるとアップロードされた帳票が保管帳票一覧に表示されています。この時、帳票はまだ仮登録（黄色いステータスアイコン）の状態です。</p>
              <img src="/img/support/img_nocontract11.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>⑤&nbsp;帳票一覧からアップロードされた帳票の行をクリックします。帳票詳細画面が開きますので帳票イメージを確認しながら入力を行ってください。帳票種類、取引日付、取引先名称、取引金額は必須項目です。<br>
                入力後、「確定」ボタン（承認機能なしのお客様）や「登録」ボタン（承認機能ありのお客様）をクリックします。</p>
              <img src="/img/support/img_nocontract12.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>⑥&nbsp;「確定・登録」ボタンを押下すると、確認のメッセージが表示されるため、内容に間違いがなければ「はい」をクリックしてください。</p>
              <img src="/img/support/img_nocontract13.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>⑦&nbsp; 保管帳票一覧画面に戻ります。入力を行った帳票のステータスが以下のように入力・承認状態に応じて段階的に変化します。</p>
              <span class="d-flex">承認機能なしのお客様：
                <img src="/img/support/img_state01.png" alt="帳票保管ステータス" class="mb-3 ms-0" style="width: 20%; height: 20%;">
                <span style="font-size: 20px;">→</span>
                <img src="/img/support/img_state04.png" alt="帳票保管ステータス" class="mb-3 ms-0" style="width: 20%; height: 20%;">
              </span>
              <span class="d-flex">承認機能ありのお客様：
                <img src="/img/support/img_state01.png" alt="帳票保管ステータス" class="mb-3 ms-0" style="width: 20%; height: 20%;">
                <span style="font-size: 20px;">→</span>
                <img src="/img/support/img_state02.png" alt="帳票保管ステータス" class="mb-3 ms-0" style="width: 20%; height: 20%;">
                <span style="font-size: 20px;">→</span>
                <img src="/img/support/img_state03.png" alt="帳票保管ステータス" class="mb-3 ms-0" style="width: 20%; height: 20%;">
                <span style="font-size: 20px;">→</span>
                <img src="/img/support/img_state04.png" alt="帳票保管ステータス" class="mb-3 ms-0" style="width: 20%; height: 20%;">
              </span>
              <p>「確定済」表示で本登録状態（緑のステータスアイコン）になります。ここまでが帳票保管の基本的な操作方法です。</p>
              <img src="/img/support/img_nocontract14.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5 frame-img" @click="showLigthBox">
            </div>
              
          </article>

          <article id="EvidenceContract" class="mb-3">
            <h3>（代行入力オプションご契約のお客様）帳票保管の方法</h3>
            <div class="ti">
              <p>①&nbsp;保管帳票一覧画面に遷移し、新規アップロードボタンを押下します。</p>
              <img src="/img/support/img_contract01.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>②&nbsp;以下の新規帳票アップロードダイアログ画面が表示されますので、「ファイルを選択」ボタンを押下しアップロード対象ファイルを選択するか、このダイアログ内にアップロード対象ファイルのドラッグ＆ドロップを行ってください。<br><br>
                グループに所属するユーザーの場合は、所属するグループがアップロード先グループとして初期セットされています。グループへのアップロードを行わない場合は、グループ名をクリックすることでアップロード先から除外することが可能です。</p>
              <img src="/img/support/img_contract02.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div>
              <div class="subtitle">（1）&nbsp;モードに関して</div>
              <div class="subtext">帳票アップロード時には、3つのモードを切り替え可能です。お客様のご利用環境や、対象帳票に合わせて選択を行ってください。</div>
              <img src="/img/support/img_contract03.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <table class="mb-5 table">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white" width="15%">モード名称</th>
                  <th scope="col" class="text-center text-white" width="50%">画面内容</th>
                  <th scope="col" class="text-center text-white" width="35%">説明</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>自動判別</td>
                  <td><img src="/img/support/img_contract04.png" alt="帳票保管機能編 帳票保管の方法" class="state-img my-2" @click="showLigthBox"></td>
                  <td>アップロードされた帳票ファイルの内容から「電子取引」、「スキャナ保存」の自動判別を行います。<br>
                    <span class="fw-bold" style="color: red;">※通常はこのモードを利用していただき、自動判別が意図しない結果になり警告表示が行われてしまうなどの時には別のモードでアップロードしていただくことを推奨します。</span>
                  </td>
                </tr>
                <tr>
                  <td>電子取引としてアップロード</td>
                  <td><img src="/img/support/img_contract05.png" alt="帳票保管機能編 帳票保管の方法" class="state-img my-2" @click="showLigthBox"></td>
                  <td>「電子取引」データとして帳票アップロードを行う時に利用します。このモードでアップロードされた帳票ファイルは、電子取引データとして保管され、解像度や階調のチェックは行われず警告表示を行いません。</td>
                </tr>
                <tr>
                  <td>スキャナ保存としてアップロード</td>
                  <td><img src="/img/support/img_contract06.png" alt="帳票保管機能編 帳票保管の方法" class="state-img my-2" @click="showLigthBox"></td>
                  <td>「スキャナ保存」データとして帳票アップロードを行う時に利用します。このモードでアップロードされた帳票ファイルは解像度や階調のチェックが強制的に行われ、スキャナ保存要件を満たさない場合は、警告表示が行われます。</td>
                </tr>
              </tbody>
            </table>
            <div>
              <div class="subtext">「次回もこのモードを利用する」にチェックをつけることで、現在のアップロードモードを保存することができ、次回の新規アップロードボタン押下時に保存したモードでダイアログが表示されます。「閉じる」ボタン押下時にモードは保存されます。</div>
              <img src="/img/support/img_contract07.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div>
              <div class="subtitle">（2）&nbsp;アップロード先グループ設定に関して</div>
              <div class="subtext">グループに所属するユーザーの場合は、所属するグループがアップロード先グループとして初期セットされています。アップロード先グループを変更する場合は、「グループを編集する」ボタンをクリック後、グループ名を選択することでアップロード先グループの割り当て／除外が可能です。</div>
              <img src="/img/support/img_contract08.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div>
              <div class="subtitle">（3）&nbsp;マップコード設定に関して</div>
              <div class="subtext">マップコードを指定してアップロードを行うこともできます。マップコードを指定した場合、アップロードごとに同一のマップコードが帳票ファイルに設定されます。マップコード入力欄横の虫眼鏡アイコンをクリックすることで、マップコード検索ダイアログを表示し、マップコードを検索、および、設定することもできます。
                マップコードに関しては<a href="#NarrativeMap">[帳票保管機能編]の「ナラティブマップの活用」</a>ページをご参照ください。</div>
              <img src="/img/support/img_contract09.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>③&nbsp;緑のチェックマークがついたらファイルのアップロード完了です。「閉じる」ボタンを押下してください。</p>
              <img src="/img/support/img_contract10.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>④&nbsp;アップロードした帳票は「全データ表示モード」で確認でき、アップロードされた帳票のステータスは、「代行入力中」と表示されます。</p>
              <img src="/img/support/img_contract11.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>⑤&nbsp;弊社での入力が完了しましたら、ステータスが「承認待ち」に変わります。<br>
                「承認待ち」ステータスの帳票を選択し、内容に間違いがなければ承認をしていただきます。</p>
              <img src="/img/support/img_contract12.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>⑥&nbsp;お客様による承認が完了すると、帳票のステータスが「確定済」に変化し、本登録となります。</p>
              <img src="/img/support/img_contract13.png" alt="帳票保管機能編 帳票保管の方法" class="mb-5 frame-img" @click="showLigthBox">
            </div>
          </article>

          <article id="EvidenceSearch" class="mb-5">
            <h3>帳票の検索</h3>
            <div class="ti">
              <p>①&nbsp;画面左の「絞り込み検索」をクリックします。</p>
              <img src="/img/support/img_search01.png" alt="帳票保管機能編 帳票の検索" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>②&nbsp;抽出を行う帳票に当てはまる条件を入力し、「検索」ボタンを押下します。<br>
                下記の例では、取引日付が「2023/1/1～2023/7/1」かつ「本社」タグのついた帳票を検索しています。</p>
              <img src="/img/support/img_search02.png" alt="帳票保管機能編 帳票の検索" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <p class="mt-3">検索可能な項目は下記の表のとおりです。</p>
            <table class="table">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white" width="17%">項目名</th>
                  <th scope="col" class="text-center text-white" width="54%">説明</th>
                  <th scope="col" class="text-center text-white" width="29%">備考</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>取引日</td>
                  <td>帳票データに登録した取引日を条件に検索します。</td>
                  <td>取引日の範囲指定可能</td>
                </tr>
                <tr>
                  <td>取引金額</td>
                  <td>帳票データに登録した取引金額を条件に検索します。</td>
                  <td>金額の範囲指定可能</td>
                </tr>
                <tr>
                  <td>取引先名称</td>
                  <td>帳票データに登録した取引名称を条件に検索します。</td>
                  <td></td>
                </tr>
                <tr>
                  <td>マップコード</td>
                  <td>帳票データに登録したマップコードを条件に検索します。</td>
                  <td></td>
                </tr>
                <tr>
                  <td>件名・摘要／メモ</td>
                  <td>帳票データの件名／摘要欄、または、メモ欄に登録した内容を条件に検索します。</td>
                  <td>スペース区切りで複数条件指定可能<br>
                      また、「すべてを含む」と「いずれかを含む」のチェックを切り替えて、検索条件を変更することも可能
                  </td>
                </tr>
                <tr>
                  <td>タグ</td>
                  <td>帳票データに設定されている帳票タグを条件に検索します。</td>
                  <td>「すべてを含む」と「いずれかを含む」のチェックを切り替えて、検索条件を変更することも可能</td>
                </tr>
                <tr>
                  <td>グループ</td>
                  <td>帳票データに設定されているグループを条件に検索します。</td>
                  <td>自身が所属するグループのみ検索条件に指定可能</td>
                </tr>
                <tr>
                  <td>帳票種類</td>
                  <td>帳票データに登録した帳票定義マスターの帳票種類を条件に検索します。</td>
                  <td></td>
                </tr>
                <tr>
                  <td>確定状況</td>
                  <td>帳票データの確定状況（未確定・確定済・確定済＆未確定両方）を指定して検索します。</td>
                  <td></td>
                </tr>
                <tr>
                  <td>削除レコード</td>
                  <td>確定後帳票データの削除状態を条件に検索します。</td>
                  <td>除外しない：削除レコードも表示する<br>
                      除外する：削除レコードは表示しない
                  </td>
                </tr>
              </tbody>
            </table>
          </article>

          <article id="NarrativeMap">
            <h3>ナラティブマップの活用</h3>
            <p>マップコード入力欄に任意のコードや文字列などをご登録いただくことで、同一のマップコードが設定された他の帳票同士を紐づけ、つながりを考慮した検索を行うことが可能です。</p>
            <p>また、ナラティブマップとして同一マップコードの帳票同士をつなげることで可視化を行うことができます。並び替え・階層化・コメント付与などを行いながら、帳票一つ一つをつなげていくことで組織の「ナラティブストーリー」を創りあげてみてはいかがでしょうか？</p>
            <img src="/img/support/img_narrativemap01.png" alt="ナラティブマップの活用" class="mb-5" @click="showLigthBox">
            <div class="info d-flex align-items-center border border-info mt-2 mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 18 18">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
              <p class="mt-3">※ナラティブとは・・・<br>
                英語で「物語」を表すのは「ストーリー」が代表的ですが、作り込まれたシナリオに従い、始まりから結末まで物語が進んでいくのが「ストーリー」であるのに対して、<br>
                <span class="fw-bold" style="color: #010066;">語り手自身が主人公</span>となり<br>
                <span class="fw-bold" style="color: #010066;">現在進行形で自由に紡いでいく</span>物語が
                <span class="fw-bold" style="color: #EC008C; font-size: 20px;">『ナラティブ』</span> です。
                </p>
            </div>
            <h4 class="mt-5">マップコードを設定する</h4>
            <div class="row mb-3">
              <div class="ti">
                <p>①&nbsp;帳票詳細画面のマップコード入力欄に自由な文字列（最大50文字）を入力し、登録・変更を行います。</p>
                <img src="/img/support/img_mapsetting01.png" alt="マップコード設定" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti">
                <p>②&nbsp;マップコード入力欄横の虫眼鏡アイコンをクリックすることで、マップコード検索ダイアログを表示し、マップコードを検索することもできます。</p>
                <img src="/img/support/img_mapsetting02.png" alt="マップコード設定" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti">
                <p>③&nbsp;マップコードの一部を知っている場合は「マップコードから検索」、マップコードはわからないが取引情報（取引日、取引先名、取引金額）は把握している場合は「取引情報から検索」を検索条件に指定し、条件を入力することで一覧に候補が表示されます。</p>
                <span class="d-flex">
                <img src="/img/support/img_mapsetting03.png" alt="マップコード設定" class="mb-5 ms-0 frame-img" style="width: 45%;" @click="showLigthBox">
                <img src="/img/support/img_mapsetting04.png" alt="マップコード設定" class="mb-5 ms-5 frame-img" style="width: 45%;" @click="showLigthBox">
                </span>
              </div>
              <div class="ti">
                <p>④&nbsp;検索結果一覧からセットを行いたいマップコードの行をクリックすることで、マップコードがマップコード入力欄にセットされますので、変更を保存してください。</p>
                <img src="/img/support/img_mapsetting05.png" alt="マップコード設定" class="mb-5" @click="showLigthBox">
              </div>
              <div class="ti" mb-5>
                <p>⑤&nbsp;マップコードを指定して検索を行うことも可能です。検索条件に対象のコードを入力し、検索を行うことで、同一マップコードの帳票データを抽出することができます。</p>
                <img src="/img/support/img_mapsetting06.png" alt="マップコード設定" class="mb-5 frame-img" @click="showLigthBox">
              </div>
            </div>
            <h4 class="mt-5">ナラティブマップを表示する</h4>
            <div class="row mb-3">
              <div class="ti mb-5">
                <p>①&nbsp;保管帳票一覧画面のマップ列のナラティブマップアイコンをクリックするか、任意の帳票で帳票詳細画面のマップコード入力欄右のナラティブマップアイコンをクリックします。</p>
                <img src="/img/support/img_displaymap01.png" alt="マップコード表示" class="mb-3 frame-img" @click="showLigthBox">
                <img src="/img/support/img_displaymap02.png" alt="マップコード表示" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti mb-5">
                <p>②&nbsp;以下のようにナラティブマップを表示することができます。マップコードを設定した直後は、以下のように、マップコードの登録順に一直線になって表示されます。随時、次手順にある並べ替えなどを行ってください。</p>
                <img src="/img/support/img_displaymap03.png" alt="マップコード表示" class="mb-5 frame-img" @click="showLigthBox">
              </div>
            </div>
            <h4 class="mt-5">ナラティブマップを編集する</h4>
            <p>初期状態ではナラティブマップは一直線ですが、並べ替えや階層化を行うことができます。以下の手順に従い、ナラティブマップを見やすく編集を行ってください。</p>
            <div class="row mb-3">
              <div class="ti mb-5">
                <p>①&nbsp;並べ替えを行う帳票をドラッグ（マウス左クリックを継続）します。対象帳票に赤い枠が表示され移動できる状態になります。</p>
                <img src="/img/support/img_editmap01.png" alt="マップコードを編集" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti mb-5">
                <p>②&nbsp;マウスドラッグしたまま、移動したい帳票まで持っていきます。赤い▼のコネクターが出ているところでドロップ（マウス左クリックを離す）を行うと帳票を移動することができます。
                  ただし、赤い▼のコネクターが表示されない場所には移動できません。
                </p>
                <img src="/img/support/img_editmap02.png" alt="マップコードを編集" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti mb-5">
                <p>③&nbsp;帳票を移動することができました。</p>
                <img src="/img/support/img_editmap03.png" alt="マップコードを編集" class="mb-5 frame-img" @click="showLigthBox">
              </div>
            </div>
            <h4 class="mt-5">ナラティブマップ内の帳票にコメントを投稿する</h4>
            <p>ナラティブマップとして紐づけられた帳票一つ一つにコメントを投稿することができます。コメントを投稿するには以下の手順に従ってください。</p>
            <div class="ti mb-5">
              <p>①&nbsp;コメントの入力を行う帳票をクリックします。ナラティブマップ画面右側に詳細表示エリアが表示されます。</p>
              <img src="/img/support/img_mapcomment01.png" alt="マップコードコメント" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>②&nbsp;コメント入力欄にコメントを入力し、「送信」ボタンをクリックすることで、コメントを投稿することができます。</p>
              <img src="/img/support/img_mapcomment02.png" alt="マップコードコメント" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>③&nbsp;ナラティブマップの各帳票上にマウスをホバーさせることで入力したコメントを確認することもできます。</p>
              <img src="/img/support/img_mapcomment03.png" alt="マップコードコメント" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>④&nbsp;コメントの削除を行いたい場合は、詳細表示エリアのコメント一覧画面から削除を行うコメント行右にある縦三点リーダーより、「削除」をクリックしてください。</p>
              <img src="/img/support/img_mapcomment04.png" alt="マップコードコメント" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <h4 id="MapOtherFunction" class="mt-3">ナラティブマップのその他の機能</h4>
            
              <p>ナラティブマップとして構築した帳票の削除設定やマップコードの変更を行うことができます。<br><br>
                【ナラティブマップから帳票詳細画面への遷移】<br><br>
                ナラティブマップから、対象の帳票を選択し、詳細表示エリアを表示します。詳細表示エリアの「帳票を開く」をクリックすることで、帳票詳細画面に遷移することができます。
              </p>
              <div class="ti mb-5">
                <img src="/img/support/img_othermap01.png" alt="ナラティブマップのその他の機能" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <p id="MapRetentionPeriod">【ナラティブマップの保管期間設定】<br><br>
                ナラティブマップに追加した帳票の帳票保管期間は、帳票定義マスターで設定を行った各帳票種類毎の帳票保管年数とは独立して機能します。<br><br>
                基本的に「ナラティブマップ内の一部の帳票だけ消去される」は発生せず（※例外あり）、ナラティブマップの消去タイミングでナラティブマップ内のすべての帳票が一括消去されます。
              </p>
              <p>（各保管年数の関係とデータ消去のタイミング例）</p>
              <table class="table mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white" width="20%">ナラティブマップの保管年数</th>
                  <th scope="col" class="text-center text-white" width="20%">帳票定義マスターの保管年数</th>
                  <th scope="col" class="text-center text-white" width="60%">帳票消去のタイミング</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">無期限</td>
                  <td class="text-center">10年</td>
                  <td>消去されることはありません。</td>
                </tr>
                <tr>
                  <td class="text-center">30年</td>
                  <td class="text-center">10年</td>
                  <td>ナラティブマップに追加されている帳票の最大の取引日＋30年後に消去されます。</td>
                </tr>
                <tr>
                  <td class="text-center">5年</td>
                  <td class="text-center">10年</td>
                  <td style="color: red;">※帳票定義マスターの保管年数未満の値をナラティブマップの保管年数とすることはできません。</td>
                </tr>
                <tr>
                  <td class="text-center">10年→15年に変更</td>
                  <td class="text-center">10年</td>
                  <td>ナラティブマップに追加されている帳票の最大の取引日＋15年後に消去されます。</td>
                </tr>
                <tr>
                  <td class="text-center">10年→5年に変更</td>
                  <td class="text-center">10年</td>
                  <td style="color: red;">※帳票定義マスターの保管年数未満の値をナラティブマップの保管年数とすることはできません。</td>
                </tr>
                <tr>
                  <td class="text-center">10年</td>
                  <td class="text-center">10年→15年に変更</td>
                  <td>ナラティブマップに追加されている帳票の最大の取引日＋15年後に消去されます。<br>
                    ※帳票定義マスターでの保管年数更新時、確認メッセージが表示され、対象の帳票定義をナラティブマップに含む保管年数を一括で延長した保管年数で更新します。
                    一括更新を行わない場合は、手動でナラティブマップの保管年数を延長する必要がありますが、対応漏れ等があった場合、ナラティブマップの保管年数と帳票定義の保管年数に差異が発生するので、ナラティブマップの一部の帳票だけ消去されるといったことが発生する可能性があります。
                  </td>
                </tr>
                <tr>
                  <td class="text-center">10年</td>
                  <td class="text-center">10年→5年に変更</td>
                  <td>ナラティブマップに追加されている帳票の最大の取引日＋10年後に消去されます。</td>
                </tr>
              </tbody>
            </table>
            <p>ナラティブマップの保管年数を変更するには、ナラティブマップ内の任意の帳票を選択し、詳細表示エリアを開きます。
              その後、右上の鉛筆アイコンをクリックすることで保管年数を変更することができます。<br><br>
              ナラティブマップ内に含まれる帳票種類の保管年数より小さい保管年数に設定することはできません。
            </p>
            <p>例）保管年数10年の請求書がナラティブマップに含まれる場合、ナラティブマップの保管年数に「9年」を設定することはできない。「11年」は設定可能。</p>
            <div class="ti mb-5">
              <img src="/img/support/img_othermap02.png" alt="ナラティブマップのその他の機能" class="mb-5" @click="showLigthBox">
            </div>
            <p>【ナラティブマップへのコメント登録】<br><br>
              ナラティブマップ内の帳票１つ１つに対するコメントとは別に、ナラティブマップ自体にコメントを登録しておくこともできます。<br><br>
              ナラティブマップにコメントを登録するには、ナラティブマップ内の任意の帳票を選択し、詳細表示エリアを開きます。その後、右上の鉛筆アイコンをクリックすることでコメントを入力することができます。
              コメント入力後、「更新」ボタンをクリックして保存を完了してください。
            </p>
            <img src="/img/support/img_othermap03.png" alt="ナラティブマップのその他の機能" class="mb-5" @click="showLigthBox">
            <p>【マップコードの一括更新】<br><br>
              ナラティブマップに設定されているマップコードを一括で変更することが可能です。<br><br>
              マップコードを一括更新するには、ナラティブマップ内の任意の帳票を選択し、詳細表示エリアを開きます。
              その後、右上の鉛筆アイコン横の▼をクリックし、「マップコードを一括変更する」をクリックします。
              マップコードが編集可能になりますので、マップコードを変更後、「更新」ボタンをクリックし、確認ダイアログで「はい」を選択してください。
              ナラティブマップに含まれるすべての帳票のマップコードが更新されます。
            </p>
            <img src="/img/support/img_othermap04.png" alt="ナラティブマップのその他の機能" class="mb-5" @click="showLigthBox">
          </article>

          <article id="ScannerAlert">
            <h3>電子帳簿保存法のスキャナ保存要件に関わる警告に関して</h3>
            <p>ハヤブサバンドではスキャナ装置やスマートフォンなどのモバイル機器などで読み取りを行った帳票データを保存（このことを以降「スキャナ保存」といいます。）することができます。
              電子帳簿保存法では、スキャナ保存に関して複数の要件が定められており、その要件を満たした上で運用を行っていただく必要があります。<br><br>
              スキャナ保存に関する詳しい情報は国税庁WEBサイト（<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/tokusetsu/03.htm" target="_blank">https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/tokusetsu/03.htm</a>）をご参照ください。<br><br>
              ハヤブサバンドでは、アップロードされたファイルがスキャナ保存要件の中の『真実性の確保「一定水準以上の解像度及びカラー画像による読み取り」』要件を満たさない帳票に関しては警告メッセージを表示しています。<br><br>
              具体的にはスキャナ保存が行われ、ハヤブサバンドにアップロードされた帳票データが以下の要件を満たす必要があります。
            </p>
            <ul class="check">
              <li>解像度が200dpi相当以上であること</li>
              <li>赤色、緑色及び青色の階調がそれぞれ256階調(24ビットカラー)以上であること</li>
            </ul>
            <div class="ti">
              <p>ハヤブサバンドにアップロードされたファイルが、上記スキャナ保存要件を満たさない場合は、下図の警告メッセージを表示します。</p>
              <img src="/img/support/img_scanneralert01.png" alt="電子帳簿保存法のスキャナ保存要件に関わる警告に関して" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <p>上記警告メッセージが表示された場合は下表をご確認の上、対応をお願いします。</p>
            <table class="table mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col" class="text-center text-white" width="40%">状況</th>
                  <th scope="col" class="text-center text-white" width="10%"></th>
                  <th scope="col" class="text-center text-white" width="45%">対処</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="2">帳票は取引先から紙で受領し、お客様環境のスキャナによりスキャニングや写真撮影されたデータですか？</td>
                  <td class="text-center">はい</td>
                  <td>スキャナ保存要件を満たしていません。
                    アップロード担当者に再スキャニング・再撮影を行ってもらい差し替えアプロードを依頼するか、アップロード者から経理担当者などに紙の帳票を送付してもらい、スキャナ保存要件を満たす機器を用いて再スキャニングを行ってください。
                  </td>
                </tr>
                <tr>
                  <td class="text-center">いいえ</td>
                  <td><p>電子取引（取引先からメール受領した、クラウドサービスからダウンロードした）の場合はスキャナ保存要件は適用されません。</p>
                    <p class="fw-bold" style="color: red;">※アップロードモードが「自動判別」の場合、取引先が紙の請求書をスキャニングし、電子メールで送付してきた場合はスキャナ保存として判定されてしまいます。</p>
                    <p>対象データを差し戻しや差し替えを行い、アップロード時にアップロードモードを<span class="fw-bold" style="color: red;">「電子取引としてアップロード」</span>を選択し、再アップロードを行ってください。</p>
                    アップロードモードに関しては、<a href="#UploadMode">[帳票保管機能編]－[帳票保管の方法]の「②（1）モードに関して」</a>をご参照ください。
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">帳票ファイルはPCやスマートフォン画面のスクリーンショットといった画像ファイルですか？</td>
                  <td class="text-center">はい</td>
                  <td>
                    <p>取引画面のスクリーンショットをアップロードする場合は電子取引にあたります。</p>
                    <p class="fw-bold" style="color: red;">※アップロードモードが「自動判別」の場合、スクリーンショットなどの低解像度画像ファイルは「スキャナ保存」と判別され、警告表示対象となってしまいます。</p>
                    <p>対象データを差し戻しや差し替えを行い、アップロード時にアップロードモードを<span class="fw-bold" style="color: red;">「電子取引としてアップロード」</span>を選択し、再アップロードを行ってください。</p>
                    アップロードモードに関しては、<a href="#UploadMode">[帳票保管機能編]－[帳票保管の方法]の「②（1）モードに関して」</a>をご参照ください。
                  </td>
                </tr>
                <tr>
                  <td class="text-center">いいえ</td>
                  <td>スキャナ保存要件を満たしていません。アップロード担当者に再スキャニン・再撮影を行ってもらい差し替えアプロードを依頼するか、アップロード者から経理担当者などに紙の帳票を送付してもらい、スキャナ保存要件を満たす機器を用いて再スキャニングを行ってください。
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">帳票は重要書類（領収書、契約書、請求書、納品書等）ですか？</td>
                  <td class="text-center">はい</td>
                  <td>スキャナ保存要件を満たす必要があります。差し替えなどの対応をお願いします。</td>
                </tr>
                <tr>
                  <td class="text-center">いいえ</td>
                  <td>一般書類（見積書、注文書等）に関しては階調がグレースケールでの保存も認められています。
                    表示されている警告が「階調がスキャナ保存の要件を満たしていない」であれば「警告を非表示にする」ボタンを押下し保存可能です。
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">帳票は電帳法のスキャナ保存とは無関係の帳票ですか？</td>
                  <td class="text-center">はい</td>
                  <td>「警告を非表示にする」ボタンを押下可能です。
                    また、一貫してスキャナ保存要件を満たす必要がない帳票なのであれば、帳票定義マスターの設定により警告を非表示にすることができます。
                    詳しくは、<a href="#EvidenceSettings">[マスター管理編]－[帳票定義マスター保守]の「スキャナ保存要件に関する設定」</a>の部分をご参照ください。
                  </td>
                </tr>
                <tr>
                  <td class="text-center">いいえ</td>
                  <td>スキャナ保存要件を満たす必要があります。差し替えなどの対応をお願いします。
                  </td>
                </tr>
              </tbody>
            </table>
            <p>また、スキャナ保存情報は帳票詳細画面の下部でもご確認いただけます。</p>
            <div class="ti">
              <p>【PDFファイルの場合】</p>
              <img src="/img/support/img_scanneralert02.png" alt="電子帳簿保存法のスキャナ保存要件に関わる警告に関して" class="mb-5" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>【画像ファイルの場合】</p>
              <img src="/img/support/img_scanneralert03.png" alt="電子帳簿保存法のスキャナ保存要件に関わる警告に関して" class="mb-5" @click="showLigthBox">
            </div>
              <p>電子取引として受領した帳票がスキャナ保存帳票として判定されてしまった場合などで警告を非表示にするには、対象メッセージ左のチェックボックスにチェックを付けることで、「警告を非表示にする」ボタンがクリックできる状態に変化します。<br>
                その後、「警告を非表示にする」ボタンを押下してください。
              </p>
              <img src="/img/support/img_scanneralert04.png" alt="電子帳簿保存法のスキャナ保存要件に関わる警告に関して" class="mb-5 frame-img" @click="showLigthBox">
            <div class="info d-flex align-items-center border border-info mt-2 mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 18 18">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
              <span>
              <p class="my-3">「警告を非表示にする」ボタンを押下し、警告を非表示にできるのは以下の権限を保持するユーザーのみです。</p>
              <ul class="check">
                <li>帳票データ一次承認者</li>
                <li>帳票データ二次承認者</li>
                <li>帳票データ編集者</li>
                <li>帳票データ編集責任者</li>
              </ul>
            </span>
            </div>
          </article>

          <article id="Convenience">
            <h3>帳票保管の便利な機能</h3>
            <article>
              <h4>変更履歴の確認</h4>
              <div class="row mb-3">
                <p class="my-3">確定された帳票への訂正・削除は変更履歴で内容を確認することができます。<br>
                  変更履歴は、「確定後の帳票データに対して『誰が』、『いつ』、『どうした』」にフォーカスして記録する履歴となっています。</p>
                <div class="ti">
                  <p>①&nbsp;帳票詳細画面の「変更履歴」タブをクリックします。</p>
                  <img src="/img/support/img_changehis01.png" alt="帳票保管機能編 帳票の検索" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>②&nbsp;確定後帳票の訂正・削除の履歴を時系列で表示します。変更があった項目の変更前・変更後情報や、帳票の差し替えがあった場合は、差し替え前の帳票にアクセスすることも可能です。</p>
                  <img src="/img/support/img_changehis02.png" alt="帳票保管機能編 帳票の検索" class="mb-5 frame-img" @click="showLigthBox">
                </div>
              </div>
            </article>

            <article>
              <h4>承認履歴の確認</h4>
              <div class="row mb-3">
                <p class="my-3">承認機能をご利用のお客様は、帳票の承認状況を確認することができます。<br>
                  承認履歴は、「帳票の【アップロード】から【確定】に至るまでの承認ルートで『誰が』、『いつ』、『どうした』」にフォーカスして記録する履歴です。</p>
                <div class="ti">
                  <p>①&nbsp;帳票詳細画面の「承認履歴」タブをクリックします。</p>
                  <img src="/img/support/img_apphis01.png" alt="帳票保管機能編 帳票の検索" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>②&nbsp;帳票の承認履歴を時系列で表示します。</p>
                  <img src="/img/support/img_apphis02.png" alt="帳票保管機能編 帳票の検索" class="mb-5 frame-img" @click="showLigthBox">
                </div>
              </div>
            </article>

            <article>
              <h4>グループアクセス権の変更</h4>
              <div class="row mb-3">
                <p class="my-3">グループ機能をご利用のお客様は、帳票アップロード後にグループを変更することが可能です。</p>
                <div class="ti">
                  <p>①&nbsp;帳票詳細画面の「グループ」タブをクリックします。</p>
                  <img src="/img/support/img_groupacc01.png" alt="帳票保管機能編 グループアクセス権の変更" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>②&nbsp;現在帳票に設定されているグループが表示されます。帳票データへのアクセスを許可するグループを追加する場合は、「＋」をクリックします。
                    （※ユーザーが所属するグループのみ追加・削除が可能です。）</p>
                  <img src="/img/support/img_groupacc02.png" alt="帳票保管機能編 グループアクセス権の変更" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>③&nbsp;追加候補のグループが表示されるので、追加を行うグループをクリックします。</p>
                  <img src="/img/support/img_groupacc03.png" alt="帳票保管機能編 グループアクセス権の変更" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>④&nbsp;最後に「グループを変更する」を押下することでグループの変更が反映されます。</p>
                  <img src="/img/support/img_groupacc04.png" alt="帳票保管機能編 グループアクセス権の変更" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>⑤&nbsp;帳票データへのグループ変更履歴もグループタブで確認することができます。</p>
                  <img src="/img/support/img_groupacc05.png" alt="帳票保管機能編 グループアクセス権の変更" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>※&nbsp;帳票データからグループを除外する場合は対象のグループをクリック後、「グループを変更する」をクリックしてください。</p>
                  <img src="/img/support/img_groupacc06.png" alt="帳票保管機能編 グループアクセス権の変更" class="mb-5 frame-img" @click="showLigthBox">
                </div>
              </div>
            </article>
            <article id="Replacement">
              <h4>帳票ファイルの差替方法</h4>
              <div class="row mb-3">
                <p class="my-3">すでに確定されたファイルが誤っていた場合などに帳票ファイルの差替を行うことが可能です。</p>
                <div class="ti">
                  <p>①&nbsp;帳票詳細画面の「差替」ボタンをクリックします。</p>
                  <img src="/img/support/img_replacement01.png" alt="帳票保管機能編 帳票ファイル差替方法" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>②&nbsp;差替帳票アップロードダイアログが表示されるので、差替後の帳票ファイルを新規アップロードと同様の手順でアップロードを行ってください。</p>
                  <img src="/img/support/img_replacement02.png" alt="帳票保管機能編 帳票ファイル差替方法" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>③&nbsp;以下の画面が表示されます。差替前帳票と、差替後帳票を確認し、問題がなければ差替事由を入力後、「差替」ボタンをクリックしてください。</p>
                  <img src="/img/support/img_replacement03.png" alt="帳票保管機能編 帳票ファイル差替方法" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>④&nbsp;差替アップロード後はすべての入力項目を再度入力していただく必要があります。入力完了後、「登録」ボタンで確定を行ってください。</p>
                  <img src="/img/support/img_replacement04.png" alt="帳票保管機能編 帳票ファイル差替方法" class="mb-5 frame-img" @click="showLigthBox">
                </div>
                <div class="ti">
                  <p>⑤&nbsp;差替前の情報は変更履歴で確認可能です。差替前の帳票ファイル自体も保管年数保存されます。ファイル名をクリックすることで、帳票ファイルの内容を確認できます。</p>
                  <img src="/img/support/img_replacement05.png" alt="帳票保管機能編 帳票ファイル差替方法" class="mb-5" @click="showLigthBox">
                </div>
              </div>
            </article>
            <article id="Denchoho">
              <h4>電帳法区分の変更方法</h4>
              <div class="row mb-3">
                <p class="my-3">電帳法区分は帳票詳細画面より変更を行うことが可能です。現在の電帳法区分は帳票詳細画面の上部に表示されています。クリックを１度行うことで確認ダイアログを表示します。
                  「はい」をクリックすることで「電子取引→スキャナ保存」、「スキャナ保存→電子取引」に変更可能です。</p>
              </div>
              <div class="ti">
                <img src="/img/support/img_denchoho01.png" alt="帳票保管機能編 電帳法区分の変更方法" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="info d-flex align-items-center border border-info mt-2 mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 18 18">
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                <span>
                  <p class="my-3">電帳法区分の変更が行えるのは以下の権限を保持するユーザーのみです。</p>
                  <ul class="check">
                    <li>帳票データ一次承認者</li>
                    <li>帳票データ二次承認者</li>
                    <li>帳票データ編集者</li>
                    <li>帳票データ編集責任者</li>
                  </ul>
                </span>
              </div>
              <div>
                <p class="my-3">【電子取引→スキャナ保存への変更時】</p>
                <div class="subtitle mb-4">以下のダイアログに「はい」をクリックで電帳法区分が「電子取引」から「スキャナ保存」に変更されます。同時に、該当帳票ファイルが電帳法のスキャナ保存要件を満たしているかのチェックが行われ、要件を満たしていない場合は警告表示が行われます。
                  詳しくは<a href="#ScannerAlert">[帳票保管機能編]－「電子帳簿保存法のスキャナ保存要件に関わる警告に関して」</a>をご参照ください。</div>
              </div>
              <div class="ti">
                <img src="/img/support/img_denchoho02.png" alt="帳票保管機能編 電帳法区分の変更方法" class="mb-5 frame-img" @click="showLigthBox">
              </div>
                <p class="my-3">【スキャナ保存→電子取引への変更時】</p>
                <div class="subtitle mb-3">以下のダイアログに「はい」をクリックで電帳法区分が「スキャナ保存」から「電子取引」に変更されます。</div>
                <div class="ti">
                <div class="notice2 d-flex align-items-center border border-warning border-2 mb-5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                  <span>
                    <p class="my-3">&nbsp;&nbsp;&nbsp;&nbsp;電帳法区分を「スキャナ保存」から「電子取引」に変更を行うことで、帳票ファイルが電帳法のスキャナ保存要件を満たしていなかった場合の警告表示が行われなくなってしまいます。以下の状況に照らし合わせて、合致する場合のみ変更を行ってください。誤って電帳法区分を変更してしまった場合は、再度「電子取引」→「スキャナ保存」への変更を行ってください。</p>
                    <ul class="check">
                      <li>&nbsp;&nbsp;&nbsp;取引先から電子取引（メール送付やクラウドからダウンロード）で受領した帳票をアップロード時、モードを「自動判別」でアップロードを行ったところ、電帳法区分が「スキャナ保存」として設定された場合</li>
                      <li>&nbsp;&nbsp;&nbsp;取引先から電子取引（メール送付やクラウドからダウンロード）として受領した帳票をアップロード時、モードを誤って「スキャナ保存」としてアップロードしてしまった場合</li>
                    </ul>
                  </span>
                </div>
                <img src="/img/support/img_denchoho03.png" alt="帳票保管機能編 電帳法区分の変更方法" class="mb-5 frame-img" @click="showLigthBox">
              </div>
            </article>
          </article>

          <div id="Invoice">
            <h2>帳票閲覧機能編</h2>
          </div>
          <article>
            <p class="my-3">帳票閲覧機能とは、当社からお客様宛に発行された請求書・納品書をハヤブサバンド上で受領・閲覧することができる機能です。</p>
            <div class="notice3 d-flex align-items-center border border-danger border-2 mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="90" height="50" fill="currentColor" class="text-danger bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <p class="my-3">当社発行請求書・納品書の閲覧期限は、発行された日から<b><u>「2年間」</u></b>です。すみやかにハヤブサバンド帳票保管機能側に保管連携をしていただくか、帳票保管機能未契約のお客様は電帳法の要件に従い、お客様環境の保管場所（PCやストレージ）への保存をお願いします。</p>
            </div>
          </article>
          <article id="InvoiceUsage">
            <h3>帳票閲覧の方法</h3>
            <div class="row mb-3">
              <div class="ti">
                <p>①&nbsp;当社にて請求書発行が行われると、「 【株式会社本島ビジネスセンター】帳票保管・閲覧クラウド(hayabusaBanD)から請求書データ配信のご連絡」というタイトルのメールが届きます。メール内に記載されているURLをクリックしてください。</p>
                <img src="/img/support/img_invoice_usage01.png" alt="帳票閲覧機能編 帳票閲覧の方法" class="mb-5 frame-img" @click="showLigthBox">
                <div class="notice2 d-flex align-items-center border border-warning border-2 mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                  <p class="my-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;サインアップ後に、連絡先メールアドレスを変更されている場合、サインインに利用しているアカウントID(メールアドレス)宛にメールは届きませんのでご注意ください。</p>
                </div>
                <div class="notice2 d-flex align-items-center border border-warning border-2 mb-5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                  <p class="my-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;管理者メニューのユーザー権限保守画面で［閲覧サービス］の「請求書閲覧者」権限が付与されているユーザーに請求書発行メールが配信されます。</p>
                </div>
              </div>
              <div class="ti">
                <p>②&nbsp;サインイン画面に遷移するので、お客様のアカウントID、パスワードを入力してサインインを行ってください。</p>
                <img src="/img/support/img_invoice_usage02.png" alt="帳票閲覧機能編 帳票閲覧の方法" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti">
                <p>③&nbsp;サインイン後、「 【閲覧】受領請求書一覧」タブにアクセスし、閲覧対象発行日の行にある「開く」ボタンをクリックします。</p>
                <img src="/img/support/img_invoice_usage03.png" alt="帳票閲覧機能編 帳票閲覧の方法" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti">
                <p>④&nbsp;別タブでPDFが開き、当社発行請求書や納品書を確認することができます。
                  PDFファイルをダウンロードする場合は、ブラウザーのダウンロードボタンをクリックすることによりファイルとしてダウンロードを行うことが可能です。</p>
                <img src="/img/support/img_invoice_usage04.png" alt="帳票閲覧機能編 帳票閲覧の方法" class="mb-5 frame-img" @click="showLigthBox">
                <p>【EdgeブラウザーでPDFファイルをダウンロードする場合】</p>
                <img src="/img/support/img_invoice_usage05.png" alt="帳票閲覧機能編 帳票閲覧の方法" class="mb-5 frame-img" @click="showLigthBox">
                <p>【ChromeブラウザーでPDFファイルをダウンロードする場合】</p>
                <img src="/img/support/img_invoice_usage06.png" alt="帳票閲覧機能編 帳票閲覧の方法" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="notice3 d-flex align-items-center border border-danger border-2 mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="90" height="50" fill="currentColor" class="text-danger bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <p class="my-3">当社発行請求書・納品書の閲覧期限は、発行された日から<b><u>「2年間」</u></b>です。すみやかにハヤブサバンド帳票保管機能側に保管連携をしていただくか、帳票保管機能未契約のお客様は電帳法の要件に従い、お客様環境の保管場所（PCやストレージ）への保存をお願いします。</p>
            </div>
              <div class="ti">
                <p>⑤&nbsp;PDFを閉じる際は、タブの「×」をクリックしてください。</p>
                <img src="/img/support/img_invoice_usage07.png" alt="帳票閲覧機能編 帳票閲覧の方法" class="mb-5" @click="showLigthBox">
              </div>
            </div>
          </article>

          <article id="InvoiceLinkage">
            <h3>（保管機能契約済のお客様）当社発行請求書・納品書の帳票保管への保存方法</h3>
            <p class="my-3">当社発行請求書・納品書をハヤブサバンド帳票保管機能側へ保存することが可能です。</p>
            <div class="notice2 d-flex align-items-center border border-warning border-2 mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <p class="my-3">事前に管理者メニューの「取引先マスター保守」画面で、取引先名として「株式会社本島ビジネスセンター」のレコードを登録していただけると、帳票保管機能側の取引先名入力時に「マスター登録済」として扱われます。未登録でも帳票保管上は問題ありませんが、「マスター登録なし」となるのを回避する場合には上記レコードの登録をお願いいたします。</p>
            </div>
            <div class="row mb-3">
              <div class="ti">
                <p>①&nbsp; 「 【閲覧】受領請求書一覧」タブにアクセスし、保管連携を行う請求書・納品書が「未連携」であることを確認後、画面右側にある三点リーダーをクリックします。</p>
                <img src="/img/support/img_invoice_linkage01.png" alt="帳票閲覧機能編 保管連携" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti">
                <p>②&nbsp;「保管連携」ボタンが表示されるので、クリックします。</p>
                <img src="/img/support/img_invoice_linkage02.png" alt="帳票閲覧機能編 保管連携" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti">
                <p>③&nbsp;「保管連携」ダイアログ画面が表示されるので、【連携対象データ】の当社発行帳票種類に合致する「帳票定義マスターに設定を行った帳票種類」、「取引日として扱う日付」、「対象帳票へのアクセスを許可するグループ」を選択してください。
                  初回のみ「帳票種類」、「取引日として扱う日付」、「アクセス可能なグループ」の選択が必要です。これらの項目の選択完了後、「連携」ボタンをクリックします。</p>
                <img src="/img/support/img_invoice_linkage03.png" alt="帳票閲覧機能編 保管連携" class="mb-5" @click="showLigthBox">
                <div class="notice2 d-flex align-items-center border border-warning border-2 mb-5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="50" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                  <p class="my-3">&nbsp;&nbsp;&nbsp;&nbsp;【選択項目に帳票種類が存在しない場合】<br>管理者メニューの「帳票定義マスター保守」画面から帳票種類の新規追加を行ってください。<br>※現在当社が発行している帳票の種類は「請求書」 「納品書」と「請求集計表（※ご利用のお客様のみ）」の3帳票です。</p>
                </div>
              </div>
              <div class="ti">
                <p>④&nbsp;保管連携のアイコンが「連携済」になったことを確認してください。</p>
                <img src="/img/support/img_invoice_linkage04.png" alt="帳票閲覧機能編 保管連携" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <article>
                <p>保管連携を行った当社発行請求書・納品書は以下の表に従って帳票保管側で値の初期セットを行います。</p>
                <table class="table mb-5">
                  <thead>
                    <tr class="table-primary">
                      <th scope="col" class="text-center text-white">帳票閲覧側での種類</th>
                      <th scope="col" class="text-center text-white">帳票保管側に取り込まれる値</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan="3" class="text-center">請求書</td>
                      <td>帳票保管「帳票種類」に帳票定義マスターで定義した請求書に合致する値をセットします。</td>
                    </tr>
                    <tr>
                      <td>帳票保管「取引日」に、帳票閲覧「発行日」または「締日」（※選択可能）をセットします。</td>
                    </tr>
                    <tr>
                      <td>帳票保管「取引金額」に、帳票閲覧「請求金額」をセットします。</td>
                    </tr>
                    <tr>
                      <td rowspan="3" class="text-center">請求集計表<br>※ご利用のお客様のみ</td>
                      <td>帳票保管「帳票種類」に帳票定義マスターで定義した請求集計表に合致する値をセットします。</td>
                    </tr>
                    <tr>
                      <td>帳票保管「取引日」に、帳票閲覧「発行日」または「締日」（※選択可能）をセットします。</td>
                    </tr>
                    <tr>
                      <td>帳票保管「取引金額」に、帳票閲覧「請求金額」をセットします。</td>
                    </tr>
                    <tr>
                      <td rowspan="3" class="text-center">納品書</td>
                      <td>帳票保管「帳票種類」に帳票定義マスターで定義した納品書に合致する値をセットします。</td>
                    </tr>
                    <tr>
                      <td>帳票保管「取引日」に、帳票閲覧「発行日」または「締日」（※選択可能）をセットします。</td>
                    </tr>
                    <tr>
                      <td>帳票保管「取引金額」に、0（ゼロ）をセットします。</td>
                    </tr>
                  </tbody>
                </table>
                <div class="notice3 d-flex align-items-center border border-danger border-2 mb-5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="50" fill="currentColor" class="text-danger bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                  <p class="my-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当社発行請求書・納品書の閲覧期限は、発行された日から<b><u>「2年間」</u></b>です。すみやかにハヤブサバンド帳票保管機能側に保管連携をしていただくか、帳票保管機能未契約のお客様は電帳法の要件に従い、お客様環境の保管場所（PCやストレージ）への保存をお願いします。</p>
                </div>
              </article>
              <h4>誤った帳票種類、取引日として扱う日付、グループを選択してしまった場合</h4>
              <p>管理者メニューの「帳票種類変換マスター保守」画面にアクセスし、「帳票種類変換データ一覧」画面から対象のデータを選択し、正しい帳票種類や取引日として扱う日付、グループを選択後、「更新」ボタンをクリックします。</p>
              <img src="/img/support/img_invoice_linkage05.png" alt="帳票閲覧機能編 保管連携" class="mb-5" @click="showLigthBox">
              <div class="ti">
                <p>※すでに帳票保管機能側へ保管連携を行った帳票に関しては、お客様自身で訂正をしていただく必要があります。次回保管連携時より今回の変更が適用されます。</p>
              </div>
            </div>
          </article>

          <div id="Users">
            <h2>ユーザー設定に関して</h2>
          </div>
          <article id="UsersTag">
            <p class="my-3">ユーザー設定タブでは、ご自身のアカウント毎にパスワードやユーザータグの設定の変更を行うことができます。</p>
            <h3>ユーザーデフォルトタグ設定</h3>
            <div class="row mb-3">
              <p>ユーザーデフォルトタグは、帳票アップロード時に自動で付与するタグの設定で、ご利用ユーザーごとに設定できます。<br>
                帳票アップロード権限を保有しているユーザーが対象です。</p>
              <div class="mb-5 ti2">
                <p>※事前に帳票タグマスター保守で帳票タグを作成しておく必要があります。なお、自動で付与するタグが見つからない場合は、テナント管理者にご相談ください。<br>
                  タグの作成方法は、帳票タグマスター保守より行ってください。詳細は、「<a href="#Maintenance">マスター管理編</a>」－「<a href="#EvidenceTag">帳票タグマスター保守</a>」をご参照ください。</p>
              </div>
              <div class="ti">
                <p>①&nbsp;「ユーザー設定」タブを開き、「ユーザー情報」をクリックします。</p>
                <img src="/img/support/img_users_tag01.png" alt="ユーザー設定に関して ユーザーデフォルトタグ設定" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti">
                <p>②&nbsp;ユーザーデフォルトタグの項目に、タグが表示されています。自動で付与を行うタグのチェックボックスにチェックを入れ、「更新」ボタンをクリックします。</p>
                <img src="/img/support/img_users_tag02.png" alt="ユーザー設定に関して ユーザーデフォルトタグ設定" class="mb-5 frame-img" @click="showLigthBox">
              </div>
              <div class="ti">
                <p>③&nbsp;アップロードした帳票に自動でタグが付与されるようになります。</p>
                <img src="/img/support/img_users_tag03.png" alt="ユーザー設定に関して ユーザーデフォルトタグ設定" class="mb-5" @click="showLigthBox">
              </div>
            </div>
          </article>
          
          <article id="UsersPassword">
            <h3>パスワード変更</h3>
            <p>パスワードを変更したい際のパスワード変更手順をご説明します。</p>
            <div class="ti">
              <p>①&nbsp;「ユーザー設定」タブを開き、「パスワード変更」をクリックします。</p>
              <img src="/img/support/img_users_pw01.png" alt="ユーザー設定に関して パスワード変更" class="mb-5 frame-img" @click="showLigthBox">
            </div>
            <div class="ti">
              <p>②&nbsp;新しいパスワードを入力し、確認でもう一度同じパスワードを入力します。「変更」ボタンを押下すると設定が完了します。<br>
                念のため、サインアウトしていただいた後に、新しいパスワードでサインインできるかご確認ください。</p>
              <img src="/img/support/img_users_pw02.png" alt="ユーザー設定に関して パスワード変更" class="mb-2 frame-img" @click="showLigthBox">
            </div>
            <div class="att ms-5 ml-1 mb-5 ps-5">
              <p class="my-3">※パスワードは、以下を確認し入力してください。</p>
              <ul class="check mb-1">
                <li>10～100文字以内。</li>
                <li>「英小文字」「英大文字」「数字」を含んでいること。</li>
                <li>過去5世代前まで利用していたパスワードと一致しないこと。</li>
              </ul>
            </div>
          </article>
          <div id="Mobile">
            <h2>モバイル端末でハヤブサバンドを利用する</h2>
          </div>
          <article>
            <p>お客様がお持ちのスマートフォンやタブレットなどのモバイル端末からもハヤブサバンドをご利用可能です。
              カメラ機能を利用して領収書やレシート等を撮影後、ハヤブサバンドにアップロードを行うといった形でご利用ください。
            </p>
            <div class="notice2 d-flex align-items-center border border-warning border-2 mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <span>
              <p class="my-3">モバイル端末のカメラなどで撮影した画像を保管することは電帳法上の「スキャナ保存」に該当します。国税庁WEBサイト（<a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/tokusetsu/03.htm" target="_blank">https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/tokusetsu/03.htm</a>）などで、スキャナ保存の要件をご確認いただいた上でご利用ください。<br><br>
                また、スキャナ保存を行う場合は事務処理規程も必要になります。こちらも国税庁のWEBサイトにサンプルがございますので、事前にご準備いただいた上でスキャナ保存の運用を行ってください。
              </p>
              <ul class="check">
                <li><a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_a.docx" target="_blank">スキャナによる電子化保存規程(https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_a.docx)</a></li>
                <li><a href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_b.docx" target="_blank">国税関係書類に係る電子計算機処理に関する事務の手続を明らかにした書類(https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/word/0021006-031_b.docx)</a></li>
              </ul>
            </span>
            </div>
            <div class="info d-flex align-items-center border border-info mt-2 mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 18 18">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
              <span>
                <p class="my-3">モバイル端末でもPC版レイアウトでサイトを表示したい場合は以下をご参照に設定を行ってください。<br>
                  ※OSバージョンなどにより正常に切り替えができない可能性もございます。
                </p>
                <div class="d-flex mb-4">
                  <span class="me-3">
                    <p>【Androidのお客様】<br>
                    ブラウザーの設定から「PC版サイト」にチェックを入れる</p>
                    <img src="/img/support/img_mobile01.png" alt="モバイル端末でハヤブサバンドを利用する" class="ms-4 frame-img" style="width: 150px;" @click="showLigthBox">
                  </span>
                  <span class="ms-1">
                    <p>【iPhoneのお客様】<br>
                      「ぁあ」や「aA」をタップし、「デスクトップ用Webサイトを表示」をタップする。</p>
                    <img src="/img/support/img_mobile02.png" alt="モバイル端末でハヤブサバンドを利用する" class="ms-5 frame-img" style="width: 160px;" @click="showLigthBox">
                  </span>
                </div>
              </span>
            </div>
          </article>
          <article class="mb-5">
            <h4>モバイル端末利用時の制限事項</h4>
            <p>モバイル端末を利用してハヤブサバンドを利用する場合、以下の制限事項がございますのでご注意ください。</p>
            <ul>
              <li class="mb-2">
                <p>PDFファイルの帳票プレビューに関しては以下の制限があります。</p>
                <p>▶&nbsp;帳票保管機能の帳票アップロード時、および、帳票詳細画面でのみプレビュー可能</p>
                <p>▶&nbsp;先頭1ページのみプレビュー可能</p>
                ※帳票閲覧機能、ハヤブサバンドからのお知らせではPDFファイルのプレビュー表示は行えません。これらのPDFファイルに関しては一度端末内にダウンロードを行いPDFビューアーアプリなどを利用して参照していただくか、PCからハヤブサバンドにアクセスの上、ご参照ください。
              </li>
              <li class="mb-2">帳票差替アップロード時の、差替前のプレビュー表示には画像ファイル、PDFファイル共に対応していません。</li>
              <li class="mb-2">動作推奨ブラウザー（Android／Chrome・Edge、iOS／Safari）以外からアクセスされた場合はご利用いただけない可能性があります。</li>
              <li class="mb-2">動作推奨ブラウザーにおいても表示崩れや動作不能が発生する可能性があります。</li>
              <li class="mb-2">すべてのモバイル端末での動作を保証するものではありません。動作に問題がある場合はPCからご利用いただくことを推奨します。</li>
            </ul>
          </article>
          <article id="MobileAlert">
            <h4>モバイル端末利用時の警告表示に関して</h4>
            <p>モバイル端末からの帳票アップロード時、以下の警告を表示する可能性があります。</p>
            <div class="ti mb-5">
              <p>1）&nbsp;ファイルサイズ超過警告<br><br>
                モバイル端末で撮影した画像が、自身で設定したファイルサイズ上限値を超えている場合に警告を表示します。
                モバイルカメラの撮影モードなどが変更されたことにより、サイズの大きなファイルがアップロードされ、テナント内に保管されることでお客様の本サービスへのお支払額が増大することを防ぎます。
              </p>
              <img src="/img/support/img_mobilealert01.png" alt="モバイル端末利用時の警告表示に関して" class="mb-2 frame-img" style="width: 60%;" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>ファイルサイズ上限値は[ユーザー設定]－[ユーザー情報]に遷移し、「アップロード時警告閾値」を設定することで変更可能です。</p>
              <img src="/img/support/img_mobilealert02.png" alt="モバイル端末利用時の警告表示に関して" class="mb-2" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>2）&nbsp;画素数不足警告<br><br>
                モバイル端末で撮影した画像の画素数が、電子帳簿保存法のスキャナ保存としての要件の一つである画素数に満たない場合に表示されます。
                スキャナ保存の要件として画素数は、縦2,338画素×横1,654画素=3,867,052画素なので、約387万画素以上が必要であり、この値を満たさない場合に警告表示を行っています。
                カメラアプリのモードなどで総画素数を切り替えられる場合は、より高画質なモードに切り替えたうえで再度撮影を行い、アップロードをお願いします。
              </p>
              <img src="/img/support/img_mobilealert03.png" alt="モバイル端末利用時の警告表示に関して" class="mb-5 frame-img" style="width: 60%;" @click="showLigthBox">
              <div class="notice2 d-flex align-items-center border border-warning border-2 mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <p class="my-3">&nbsp;&nbsp;&nbsp;&nbsp;モバイル端末のカメラを使用して撮影、および、アップロード時、電帳法のスキャナ保存の要件としてチェックできるのは、画像の画素数のみです。24bitカラー以上かどうかの階調のチェックはアップロード完了後の帳票詳細画面（PCおよびモバイル端末どちらでも可）で警告表示されます。<br><br>
                  また、モバイル端末からPDFを選択した場合も、解像度・階調のチェックはアップロード前では行われませんので、帳票アップロード完了後、帳票詳細画面で警告表示されていないことをご確認の上、確定をお願いいたします。
                </p>
              </div>
              <div class="notice3 d-flex align-items-center border border-danger border-2 mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="currentColor" class="text-danger bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <p class="my-3">&nbsp;&nbsp;&nbsp;&nbsp;モバイル端末のカメラを使用して撮影、および、アップロード時、撮影する帳票が「A4サイズより大きい帳票」の場合は、警告が表示されなくても電帳法のスキャナ保存要件を満たしていない可能性があります。
                  対象のファイルがスキャナ保存要件を満たしているかどうかは、所轄の税務署、専門の税理士や法律顧問にご確認の上、ハヤブサバンド上に保存を行ってください。
                </p>
              </div>
            </div>
          </article>
          <article id="MobileUtilization">
            <h3>モバイル端末での帳票保管の利用方法</h3>
            <p>モバイル端末のカメラ機能を利用して撮影を行った帳票類を、ハヤブサバンドにアップロードする基本的な手順をご説明します。</p>
            <div class="ti mb-5">
              <p>①&nbsp;モバイル端末でハヤブサバンドサイト（<a href="https://hayabusaband.motojima-idc.com" target="_blank">https://hayabusaband.motojima-idc.com</a>）にアクセスするか、以下のQRコードをモバイル端末で読み取りを行います。ハヤブサバンドのサインイン画面を表示するのでお客様のアカウントID、パスワードを入力してサインインを行ってください。</p>
              <span class="d-flex">
              <img src="/img/support/img_mobileutilization01.png" alt="モバイル端末での帳票保管の利用方法" class="mb-4" style="width: 40%; height: 40%;" @click="showLigthBox">
              <img src="/img/support/img_mobileutilization02.png" alt="モバイル端末での帳票保管の利用方法" class="mb-4" style="width: 30%;" @click="showLigthBox">
              </span>
            </div>
            <div class="ti mb-5">
              <p>②&nbsp;保管帳票一覧画面を表示します。表示されない場合は、左下の「メニュー」をタップし、「【保管】保管帳票一覧」をタップしてください。</p>
              <img src="/img/support/img_mobileutilization03.png" alt="モバイル端末での帳票保管の利用方法" class="mb-2" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>③&nbsp;保管帳票一覧画面で「新規アップロード」をタップします。</p>
              <img src="/img/support/img_mobileutilization04.png" alt="モバイル端末での帳票保管の利用方法" class="mb-2  frame-img mobile-img" style="width: 30%;" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>④&nbsp;「新規帳票アップロード」画面を表示しますので、「カメラで撮影」をタップします。</p>
              <img src="/img/support/img_mobileutilization05.png" alt="モバイル端末での帳票保管の利用方法" class="mb-2" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>⑤&nbsp;モバイル端末のカメラアプリが起動します。領収書やレシートの撮影を行ってください。</p>
              <span class="d-flex">
                <img src="/img/support/img_mobileutilization06.png" alt="モバイル端末での帳票保管の利用方法" class="mb-2" style="width: 40%;" @click="showLigthBox">
                <div class="notice align-items-center border border-primary border-2 mb-5 ps-4">
                  <p class="p-3">【撮影時の注意点】<br>
                    モバイル端末のカメラ機能での撮影時は以下の点にご留意ください。
                  </p>
                  <ul class="mb-2" style="text-indent: 0;">
                    <li class="mb-2">387万画素以上で撮影を行う。</li>
                    <li class="mb-2">24bitカラー以上で撮影を行う。</li>
                    <li class="mb-2">カメラのフィルター効果などは利用しないこと。</li>
                    <li class="mb-2">背景は無地になるようにし、撮影対象以外のものが写り込まないようにする。</li>
                    <li class="mb-2">ズーム機能を使って撮影を行わないこと。</li>
                    <li class="mb-2">フラッシュや照明などのテカリが写り込まないように注意する。</li>
                    <li class="mb-2">ピンボケや手振れに注意する。</li>
                    <li class="mb-2">撮影対象が過度な台形にならないように極力被写体の上部より撮影を行う。</li>
                  </ul>
                </div>  
              </span>
            </div>
            <div class="ti mb-5">
              <p>⑥&nbsp;撮影が終わるとアップロードファイル確認画面になります。さらに帳票を追加で撮影する場合は下部にある「カメラで撮影」で撮影を行ったり、「ファイルを選択」で対象ファイルを選択してください。</p>
              <img src="/img/support/img_mobileutilization07.png" alt="モバイル端末での帳票保管の利用方法" class="mb-2" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>⑦&nbsp;対象のファイルをタップすることでアップロード前にプレビュー表示が可能です。必ず帳票が正しく撮影できているか確認を行ってください。</p>
              <img src="/img/support/img_mobileutilization08.png" alt="モバイル端末での帳票保管の利用方法" class="mb-2" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>⑧&nbsp;確認OKであれば、「送信」をタップします。[アップロード件数の確認]ダイアログが表示されますので、アップロード件数が正しければ「はい」をタップしてください。</p>
              <img src="/img/support/img_mobileutilization09.png" alt="モバイル端末での帳票保管の利用方法" class="mb-2" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>⑨&nbsp;アップロードが完了すると緑のチェックマークになります。</p>
              <img src="/img/support/img_mobileutilization10.png" alt="モバイル端末での帳票保管の利用方法" class="mb-2 frame-img mobile-img" style="width: 30%;" @click="showLigthBox">
            </div>
            <div class="ti mb-5">
              <p>⑩&nbsp;保管帳票一覧画面に戻るとアップロードを行った帳票データが表示され、入力・承認や検索など、帳票保管機能の一通りの操作を行うことができます。</p>
              <img src="/img/support/img_mobileutilization11.png" alt="モバイル端末での帳票保管の利用方法" class="mb-2 frame-img mobile-img" style="width: 30%;" @click="showLigthBox">
            </div>
            <div class="notice2 d-flex align-items-center border border-warning border-2 mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <p class="my-3">モバイル端末のカメラを使用して撮影、および、アップロード時、電帳法のスキャナ保存の要件としてチェックできるのは、画像の画素数のみです。24bitカラー以上かどうかの階調のチェックはアップロード完了後の帳票詳細画面（PCおよびモバイル端末どちらでも可）で警告表示されます。<br><br>
                また、モバイル端末からPDFを選択した場合も、解像度・階調のチェックはアップロード前では行われませんので、帳票アップロード完了後、帳票詳細画面で警告表示されていないことをご確認の上、確定をお願いいたします。
              </p>
            </div>
            <div class="info d-flex align-items-center border border-info mt-2 mb-5">
              <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 18 18">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
              </svg>
              <p class="my-3">モバイル端末のカメラを使用して撮影した画像データは、ハヤブサバンド上にアップロードを行わなかった場合は破棄されます。<span style="border-bottom: double 4px red; color: red">（モバイル端末のストレージ内に保存はされません。）</span>
                従いまして、対象帳票のアップロードを行う必要性がある場合は再度撮影を行っていただく必要があることにご注意ください。<br>
                ただし、この仕様は現時点での端末OSまたはブラウザーの最新版の仕様です。OSアップデートやブラウザーの仕様変更・AndroidかiOSか・バージョンの違い等などの要因で<span style="color: red">画像が端末内に保存される可能性がある</span>ことをご承知いただいた上で、ご利用いただくようお願いいたします。
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
    <!-- main contents -->

    <!-- LightBox -->
    <vue-easy-lightbox
      :visible="isShowingLightBox"
      :imgs="lightBoxImages"
      @hide="isShowingLightBox = false"
    />
  </div>
</template>

<style scoped>

h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
  position: relative;
  margin-bottom: 20px;
  padding: 0.5rem 0;
  border-bottom: 5px solid;
}

h2:before {
  position: absolute;
  bottom: -0.6rem;
  left: 0rem;
  right: 0rem;
  border-bottom: 1px solid;
  content: '';
}

article {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

article h3 {
  position: relative;
  padding-top: 0.6rem;
  padding-bottom: 0.28rem;
  margin: 10px auto 20px;
  font-weight: bold;
  font-size: 16pt;
  border-bottom: 5px solid skyblue;
}

article h3:after {
  position: absolute;
  border-bottom: 5px solid #1e72b1;
  content: " ";
  display: block;
  bottom: -4px;
  width: 15%;
}

h4 {
  margin-bottom: 4px;
  color: var(--color-navy);
  font-size: 14pt;
  position: relative;
  padding: 0.6rem 1rem;
  margin-bottom: 0.2rem;
  margin: 0px 0px 20px;
  overflow: hidden;
  font-weight: bold;
  font-size: 16pt;
  display: inline-block;
  border-top: solid 2px #5989cf;
  border-bottom: solid 2px #5989cf;
  width: auto;
}

h5 {
  margin-bottom: 10px;
  color: var(--color-navy);
  font-size: 14pt;
}

/** 帳票保管の流れ（アップロード） */
.subtitle {
  margin-left: 1.5rem;
}
.subtext {
  margin: 1rem 0 1rem 3rem;
}

article svg {
  margin: 12px 20px;
}

.notice {
  padding:0 20px;
  margin-left: 5%;
  width: 85%;
  border-radius: 10px;
  background-color: #f1f8ff;
}

.notice2{
  padding: 0 20px;
  margin: 0 5em 0 0;
  width: 85%;
  border-radius: 20px;
  background-color: rgb(255,227,157);
}

.notice3{
  color: #ff0000;
  padding: 0 20px;
  margin: 0 5em 0 0;
  width: 85%;
  border-radius: 20px;
  background-color: rgb(255, 232, 236);
}

.info{
  padding: 0 20px;
  margin: 0 5em 0 0;
  width: 85%;
  border-radius: 20px;
  background-color: #f3fafe;
}
.info svg{
  color: #87cefa;
  margin: 12px 20px;
}

.ti {
  text-indent: -1.3em;
  margin-left: 1.5em;
}
.ti2 {
  text-indent: -1.0em;
  margin-left: 1.0em;
}
li.list-group-item {
  text-indent: -1.3em;
  margin-left: 1.5em;
  border: none;
}
li.list-group-item ul {
  padding-left: 0em
}
li.list-group-item ul li {
  list-style: disc;
  padding-left: 0em;

  text-indent: -0.3em;
  margin-left: 1.5em;
}
ul.list-main {
  list-style-type: none;
  padding: 0;
}
ul.list-main li {
  position: relative;
  padding: 0 0 0 20px;
  margin: 7px 0 7px 0px;
  line-height: 28px;
  margin-left: 1em;
	text-indent: -1.4em;
  border-bottom: dashed 1px #5989cf;
}
ul.list-main li:last-child {
  border: none;
 }
ul.list-main span {
  color: red;
}
/** チェックマーク */
ul.check {
  list-style-type: none;
}
ul.check li {
  position: relative;
  line-height: 1.8;
}
ul.check li::after {
  content: '';
  display: block;
  position: absolute;
  top: .5em;
  left: -1.5em;
  width: 12px;
  height: 6px;
  border-left: 3px solid #000;
  border-bottom: 3px solid #000;
  transform: rotate(-45deg);
}
table {
  margin: 0 10px;
  border: 1px solid rgb(68,114,196);
}
table th {
  background-color: rgb(68,114,196);
  border-right: #fff solid 1px;
}
table td {
  border: 1px solid rgb(68,114,196);
}
table th:last-child {
  border-right: none;
}
table tr:nth-child(2n+1) {
  background-color: rgb(217,226,243);
}

table.num {
	counter-reset: rowCount;
}
table.num > tbody > tr {
	counter-increment: rowCount;
}
table.num > tbody > tr > td:first-child::before {
  content: counter(rowCount);
}
table.num td {
  border-right: 1px solid rgb(68,114,196);
}
.border-double {
  border-bottom: 3px double #666;
}

img {
  width: 70%;
  margin-left: 10%;
}

/** ステータス表示画像 */
.state-img {
  width: 90%;
  margin-left: 5%
}

/** 画像枠 */
.frame-img {
  width: 70%;
  margin-left: 10%;
  border: 1px solid #666;
}

.p-img {
  width: 3%;
  margin: 0;
}

/** 縦長画像配置 */
.mobile-img {
  margin-left: 30%;
}

.att {
  width: 80%;
}

.overflow-y-auto {
  /** bootstrap5.3へ更新前の暫定対応 */
  overflow-y: auto;
  overflow-x: hidden;
}

a.nav-link{
  padding: 2px;
}
</style>

<script>
import { ref } from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'SpManual',
  components: {
    VueEasyLightbox
  },
  setup() {
    /** LightBox表示フラグ */
    const isShowingLightBox = ref(false);

    /** LightBoxで表示する画像のURL文字列、またはURLの配列 */
    const lightBoxImages = ref([]);

    /** LightBoxを表示する処理 */
    const showLigthBox = (event) => {
      // クリックされた画像のソース属性を、対象画像としてセットする
      lightBoxImages.value = event.currentTarget.src;
      
      // LightBoxを表示する
      isShowingLightBox.value = true;
    }

    return {
      isShowingLightBox,
      lightBoxImages,
      showLigthBox
    }
  }
}
</script>