import { computed, reactive } from 'vue';
import backend from '@/services/backendApi';
import util from '@/services/utilities';
import store from '@/store/index';

/**
 * 帳票タグ関連
 */
const useEvidenceTag = () => {
    const evidenceTag = reactive({
        data: [],               // タグデータ配列(生データ)
        length: computed(() => evidenceTag.data.length),
        status: {
            get: {                          // 取得状況
                loaded: false,              // 取得済か否か
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            add: {                          // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            update: {                       // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            delete: {                       // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
        }
    });

    /** 新規追加用オブジェクト */
    const evidenceTagObject = reactive({
        id: 0,          // ID
        name: "",       // 名称
        backColor: "",  // 背景色
        foreColor: "",  // 文字色
    });

    /**
     * タグマスター取得
     */
    const getEvidenceTag = async (tenantCode = null) => {
        // メッセージ初期化、処理中フラグON
        evidenceTag.status.get.message = "";
        evidenceTag.status.get.inprogress = true;

        // データクリア
        clearEvidenceTagList();

        // テナントコード指定がない場合は自身のテナントを指定する
        if (tenantCode == null) {
            tenantCode = store.getters.getOwnInfo.tenantCode;
        }

        // キャッシュ取得
        const cache = store.getters.getCache.evidenceTag;

        if (cache?.length > 0 && cache[0].tenantCode === tenantCode) {
            // 取得成功
            evidenceTag.status.get.success = true;
            evidenceTag.data = cache;
            // 読み込み済みに変更
            evidenceTag.status.get.loaded = true;
            // 処理中フラグOFF
            evidenceTag.status.get.inprogress = false;
            return;
        }

        await backend.getEvidenceTags(tenantCode)
            .then(response => {
                // 取得成功
                evidenceTag.status.get.success = true;
                // タグデータがある場合はタグデータ配列にセット
                if (response.status !== 204) {
                    // グループコード、IDの降順にソート
                    evidenceTag.data = util.objectMultiSort(response.data.contents, ["id"], 1);
                    // キャッシュ保存
                    store.dispatch('saveCache', {evidenceTag: evidenceTag.data});
                }
            }).catch(error => {
                console.error(error);
                // 取得失敗
                evidenceTag.status.get.success = false;
                evidenceTag.status.get.message = error.message;
            }).finally(() => {
                // 読み込み済みに変更
                evidenceTag.status.get.loaded = true;
                // 処理中フラグOFF
                evidenceTag.status.get.inprogress = false;
            });
    }

    /**
     * 新規タグマスター追加処理
     * @param {Object} evidenceTagObject タグマスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const addEvidenceTag = async (evidenceTagObject, tenantCode = null) => {
        // メッセージ初期化・処理フラグON
        evidenceTag.status.add.message = "";
        evidenceTag.status.add.inprogress = true;

        return await backend.addEvidenceTag(evidenceTagObject, tenantCode)
            .then(response => {
                // 処理成功
                evidenceTag.status.add.success = true;
                evidenceTag.status.add.message = response.data.message;
                
                // キャッシュクリア
                store.dispatch('clearCache', "evidenceTag");
            }).catch(error => {
                console.error(error);
                // 処理失敗
                evidenceTag.status.add.success = false;
                evidenceTag.status.add.message = error.message;
            }).finally(() => {
                // 処理終了
                evidenceTag.status.add.inprogress = false;
            });
    }

    /**
     * タグマスター更新処理
     * @param {Object} evidenceTagObject タグマスターオブジェクト
     * @param {String} tenantCode テナントコード
     * @returns {Promise} Promiseオブジェクト
     */
    const updateEvidenceTag = async (evidenceTagObject, tenantCode = null) => {
        // メッセージ初期化・処理フラグON
        evidenceTag.status.update.message = "";
        evidenceTag.status.update.inprogress = true;

        return await backend.updateEvidenceTag(evidenceTagObject, tenantCode)
            .then(response => {
                // 処理成功
                evidenceTag.status.update.success = true;
                evidenceTag.status.update.message = response.data.message;
                
                // キャッシュクリア
                store.dispatch('clearCache', "evidenceTag");
            }).catch(error => {
                console.error(error);
                // 処理失敗
                evidenceTag.status.update.success = false;
                evidenceTag.status.update.message = error.message;
            }).finally(() => {
                // 処理終了
                evidenceTag.status.update.inprogress = false;
            });
    }

    /**
     * タグマスター削除処理
     * @param {String} evidenceTagCode タグコード
     * @param {String} tenantCode テナントコード
     * @returns {Promise} Promiseオブジェクト
     */
    const deleteEvidenceTag = async (evidenceTagCode, tenantCode = null) => {
        // メッセージ初期化・処理フラグON
        evidenceTag.status.delete.message = "";
        evidenceTag.status.delete.inprogress = true;

        return await backend.deleteEvidenceTag(evidenceTagCode, tenantCode)
            .then(response => {
                // 処理成功
                evidenceTag.status.delete.success = true;
                evidenceTag.status.delete.message = response.data.message;
                
                // キャッシュクリア
                store.dispatch('clearCache', "evidenceTag");
            }).catch(error => {
                console.error(error);
                // 処理失敗
                evidenceTag.status.delete.success = false;
                evidenceTag.status.delete.message = error.message;
            }).finally(() => {
                // 処理終了
                evidenceTag.status.delete.inprogress = false;
            });
    };

    /**
     * タグデータ配列クリア処理
     */
    const clearEvidenceTagList = async () => {
        evidenceTag.data = [];
        evidenceTag.status.get.loaded = false;
        evidenceTag.status.get.success = false;
    }

    return {
        evidenceTag,
        evidenceTagObject,          // タグオブジェクト
        getEvidenceTag,             // タグ取得処理
        addEvidenceTag,             // タグ追加処理
        updateEvidenceTag,          // タグ更新処理
        deleteEvidenceTag,          // タグ削除処理
        clearEvidenceTagList,       // タグデータ配列クリア処理
    };
};

export default useEvidenceTag;