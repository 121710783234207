import { reactive } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';
import backend from '@/services/backendApi';

const useSupportRequest = () => {
    // reCAPTCHA
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    // サポートリクエスト送信用オブジェクト
    const supportRequestContent = reactive({
        data: {                     // ユーザデータ
            accountId: '',          // ID
            name: '',               // 氏名漢字
            customerCode: '',       // お客様番号
            type: '',               // 問い合わせ区分
            feature: '',            // 機能区分
            subject: '',            // 件名
            message: '',            // 問い合わせ内容
            recaptchaToken: '',     // reCAPTCHAトークン
            hasAttachment: false,   // 添付ファイル有無
        },
        isAgreement: false,         // 利用開始同意の状態
        isConfirmed: false,         // 入力内容確認の状態
    });

    // 状態管理用オブジェクト
    const status = reactive({
        inprogress: false,          // 処理状態
        success: false,             // 処理結果
        message: '',                // メッセージ
    });

    /** サポートリクエスト送信処理 */
    const sendSupportRequest = async (hasAttachment = false) => {
        // 処理中状態ON
        status.inprogress = true;

        // reCAPTCHAトークン取得
        await recaptchaLoaded();
        supportRequestContent.data.recaptchaToken = await executeRecaptcha('support_request');

        // 添付ファイルの有無をセットする
        supportRequestContent.data.hasAttachment = hasAttachment;

        // API呼び出し
        return await backend.sendSupportRequest(supportRequestContent.data)
            .then((response) => {
                // 成功
                status.success = true;

                return Promise.resolve(response.data.message);
            }).catch(error => {
                // エラーメッセージをセット
                switch (error.message) {
                    case "お客様情報のチェックでエラーが発生しました。":
                        status.message = "<b>入力内容の確認でエラーが発生しました。次の点を確認してください。</b><br>"
                                       + "・お客様番号が正しいこと<br>・ユーザー登録時認証コードが正しいこと<br>・ユーザー登録時認証コードの有効期限を過ぎていないこと<br>・登録可能なユーザー数制限を超えていないこと";
                        break;
                    default:
                        status.message = error.message;
                        break;
                }

                return Promise.reject(error.message);
            }).finally(() => {
                // 処理中状態OFF
                status.inprogress = false;
            });
    }

    /**
     * 帳票アップロード処理
     * @param {String} requestDateTime サポートリクエスト送信日時
     * @param {FileList} file ファイル
     * @returns {Promise} Promiseオブジェクト
     */
    const uploadAttachmentFile = async (requestDateTime, file) => {
        return await backend.uploadAttachmentFile(requestDateTime, file)
            .then(response => {
                return Promise.resolve(response.data.message);
            })
            .catch(error => {
                return Promise.reject(error.message);
            });
    }

    return {
        supportRequestContent,
        status,
        sendSupportRequest,
        uploadAttachmentFile,
    };
};

export default useSupportRequest;