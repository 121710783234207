import { computed, reactive } from 'vue';
import backend from '@/services/backendApi';
import { useStore } from "vuex";
import util from '@/services/utilities';

/**
 * お知らせデータ関連
 */
const useNoticeList = () => {

    // vuex::store
    const store = useStore();

    const notice = reactive({
        list: [],        // お知らせデータ配列
        length: computed(() => notice.list.length),
        status: {
            get: {                          // 取得状況
                loaded: false,              // 取得済か否か
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            add: {                          // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            update: {                       // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            delete: {                       // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            approve: {                      // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
        },
    });

    /** 新規追加用オブジェクト */
    const noticeObject = reactive({
        title: '',              // タイトル
        contents: '',           // 内容
        indication: 1,          // 表示方法
        notification: 0,        // 通知オプション
        startDateTime: '',      // 公開開始日時
        endDateTime: '',        // 公開終了日時
        visibility: 0,          // 公開状態
        approval: 0,            // 承認状態
        importance: 0,          // 重要度
        displayArea: 1,         // 表示場所
        state: 0,               // ステータス
        destinations: [],       // 配信先配列
        startDate: '',          // 公開開始日(tmp)
        startTime: '',          // 公開開始時刻(tmp)
        endDate: '',            // 公開終了日(tmp)
        endTime: '',            // 公開終了時刻(tmp)
    });

    /**
     * お知らせ一覧取得
     * @param {int} displayArea 表示場所(サインイン画面=0, ダッシュボード=1)（初期値：-1=全て）
     * @param {string} creationDate お知らせ作成日（任意）
     * @param {int} history 発行履歴番号（初期値：0）
     * @param {bool} excludesDeleted 削除レコード除外有無（初期値：True）
     * @param {bool} maintenanceMode メンテナンスモード（true：条件無しで全件取得、false：公開開始終了日付、公開状態の条件を使用する）
     */
    const getNoticeData = async (displayArea = -1, creationDate, history = 0, excludesDeleted = true, maintenanceMode = false) => {
        // メッセージ初期化、処理中フラグON
        notice.status.get.message = "";
        notice.status.get.inprogress = true;

        await backend.getNoticeData(creationDate, history, excludesDeleted, maintenanceMode)
            .then(response => {
                // お知らせデータがある場合はお知らせデータ配列に追加
                if (response.status !== 204) {
                    const result = response.data;

                    if (maintenanceMode) {
                        // 登録年月日、登録履歴番号の降順になるようにソートして追加
                        notice.list = util.objectMultiSort(result.contents, ["creationDate", "history"], -1);
                    }
                    else {
                        // 配信開始日、登録年月日、登録履歴番号の降順になるようにソートして追加
                        notice.list = util.objectMultiSort(result.contents, ["startDateTime", "creationDate", "history"], -1);
                    }

                    // 表示場所が指定されている場合はフィルタリング
                    if (displayArea !== -1) {
                        notice.list = notice.list.filter(item => item.area === displayArea);
                    }

                    // 取得成功
                    notice.status.get.success = true;
                }
            }).catch(error => {
                console.error(error);
                // 取得失敗
                notice.status.get.success = false;
                notice.status.get.message = error.message;
            }).finally(() => {
                // 読み込み済みに変更
                notice.status.get.loaded = true;
                // 処理中フラグOFF
                notice.status.get.inprogress = false;
            });
    }

    /**
     * 新規お知らせ配信データ追加処理
     * @param {object} noticeObject お知らせデータオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const addNoticeData = async (noticeObject) => {
        // メッセージ初期化・処理フラグON
        notice.status.add.message = "";
        notice.status.add.inprogress = true;

        return await backend.addNoticeData(noticeObject)
            .then(response => {
                // 処理成功
                notice.status.add.success = true;
                notice.status.add.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                notice.status.add.success = false;
                notice.status.add.message = error.message;
            }).finally(() => {
                // 処理終了
                notice.status.add.inprogress = false;
            });
    }

    /**
     * お知らせ配信データ更新処理
     * @param {object} noticeObject お知らせデータオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const updateNoticeData = async (noticeObject) => {
        // メッセージ初期化・処理フラグON
        notice.status.update.message = "";
        notice.status.update.inprogress = true;

        return await backend.updateNotice(noticeObject)
            .then(response => {
                // 処理成功
                notice.status.update.success = true;
                notice.status.update.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                notice.status.update.success = false;
                notice.status.update.message = error.message;
            }).finally(() => {
                // 処理終了
                notice.status.update.inprogress = false;
            });
    }

    /**
     * お知らせ配信データ削除処理
     * @param {String} creationDate 登録年月日
     * @param {Number} history 登録履歴番号
     * @returns {Promise} Promiseオブジェクト
     */
    const deleteNoticeData = async (creationDate,history) => {
        // メッセージ初期化・処理フラグON
        notice.status.delete.message = "";
        notice.status.delete.inprogress = true;

        return await backend.deleteNoticeData(creationDate,history)
            .then(response => {
                // 処理成功
                notice.status.delete.success = true;
                notice.status.delete.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                notice.status.delete.success = false;
                notice.status.delete.message = error.message;
            }).finally(() => {
                // 処理終了
                notice.status.delete.inprogress = false;
            });
    }

    /**
     * お知らせ配信データ承認処理
     * @param {String} creationDate 登録年月日
     * @param {Number} history 登録履歴番号
     * @returns {Promise} Promiseオブジェクト
     */
     const approveNoticeData = async (creationDate,history) => {
        // メッセージ初期化・処理フラグON
        notice.status.approve.message = "";
        notice.status.approve.inprogress = true;

        return await backend.approveNoticeData(creationDate,history)
            .then(response => {
                // 処理成功
                notice.status.approve.success = true;
                notice.status.approve.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                notice.status.approve.success = false;
                notice.status.approve.message = error.message;
            }).finally(() => {
                // 処理終了
                notice.status.approve.inprogress = false;
            });
    }

    /**
     * お知らせ既読更新処理
     * @param {String} accountId アカウントID
     * @param {String} creationDate 登録年月日
     * @param {Number} history 登録履歴番号
     * @returns {Promise} Promiseオブジェクト
     */
     const updateReadState = async (accountId,creationDate,history) => {
        // メッセージ初期化・処理フラグON
        notice.status.update.message = "";
        notice.status.update.inprogress = true;

        return await backend.updateReadState(accountId,creationDate,history)
            .then(response => {
                // 処理成功
                notice.status.update.success = true;
                notice.status.update.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                notice.status.update.success = false;
                notice.status.update.message = error.message;
            }).finally(() => {
                // 処理終了
                notice.status.update.inprogress = false;
            });
    }

    /**
     * お知らせ配信データ配列クリア処理
     */
    const clearNoticeList = async () => {
        notice.data = [];
        notice.list = [];
        notice.status.get.loaded = false;
        notice.status.get.success = false;
    }

    /**
     * お知らせ取得（サインイン画面用）
     * @returns {Promise} Promiseオブジェクト
     */
    const getAnnounce = async () => {
        // メッセージ初期化・処理フラグON
        notice.status.get.message = "";
        notice.status.get.inprogress = true; 
       
        return await backend.getAnnounce()
            .then(response => {
                // 処理成功
                // お知らせデータがある場合はお知らせデータ配列に追加
                if (response.status !== 204) {
                    const result = response.data;

                    // 配信開始日、登録年月日の降順になるようにソートして追加
                    notice.list = util.objectMultiSort(result.contents, ["startDateTime", "creationDate"], -1);

                    // 取得成功
                    notice.status.get.success = true;
                }
            }).catch(error => {
                console.error(error);
                // 処理失敗
                notice.status.get.success = false;
                notice.status.get.message = error.message;
            }).finally(() => {
                // 処理終了
                notice.status.get.inprogress = false;
                // 読み込み済みに変更
                notice.status.get.loaded = true;
            });
        }

     /**
     * お知らせPDFリンク取得処理
     * @param {Object} notice 対象のお知らせデータ
     */
    const getNoticePDF = async (notice) => {
        // 既存の通知を削除
        store.dispatch("removeNotification", "getNoticePDF");

        // SASリンク取得
        return await backend.getnoticePDFLink(notice).then(async response => {
            // SASリンクURL
            let url = "";

            // ステータスチェック
            if (response.status === 204) {
                throw new Error("該当するお知らせPDFが存在しませんでした");
            }

            // レスポンス解析
            url = response.data.content;
            if (!url) {
                throw new Error("お知らせPDFダウンロードリンクが不正です");
            }

            const link = document.createElement("a");   // リンク生成
            link.href = url;                            // SASリンクをセット
            link.target = "_blank";                     // 新しいタブで開く
            link.rel = "noopener noreferrer";           // セキュリティ対策
            link.click();                               // クリック実行

            return Promise.resolve();

        }).catch(error => {
            console.error(error);
            store.dispatch("addNotification", {
                id: "getNoticePDF",
                title: "PDFダウンロード失敗",
                message: error.message,
                type: "danger"
            });

            return Promise.reject();
        });
    }

    
    return {
        notice,
        noticeObject,         // お知らせ配信データオブジェクト
        getNoticeData,        // お知らせ配信データ取得処理
        addNoticeData,        // お知らせ配信データ追加処理
        updateNoticeData,     // お知らせ配信データ更新処理
        deleteNoticeData,     // お知らせ配信データ削除処理
        approveNoticeData,    // お知らせ配信データ承認処理
        updateReadState,      // お知らせ既読更新処理   
        clearNoticeList,      // お知らせデータ配列クリア処理
        getAnnounce,          // お知らせ取得（サインイン画面用）
        getNoticePDF,         // お知らせPDF取得処理
    };
};

export default useNoticeList;