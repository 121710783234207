<template>
  <header v-if="$route.meta.noHeader !== true" class="mb-3 p-0 navbar navbar-light bg-light" :class="[$props.environment != 'production' ? $props.environment : '']">
    <div class="px-3 container-fluid d-flex align-items-center">
      <h1 class="mb-0 fs-5 align-items-center">
        <router-link to="/">
          <img class="header-logo" src="/img/logo_h.svg" alt="帳票保管・閲覧クラウド hayabusa BanD">
        </router-link>
      </h1>
      <table v-if="$store.getters.getOwnInfo.accountId" class="user-info d-none d-sm-block">
        <tr>
            <th>お客様名：</th>
            <td :title="$store.getters.getOwnInfo.name">{{ $store.getters.getOwnInfo.name }}</td>
        </tr>
        <tr>
            <th>前回ご利用日時：</th>
            <td :title="$store.getters.getOwnInfo.previousAccess.trim()">{{ $store.getters.getOwnInfo.previousAccess.trim() || '(初回サインイン)' }}</td>
        </tr>
      </table>
      <!-- スマホ用 -->
      <!-- ナビゲーションバー -->
      <nav class="navbar d-sm-none">
        <a class="navbar-brand"></a>
        <!-- <div>
          <label class="notice-button" for="check">
            <div class="navbar-notice notice-list-mobile">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 18 18">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
              </svg>
              <div v-if="newNotice" class="dot-indicator" style="width: 5px; height: 5px;  margin:10px 10px 0px 0px;"></div>
            </div>
            <div class="navbar-menu">
              お知らせ
            </div>
          </label>
          <input type="checkbox" id="check">
          <div id="noticeList">
            <NoticeList :displayArea="1" />
          </div>
        </div> -->
        <button
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSettings"
          aria-expanded="false"
          aria-controls="offcanvasSettings"
          class="icon"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#77787B" class="bi bi-person-fill" viewBox="0 0 16 16">
          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
        </svg>
        </button>

        <div id="offcanvasSettings" class="offcanvas offcanvas-end settings" aria-labelledby="offcanvasHeader"
          data-bs-parent="#settingsContainer">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasTopLabel">ユーザー設定</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <table v-if="$store.getters.getOwnInfo.accountId" class="user-info">
              <tr>
                  <th>お客様名：</th>
                  <td :title="$store.getters.getOwnInfo.name">{{ $store.getters.getOwnInfo.name }}</td>
              </tr>
              <tr>
                  <th>前回ご利用日時：</th>
                  <td :title="$store.getters.getOwnInfo.previousAccess.trim()">{{ $store.getters.getOwnInfo.previousAccess.trim() || '(初回サインイン)' }}</td>
              </tr>
          </table>
          <div class="offcanvas-body">
            <ul class="navbar-nav p-3">
              <li class="nav-item detail-menu">
                <a class="nav-link" href="/settings">ユーザー情報</a>
              </li>
              <li class="nav-item detail-menu">
                <a class="nav-link" href="/settings?pane=password">パスワード変更</a>
              </li>
              <li class="nav-item detail-menu">
                <a class="nav-link" href="/settings?pane=assign">閲覧先管理</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<style scoped>
header {
  border-top: 6px solid var(--theme-primary);
  border-bottom: 1px solid #ccc;
}
header.staging {
  border-color: orange;
}
header.development {
  border-color: var(--color-green);
}
.header-logo {
  margin: 0.25rem 0.25rem 0.25rem 4rem;
  height: 66px;
}

table.user-info th {
  text-align: end;
}
table.user-info td {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 画面サイズ576px以下の時 */
@media (max-width: 576px) {
  .header-logo {
    margin: 0.25rem 0.5rem 0.5rem 0rem;
    height: 45px;
  }
  table.user-info {
    margin-left: 10px;
    font-size: 14px;
  }
  table.user-info th {
    text-align: start;
  }
  .navbar-menu{
    font-size: 9px;
  }
  .notice-button{
    color: #00000086;
    border-color: rgba(0,0,0,0);
    background-color: rgba(0,0,0,0);
  }
  .navbar-notice{
    margin: 0px 2px 2px 6px;
  }
  #noticeList{
    position: absolute;
    z-index: var(--zindex-high);
    width: 80vw;
    right: 10px;
  }
  input + #noticeList{
    display: none;
  }
  input:checked + #noticeList {
    display:block;
  }
  #check{
    display:none;
  }
  
  /* ユーザーアイコン */
  button.icon {
    margin: 2px 2px 2px 10px;
    width: 42px;
    height: 42px;
    top: 4px;
    font-size: 14pt;
    text-align: center;
    line-height: 40px;
    flex-shrink: 0;
    border-radius: 50%;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    position: sticky;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border:#949494;
  }

  /* ユーザー設定メニュー */
  .settings {
    width: 80% !important;
  }
}

@media (max-width: 350px) {
  .header-logo {
    margin: 0.25rem 0.5rem 0.5rem 0rem;
    height: 35px;
  }
}
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GlobalHeader",
  props: {
    /* environment */
    environment : {
      type: String,
      default: "production",
    },
  },
  setup() {

    return {
    };
  }
});
</script>
