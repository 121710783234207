import { reactive } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';
import backend from '@/services/backendApi';

const useSignup = () => {
    // reCAPTCHA
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    // サインイン処理用オブジェクト
    const signup = reactive({
        data: {                 // ユーザデータ
            accountId: '',      // ID
            name: '',           // 氏名漢字
            nameKana: '',       // 氏名カナ
            mailAddress: '',    // 連絡先メールアドレス
            notification: 1,    // 通知オプション（メール固定）
            customerCode: '',   // 得意先コード
            authCode: '',       // 登録認証コード
            recaptchaToken: '', // reCAPTCHAトークン
        },
        isAgreement: false,     // 利用開始同意の状態
        isConfirmed: false,     // 入力内容確認の状態
    });

    // 状態管理用オブジェクト
    const status = reactive({
        inprogress: false,          // 処理状態
        success: false,             // 処理結果
        message: '',                // メッセージ
    });

    /** サインイン処理 */
    const doSignup = async () => {
        // 固定値データセット
        signup.data.notification = 1;                    // 通知OPTメール固定
        signup.data.mailAddress = signup.data.accountId; // 連絡先メール＝アカウントID

        // 処理中状態ON
        status.inprogress = true;

        // reCAPTCHAトークン取得
        await recaptchaLoaded();
        signup.data.recaptchaToken = await executeRecaptcha('signup');

        // API呼び出し
        backend.doSignup(signup.data)
            .then(() => {
                // 成功
                status.success = true;
            })
            .catch(error => {
                // エラーメッセージをセット
                switch (error.message) {
                    case "お客様情報のチェックでエラーが発生しました。":
                        status.message = "<b>入力内容の確認でエラーが発生しました。次の点を確認してください。</b><br>"
                                       + "・お客様番号が正しいこと<br>・ユーザー登録時認証コードが正しいこと<br>・ユーザー登録時認証コードの有効期限を過ぎていないこと<br>・登録可能なユーザー数制限を超えていないこと";
                        break;
                    default:
                        status.message = error.message;
                        break;
                }

                // 入力画面に戻すため、確認済みをFalseに変更
                signup.isConfirmed = false;
            }).finally(() => {
                // 処理中状態OFF
                status.inprogress = false;
            });
    }

    return {
        signup,
        status,
        doSignup,
    };
};

export default useSignup;