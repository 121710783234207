<template>
  <Loading v-model:active="isLoading" :can-cancel="false" color="var(--color-blue)" />
  <div class="container-xl mx-auto">
    <p class="my-3">
      <router-link to="/dashboard">&lt;&nbsp;ダッシュボードへ戻る</router-link>
    </p>
    <h2>サービスに関するよくあるご質問</h2>
    <p class="my-3">
      当Q&amp;Aにて問題が解決されない場合は、お手数をおかけしますが下記<a href="#contact">サポート窓口</a>までお問い合わせをお願いいたします。
    </p>

    <article v-for="item in qanda.list" :key="item">
      <h3>{{ item.question }}</h3>
      <p style="white-space: pre-wrap;">{{ item.answer }}</p>
    </article>

    <div v-if="qanda.status.get.loaded && !qanda.status.get.success"
      class="alert alert-danger d-flex align-items-center" role="alert">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="警告:">
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
      </svg>
      <div>
        <strong>Q&Aデータの取得に失敗しました</strong>
        <span v-if="qanda.status.get.message !== ''"><br>&nbsp;{{ qanda.status.get.message }}</span>
      </div>
    </div>

    <h2 id="contact" class="mt-3">サポート窓口</h2>
    <p class="my-3">
      帳票保管・閲覧クラウドhayabusaBanDに関するお問い合わせは、以下にて承ります。<br>
      サポート時間：平日（月～金）9:00～17:00。ただし、国⺠の休⽇に関する法律に定める休⽇および年末年始（12⽉29⽇から1⽉3⽇まで）を除く。
    </p>
    <article class="row">
      <div class="col-sm-12 col-md-6">
        <h4>WEBからのお問い合わせ</h4>
        <div class="d-flex">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
          </svg>
          <p class="mt-2">
            <router-link to="/support/supportrequest" class="button-navy">
              お問い合わせフォームへ
            </router-link>
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <h4>お電話でのお問い合わせ</h4>
        <div class="d-flex">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
          </svg>
          <p>
            株式会社本島ビジネスセンター<br>
            帳票保管・閲覧クラウド hayabusaBanD サポート担当<br>
            <span class="fw-bold" style="font-size: 16pt;">027-322-4673</span>
          </p>
        </div>
      </div>
    </article>
  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
}

article {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #1e72b1;
  border-radius: 4px;
}

article h3 {
  padding: 0.25rem;
  color: var(--color-navy);
  font-size: 16pt;
  border-left: solid 4px #1e72b1;
  border-bottom: dotted 1px #1e72b1;
}

article h4 {
  padding: 0.25rem;
  color: var(--color-navy);
  font-size: 14pt;
  border-bottom: solid 1px #1e72b1;
}

article p {
  padding: 0 10px;
  font-size: 1.05rem;
}

article svg {
  margin: 20px;
}

/* ボタン */
.button-navy {
  padding: 10px 20px;
  width: 100%;
  color: var(--color-white);
  font-size: 16pt;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: var(--color-navy);
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  display: block;
  cursor: pointer;
}
.button-navy:hover {
  background-color: var(--color-navy-lighten);
  transition: var(--anim-speed);
}
.button-navy:active {
  background-color: var(--color-navy-darken);
}
</style>

<script>
import { ref } from 'vue';
import Loading from 'vue-loading-overlay';
import useQandA from '@/composable/qanda';

export default {
  name: 'SpQandA',
  components: {
    Loading,
  },
  setup() {
    // qanda.jsの関数を読み込み
    const { qanda, getQandA } = useQandA();

    // 読み込みステータス
    const isLoading = ref(true);

    const initialize = async () => {
      // QandAマスター取得
      await getQandA().finally(() => {
        isLoading.value = false
      });
    }

    initialize();

    return {
      isLoading,  // 読み込みステータス
      qanda,      // QandAデータオブジェクト
    }
  },
}
</script>