<template>
  <div class="container-xl mx-auto">
    <article id="contact">
      <h3>サポート窓口</h3>
      <div class="row mb-3">
        <p class="my-3">        
          帳票保管・閲覧クラウドhayabusaBanDに関するお問い合わせは、以下にて承ります。<br>
          &#9678;サポート時間：平日（月～金）&nbsp;9:00&nbsp;～&nbsp;17:00<br>
          <span class="font-small">※ただし、国⺠の休⽇に関する法律に定める休⽇および年末年始（12⽉29⽇から1⽉3⽇まで）を除く</span>
        </p>
        <div class="contact-frame row">
          <div class="col-sm-12 col-md-6">
            <h4>WEBからのお問い合わせ</h4>
            <div class="d-flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
              </svg>
              <p class="mt-2">
                <router-link to="/support/supportrequest" class="button-navy">
                  お問い合わせフォームへ
                </router-link>
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <h4>お電話でのお問い合わせ</h4>
            <div class="d-flex">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
              <p>
                株式会社本島ビジネスセンター<br>
                帳票保管・閲覧クラウド hayabusaBanD サポート担当<br>
                <span class="fw-bold" style="font-size: 16pt;">027-322-4673</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>

  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
}

article {
  margin: 10px 0;
  padding: 10px 0;
}

article h3 {
  position: relative;
  padding: 0.8rem 0;
  margin: 0px auto 20px;
  overflow: hidden;
  font-weight: bold;
  font-size: 16pt;
}

article h3:before,article h3:after {
  position: absolute;
  width: 100%;
  bottom: 0;
  content: '';
}

article h3:before {
  border-bottom: 5px solid #1e72b1;
}

article h3:after {
  border-bottom: 5px solid skyblue;
}

article h4 {
  padding: 0.25rem;
  color: var(--color-navy);
  font-size: 14pt;
  border-bottom: solid 1px #1e72b1;
}

article p {
  padding: 0 10px;
  font-size: 1.05rem;
}

article svg {
  margin: 20px;
}

/* ボタン */
.button-navy {
  padding: 10px 20px;
  width: 100%;
  color: var(--color-white);
  font-size: 16pt;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: var(--color-navy);
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  display: block;
  cursor: pointer;
}
.button-navy:hover {
  background-color: var(--color-navy-lighten);
  transition: var(--anim-speed);
}
.button-navy:active {
  background-color: var(--color-navy-darken);
}

.contact-frame{
  display: flex;
  width: 97%;
  margin: 20px 1em;
  padding: 15px 10px 0px;
  border: 1px solid #1e72b1;
  border-radius: 4px;
}
.contact-frame h4{
  border-bottom: 2px solid #1e72b1;
}
.col-md-6{
  padding-right:20px;
  padding-left:20px;
}

.font-small {
  font-size: 80%;
}
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GlobalContact",
});

</script>