<template>
  <div class="container-xl mx-auto">
    <button type="button" class="ps-0 btn btn-link" @click="$router.back()">&lt;前のページに戻る</button>
    <h2>hayabusaBanD利用規約</h2>
    <p class="my-3">
      株式会社本島ビジネスセンター（以下｢当社｣という）が、インターネット上で提供する「hayabusaBanD帳票保管・閲覧クラウド」（以下｢本サービス｣という）の利用を希望する申込者は、以下の規約に同意し本サービスを利用するものとします。
    </p>
    <ol class="mb-1">
      <li><a href="#cat01">サービス利用にあたっての留意事項</a></li>
      <li><a href="#cat02">利用規約</a></li>
      <!-- <li><a href="#cat03">本サービスについて</a></li> -->
      <li><a href="#cat03">クッキーポリシー</a></li>
    </ol>

    <article>
      <h3 id="cat01">1.サービス利用にあたっての留意事項</h3>
      <h4>ご利用対象先</h4>
      <p>
        本サービスをご利用いただける方は、当社と本サービスの利用に同意した法人企業および同等の機関・組織ならびに団体様とさせていただきます。
      </p>
      <h4>サービスエリア</h4>
      <p>
        サービスエリアは日本国内です。
      </p>
    </article>

    <article>
      <h3 id="cat02">2.利用規約</h3>
      <section>
        <h3 class="chapter">第１章	総則</h3>
        <h4>第１条（利用規約の適用）</h4>
        <p>当社は、この利用規約（以下単に「利用規約」といいます。）に基づき、本サービスを提供します。</p>
        <h4>第２条（定義）</h4>
        <p class="mb-1">利用規約においては、次の用語はそれぞれ次の意味で使用します。</p>
        <dl class="definition">
          <dt>①&nbsp;本サービス</dt><dd>利用規約に基づき当社がサービスプロバイダーとして契約者に提供するhayabusaBanD</dd>
          <dt>②&nbsp;契約者</dt><dd>利用規約に基づく利用契約を当社と締結し、本サービスの提供を受ける者</dd>
          <dt>③&nbsp;利用責任者</dt><dd>本サービス利用時に当社との窓口となる者</dd>
          <dt>④&nbsp;利用者</dt><dd>利用規約に同意し、本サービスを利用する者</dd>
          <dt>⑤&nbsp;テナント管理者</dt><dd>利用者を追加、削除、権限付与等を管理監督する者</dd>
          <dt>⑥&nbsp;利用契約</dt><dd>利用規約に基づき当社と契約者との間に締結される本サービスの提供に関する契約</dd>
          <dt>⑦&nbsp;利用契約等</dt><dd>利用契約及び利用規約</dd>
          <dt>⑧&nbsp;契約者設備</dt><dd>本サービスの提供を受けるため契約者が設置するコンピューター、電気通信設備その他の機器およびソフトウェア、電気通信事業者より借り受ける電気通信回線</dd>
          <dt>⑨&nbsp;本サービス用設備等</dt><dd>本サービスを提供するにあたり当社が設置するコンピューター設備、電気通信設備、その他の機器およびソフトウェア、電気通信事業者より借り受ける電気通信回線、マイクロソフトAzureが提供する各種サービス</dd>
          <dt>⑩&nbsp;テナントコード</dt><dd>契約者を識別するために用いられる符号</dd>
          <dt>⑪&nbsp;利用者ID</dt><dd>利用者を識別するために用いられる符号</dd>
          <dt>⑫&nbsp;パスワード</dt><dd>利用者IDと付随して識別するために用いられる符号</dd>
        </dl>
        <h4>第３条（通知）</h4>
        <ol class="type0">
          <li>当社から契約者への通知は、利用契約等に特段の定めのない限り、本サービス内お知らせ欄又は通知内容を電子メールにより行います。</li>
          <li>前項の規定に基づき、当社から契約者への通知を電子メールにより行う場合には、契約者に対する当該通知は、それぞれ本サービス内お知らせ欄又は電子メールの送信がなされた時点から効力を生じるものとします。</li>
        </ol>
        <h4>第４条（利用規約の変更）</h4>
        <ol class="type0">
          <li>当社は、利用規約を随時変更することがあります。なお、この場合には、契約者の利用条件その他利用契約の内容は、変更後の利用規約を適用するものとします。</li>
          <li>当社は、前項の変更を行う場合は、１ヶ月間の予告期間をおいて、変更後の利用規約の内容を契約者に本サービス内お知らせ欄又は電子メールにより通知するものとします。</li>
        </ol>
        <h4>第５条（権利義務譲渡の禁止）</h4>
        <p>契約者は、あらかじめ当社の書面による承諾がない限り、利用契約上の地位、利用契約に基づく権利又は義務の全部又は一部を他に譲渡してはならないものとします。</p>
        <h4>第６条（合意管轄）</h4>
        <p>契約者と当社の間で訴訟の必要が生じた場合には、前橋地方裁判所をもって合意による専属管轄裁判所とします。</p>
        <h4>第７条（準拠法）</h4>
        <p>利用契約等の成立、効力、履行及び解釈に関する準拠法は、日本法とします。</p>
        <h4>第８条（協議等）</h4>
        <p>利用契約等に規定のない事項及び規定された項目について疑義が生じた場合は両者誠意を持って協議の上解決することとします。なお、利用契約等の何れかの部分が無効である場合でも、利用契約等全体の有効性には影響がないものとし、かかる無効の部分については、当該部分の趣旨に最も近い有効な規定を無効な部分と置き換えるものとします。</p>
      </section>

      <section>
        <h3 class="chapter">第２章&nbsp;&nbsp;契約の締結等</h3>
        <h4>第９条（利用契約の締結等）</h4>
        <ol class="type0">
          <li>利用契約は、本サービスの契約者が、当社既定の利用申込書にて利用申込を行い、当社がこれに対し当社所定の方法により承諾の通知を発信したときに成立するものとします。なお、本サービスの契約者は利用規約の内容を承諾の上、かかる申込を行うものとし、本サービスの契約者が申込を行った時点で、当社は、本サービスの契約者が利用規約の内容を承諾しているものとみなします。</li>
          <li>当社は、前項その他利用規約の規定にかかわらず、本サービスの契約者が次の各号のいずれかに該当する場合には、利用契約又は利用変更契約を締結しないことができます。
            <ol class="type0-nest">
              <li>①&nbsp;本サービスに関する金銭債務の不履行、その他利用契約等に違反したことを理由として利用契約を解除されたことがあるとき</li>
              <li>②&nbsp;利用申込書又は利用変更申込に虚偽の記載、誤記があったとき又は記入もれがあったとき</li>
              <li>③&nbsp;金銭債務その他利用契約等に基づく債務の履行を怠るおそれがあるとき</li>
              <li>④&nbsp;その他当社が不適当と判断したとき</li>
            </ol>
          </li>
        </ol>
        <h4>第１０条（変更通知）</h4>
        <ol class="type0">
          <li>契約者は、その商号若しくは名称、本店所在地若しくは住所、連絡先その他利用申込の契約者にかかわる事項に変更があるときは、変更予定日の１ヶ月前までに当社に通知するものとします。</li>
          <li>当社は、契約者が前項に従った通知を怠ったことにより契約者が通知の不到達その他の事由により損害を被った場合であっても、一切責任を負わないものとします。</li>
        </ol>
        <h4>第１１条（一時的な中断及び提供停止）</h4>
        <ol class="type0">
          <li>当社は、次の各号のいずれかに該当する場合には、契約者への事前の通知又は承諾を要することなく、本サービスの提供を中断することができるものとします。
            <ol class="type0-nest">
                <li>①&nbsp;本サービス用設備等の故障により保守を行う場合</li>
                <li>②&nbsp;運用上又は技術上の理由でやむを得ない場合</li>
                <li>③&nbsp;その他天災地変等不可抗力により本サービスを提供できない場合</li>
                <li>④&nbsp;マイクロソフト提供サービスの故障、保守、不具合に起因する場合</li>
            </ol>
          </li>
          <li>当社は、契約者が第１４条第１項各号のいずれかに該当する場合又は契約者が利用料金未払いその他利用契約等に違反した場合には、契約者への事前の通知若しくは催告を要することなく本サービスの全部又は一部の提供を停止することができるものとします。</li>
          <li>当社は、前各項に定める事由のいずれかにより本サービスを提供できなかったことに関して契約者又はその他の第三者が損害を被った場合であっても、一切責任を負わないものとします。</li>
        </ol>
        <h4>第１２条（利用期間）</h4>
        <ol class="type0">
          <li>本サービスの利用期間は、第９条第１項の利用契約成立の日から１年間とします。ただし、契約者は期間満了１ヶ月前までに当社に契約終了の意思表示又は当社から契約者に別段の意思表示がないときは、利用契約は期間満了日の翌日からさらに１年間自動的に更新されるものとし、以後もまた同様とします。</li>
          <li>当社は、本サービスの利用期間満了の３ヶ月前までに、契約者に利用契約の変更内容を通知することにより、更新後における本サービスの内容及び利用料金その他利用契約内容を変更することができるものとします。</li>
        </ol>
        <h4>第１３条（契約者からの利用契約の解約）</h4>
        <ol class="type0">
          <li>契約者は、解約希望日の１ヶ月前までに当社に通知することにより、解約希望日の月末日をもって利用契約を解約することができるものとします。なお、解約希望日の記載のない場合又は解約希望通知到達日から解約希望日までの期間が１ヶ月未満の場合、解約希望通知が当社に到達した日より１ヶ月後の月末日を契約者の解約希望日とみなすものとします。</li>
          <li>契約者は、前項に定める通知が当社に到達した時点において未払いの利用料金等又は支払遅延損害金がある場合には、直ちにこれを支払うものとします。</li>
          <li>契約解約時に契約者が希望した場合は保管されている帳票データを返却します。また解約成立後から１ケ月経過ののちにデータを消去します。</li>
        </ol>
        <h4>第１４条（当社からの利用契約の解除）</h4>
        <ol class="type0">
          <li>当社は、契約者が次の各号のいずれかに該当すると判断した場合、契約者への事前の通知若しくは催告を要することなく利用契約の全部若しくは一部を解除することができるものとします。
            <ol class="type0-nest">
              <li>①&nbsp;利用申込書、利用変更申込その他通知内容等に虚偽記入又は記入もれがあった場合</li>
              <li>②&nbsp;支払停止の状態又は手形交換所の取引停止処分を受けた場合</li>
              <li>③&nbsp;差押え、仮差押え若しくは競売の申立があったとき又は公租公課の滞納処分を受けた場合</li>
              <li>④&nbsp;破産、民事再生、会社更生等の申立を行った場合又は申立を受けた場合</li>
              <li>⑤&nbsp;監督官庁から営業許可の取消、停止等の処分を受けた場合</li>
              <li>⑥&nbsp;利用契約等に違反し当社がかかる違反の是正を催告した後合理的な期間内に是正されない場合</li>
              <li>⑦&nbsp;その他利用契約を履行することが困難となる事由が生じた場合</li>
            </ol>
          </li>
          <li>契約者は、前項による利用契約の解約があった時点において未払いの利用料金等又は支払遅延損害金がある場合には、当社が定める日までにこれを支払うものとします。</li>
          <li>契約解約時に契約者が希望した場合は保管されている帳票データを返却します。また解約成立後から１ケ月経過ののちにデータを消去します。</li>
        </ol>
        <h4>第１５条（本サービスの廃止）</h4>
        <p class="mb-1">当社は、次の各号のいずれかに該当する場合、本サービスの全部又は一部を廃止し、廃止日をもって利用契約の全部又は一部を解約することができるものとします。</p>
        <ul class="typenone">
          <li>①&nbsp;廃止日の３ヶ月前までに契約者に通知した場合</li>
          <li>②&nbsp;天災地変等不可抗力により本サービスを提供できない場合</li>
          <li>③&nbsp;マイクロソフトの提供サービス変更に伴い本サービスを提供できない場合</li>
        </ul>
        <h4>第１６条（契約終了後の処理）</h4>
        <p>契約終了時に契約者が希望した場合は保管されている帳票データを返却します。また契約終了後から１ケ月経過ののちにデータを消去します。</p>
      </section>

      <section>
        <h3 class="chapter">第３章&nbsp;&nbsp;サービス</h3>
        <h4>第１７条（本サービスの内容）</h4>
        <ol class="type0">
          <li>当社が提供する本サービスの内容は、別紙クラウドサービスレベルの仕様書に定めるものとします。</li>
          <li>契約者は以下の事項を了承の上、本サービスを利用するものとします。
            <ol class="type0-nest">
              <li>①&nbsp;第３５条第１項各号に掲げる場合を含め、本サービスに当社に起因しない不具合が生じる場合があること</li>
              <li>②&nbsp;当社に起因しない本サービスの不具合については、当社は一切その責を免れること</li>
            </ol>
          </li>
        </ol>
        <h4>第１８条（本サービスの提供区域）</h4>
        <p>本サービスの提供区域は、日本国内とします。</p>
        <h4>第１９条（サポート）</h4>
        <p>当社は、別紙クラウドサービスレベルの仕様書に定めるサポートサービスを利用契約に基づき契約者に対して提供するものとします。</p>
        <h4>第２０条（マイクロソフト製品利用の承諾）</h4>
        <p class="mb-1">本サービスはマイクロソフトの製品を用いてサービス構成されております。</p>
        <ul class="typenone">
          <li>①&nbsp;契約者は、当社が提供する本サービスがマイクロソフトＡｚｕｒｅを利用したサービスであることを承知したうえで利用申込を行ったものと判断いたします。</li>
          <li>②&nbsp;契約者は、当社が提供する本サービスがマイクロソフトＡｚｕｒｅのＳＬＡに則したサービス内容であることを承知したうえで利用申込を行ったものと判断いたします。</li>
          <li>③&nbsp;契約者は、本サービスで保管したデータはマイクロソフトＡｚｕｒｅに記録保管されていることを認識したものとします。</li>
          <li>④&nbsp;本サービスの利用により生じる直接被害、間接損害または結果的損害について、マイクロソフトによる一切の保証およびマイクロソフトまたはそのサプライヤーによる一切の賠償責任を負いません。</li>
          <li>⑤&nbsp;本サービスに関する契約者からの問い合わせ対応は、当社が行います。</li>
          <li>⑥&nbsp;本サービスの業務実施場所はマイクロソフトクラウドサービス内とします。</li>
        </ul>
      </section>

      <section>
        <h3 class="chapter">第４章&nbsp;&nbsp;利用料金</h3>
        <h4>第２１条（本サービスの利用料金、算定方法等）</h4>
        <p>本サービスの利用料金、算定方法等は、別に定めるとおりとします。</p>
        <h4>第２２条（利用料金の支払義務）</h4>
        <ol class="type0">
          <li>契約者は、利用料金及びこれにかかる消費税等を利用契約等に基づき、毎月支払うものとします。なお、契約者が本条に定める支払を完了しない場合、当社は、第１１条第２項の定めに従い、本サービスの提供を停止することができるものとします。</li>
          <li>利用期間において、第１１条に定める本サービスの提供の中断、停止その他の事由により本サービスを利用することができない状態が生じたときであっても、契約者は、利用期間中の利用料金及びこれにかかる消費税等の支払を要します。</li>
        </ol>
        <h4>第２３条（利用料金の支払方法）</h4>
        <p>契約者は、本サービスの利用料金及びこれにかかる消費税等を、当社が定める支払方法により支払うものとします。</p>
        <h4>第２４条（遅延利息）</h4>
        <ol class="type0">
          <li>契約者が、本サービスの利用料金その他の利用契約等に基づく債務を所定の支払期日が過ぎてもなお履行しない場合、契約者は、所定の支払期日の翌日から支払日の前日までの日数に、年１４．６％の利率で計算した金額を延滞利息として、本サービスの料金その他の債務と一括して、当社が指定する期日までに当社の指定する方法により支払うものとします。</li>
          <li>前項の支払に必要な振込手数料その他の費用は、契約者の負担とします。</li>
        </ol>
      </section>

      <section>
        <h3 class="chapter">第５章&nbsp;&nbsp;契約者の義務等</h3>
        <h4>第２５条（自己責任の原則）</h4>
        <ol class="type0">
          <li>契約者は、本サービスの利用に伴い、自己の責に帰すべき事由で第三者に対して損害を与えた場合、又は第三者からクレーム等の請求がなされた場合、自己の責任と費用をもって処理、解決するものとします。契約者が本サービスの利用に伴い、第三者から損害を被った場合、又は第三者に対してクレーム等の請求を行う場合においても同様とします。</li>
          <li>本サービスを利用して契約者が登録する情報については、契約者の責任で登録されるものであり、当社はその内容等についていかなる保証も行わず、また、それに起因する損害についてもいかなる責任も負わないものとします。</li>
          <li>契約者は、契約者がその故意又は過失により当社に損害を与えた場合、当社に対して、当該損害の賠償を行うものとします。</li>
        </ol>
        <h4>第２６条（利用者・利用責任者・テナント管理者）</h4>
        <ol class="type0">
          <li>契約者は利用責任者及びテナント管理者の選任を行うものとします。利用責任者、テナント管理者の不正利用を防ぐため、管理、監督の義務を負います。</li>
          <li>テナント管理者は利用者の選任を行うものとします。テナント管理者は、利用者の不正利用を防ぐため、管理、監督の義務を負います。</li>
          <li>本サービスの利用に関する当社との連絡・確認等は、利用責任者を通じて行うものとします。</li>
          <li>契約者は、利用責任者に変更が生じた場合、当社に対し、速やかに通知するものとします。</li>
        </ol>
        <h4>第２７条（本サービス利用のための設備設定・維持）</h4>
        <ol class="type0">
          <li>契約者は、自己の費用と責任において、当社が定める条件にて契約者設備を設定し、契約者設備及び本サービス利用のための環境を維持するものとします。</li>
          <li>契約者は、本サービスを利用するにあたり自己の責任と費用をもって、電気通信事業者等の電気通信サービスを利用して契約者設備を接続するものとします。</li>
          <li>契約者設備、前項に定める通信設備並びに本サービス利用のための環境に不具合がある場合、当社は契約者に対して本サービスの提供の義務を負わないものとします。</li>
          <li>当社は、本サービスに関して保守、運用上又は技術上必要であると判断した場合、契約者が本サービスにおいて登録するデータ等について、監視、分析、ＯＣＲモデル作成、調査等必要な行為を行うことができます。</li>
        </ol>
        <h4>第２８条（利用者ＩＤ及びパスワードの管理）</h4>
        <ol class="type0">
          <li>契約者は、得意先コード、利用者ＩＤ及びパスワード（以下、ＩＤ等という）を利用者以外に開示、貸与、共有させないとともに、利用者以外に漏洩することのないよう厳重に管理するものとします。ＩＤ等の管理不備、使用上の過誤、利用者以外の者の使用等により契約者自身及びその他の者が損害を被った場合、当社は一切の責任を負わないものとします。契約者及び利用者による利用、その他の行為は、全て契約者による利用とみなすものとします。</li>
          <li>利用者以外の者が契約者の得意先コード・利用者ＩＤ及びパスワードを用いて、本サービスを利用した場合、当該行為は契約者の行為とみなされるものとし、契約者はかかる利用についての利用料金の支払その他の債務一切を負担するものとします。また、当該行為により当社が損害を被った場合は契約者は当該損害を補填するものとします。ただし、当社の故意又は過失により利用者ＩＤ及びパスワードが第三者に利用された場合はこの限りではありません。</li>
        </ol>
        <h4>第２９条（禁止事項）</h4>
        <ol class="type0">
          <li>契約者は本サービスの利用に関して、以下の行為を行わないものとします。
            <ol class="type0-nest">
              <li>①&nbsp;当社若しくは第三者の著作権、商標権などの知的財産権その他の権利を侵害する行為、又は侵害するおそれのある行為</li>
              <li>②&nbsp;利用契約等に違反して、第三者に本サービスを利用させる行為</li>
              <li>③&nbsp;詐欺等の犯罪に結びつく又は結びつくおそれがある行為</li>
              <li>④&nbsp;本サービスの運営を妨げる行為（過度なリクエスト送信、サービス停止攻撃、情報の改ざん、不正アクセス、コンピュータウイルスの送信）</li>
            </ol>
          </li>
          <li>契約者は、前項各号のいずれかに該当する行為がなされたことを知った場合、又は該当する行為がなされるおそれがあると判断した場合は直ちに当社に通知するものとします。</li>
          <li>当社は、本サービスの利用に関して、契約者等の行為が第１項各号のいずれかに該当するものであること又は契約者の登録した情報が第１項各号のいずれかの行為に関連する情報であることを知った場合、事前に契約者に通知することなく、本サービスの全部又は一部の提供を一時停止し、又は第１項各号に該当する行為に関連する情報を削除することができるものとします。ただし、当社は、契約者の行為又は契約者が登録する（契約者の利用とみなされる場合も含みます。）情報を監視する義務を負うものではありません。</li>
        </ol>
      </section>

      <section>
        <h3 class="chapter">第６章&nbsp;&nbsp;当社の義務等</h3>
        <h4>第３０条（善管注意義務）</h4>
        <p>当社は、本サービスの利用期間中、善良なる管理者の注意をもって本サービスを提供するものとします。ただし利用契約等に別段の定めがあるときはこの限りでないものとします。</p>
        <h4>第３１条（本サービス用設備等の障害等）</h4>
        <ol class="type0">
          <li>当社は、本サービス用設備等について障害があることを知ったときは、遅滞なく契約者にその旨を通知するものとします。</li>
          <li>当社は、本サービス用設備等に障害があることを知ったときは、遅滞なく本サービス用設備等を修理又は復旧します。マイクロソフトＡｚｕｒｅに関連する障害の修理または復旧はマイクロソフトの責任範疇と致します。</li>
          <li>当社は本サービスの安定供給を目的として、データのバックアップを日次で実施するが、かかるバックアップは個別の契約者情報およびシステム・データの復元を目的とするものではなく、本サービス設備等の障害時における、データの消失を補完するものであり、契約者からのデータの戻しに対する依頼には応じないものとします。</li>
          <li>上記のほか、本サービスに不具合が発生したときは、契約者及び当社はそれぞれ遅滞なく相手方に通知し、両者協議のうえ各自の行うべき対応措置を決定したうえでそれを実施するものとします。</li>
        </ol>
      </section>

      <section>
        <h3 class="chapter">第７章&nbsp;&nbsp;機密情報及び個人情報の取り扱い</h3>
        <h4>第３２条（機密情報の取り扱い）</h4>
        <ol class="type0">
          <li>契約者及び当社は、本サービスに基づいて知りえた内容を、一切漏えいしてはならないものとします。但し、次の各号の情報は機密事項から除外されます。
            <ol class="type0-nest">
              <li>①&nbsp;知りえた時点ですでに公知であった情報</li>
              <li>②&nbsp;知りえた時点ですでに自己が保有していた情報</li>
              <li>③&nbsp;本サービス以外で、自己が独自に、かつ適法な方法で入手した情報</li>
            </ol>
          </li>
          <li>前項にかかわらず法令・行政機関及び司法機関等の命令、要請等により機密情報を開示する場合は、相手方に事前の通知をするものとします。事前の通知ができない場合は、速やかな事後通知により、相手方に配慮するものとします。</li>
        </ol>
        <h4>第３３条（個人情報の取り扱い）</h4>
        <ol class="type0">
          <li>契約者及び当社は、本サービスに基づいて知り得た内容に含まれる個人情報（個人情報の保護に関する法律に定める「個人情報」をいいます。以下同じとします。）を本サービス遂行目的の範囲内でのみ使用し、第三者に開示又は漏洩しないものとするとともに、個人情報に関して個人情報の保護に関することを含め関連法令を遵守するものとします。当社による利用者の登録事項の取扱いについては、別途当社個人情報保護方針に定めによるものとし、利用者はこの<a href="https://mbcnet.co.jp/pms/privacy.html" target="_blank">個人情報保護方針</a>に従って当社が利用者のお客様情報を取扱うことについて同意するものとします。</li>
          <li>本条の規定は、本サービス終了後も有効に存続するものとします。</li>
        </ol>
      </section>

      <section>
        <h3 class="chapter">第８章&nbsp;&nbsp;損害賠償等</h3>
        <h4>第３４条（損害賠償の制限）</h4>
        <p>債務不履行責任、不法行為責任、その他法律上の請求原因の如何を問わず、本サービス又は利用契約等に関して、当社が契約者に対して負う損害賠償責任期間は利用契約成立日から３年とし、その範囲は、当社の責に帰すべき事由により又は当社が利用契約等に違反したことが直接の原因で契約者に現実に発生した通常の損害に限定され、損害賠償の額は障害発生月度の月額費用を上限とします。</p>
        <h4>第３５条（免責）</h4>
        <ol class="type0">
          <li>本サービス又は利用契約等に関して当社が負う責任は、理由の如何を問わず前条の範囲に限られるものとし、当社は、以下の事由により契約者に発生した損害については、債務不履行責任、不法行為責任、その他の法律上の請求原因の如何を問わず賠償の責任を負わないものとします。
            <ol class="type0-nest">
              <li>①&nbsp;天災地変、騒乱、暴動等の不可抗力</li>
              <li>②&nbsp;契約者設備の障害又は本サービス用設備等までの通信接続サービスの不具合等契約者の接続環境の障害</li>
              <li>③&nbsp;本サービス用設備等からの応答時間等通信接続サービスの性能値に起因する損害</li>
              <li>④&nbsp;未知のコンピュータウイルス等の本サービス用設備等への侵入</li>
              <li>⑤&nbsp;善良なる管理者の注意をもってしても防御し得ない本サービス用設備等への第三者による不正アクセス又はアタック、通信経路上での傍受</li>
              <li>⑥&nbsp;当社が定める手順・セキュリティ手段等を契約者が遵守しないことに起因して発生した損害</li>
              <li>⑦&nbsp;本サービス用設備等のうち当社の製造に係らないマイクロソフトＡｚｕｒｅのサービスに起因して発生した損害</li>
              <li>⑧&nbsp;本サービス用設備等のうち、当社の製造に係らないハードウェアに起因して発生した損害</li>
              <li>⑨&nbsp;電気通信事業者の提供する電気通信役務の不具合に起因して発生した損害</li>
              <li>⑩&nbsp;刑事訴訟法第２１８条、犯罪捜査のための通信傍受に関する法律の定めに基づく強制の処分その他裁判所の命令若しくは法令に基づく強制的な処分</li>
              <li>⑪&nbsp;お客様が送信した情報が本サービスに到達するかどうか、および本サービスに到達した情報がお客様の送信した情報と同一内容であるか</li>
              <li>⑫&nbsp;当社は本サービスの停止または中止、サービス内容の変更によって受ける損害</li>
              <li>⑬&nbsp;その他当社の責に帰すべからざる事由</li>
              <li>⑭&nbsp;当社は予告なく利用規約を変更することがあります。その場合は変更後の利用規約が適用されます。</li>
            </ol>
          </li>
          <li>当社は、契約者が本サービスを利用することにより契約者と第三者との間で生じた紛争について一切責任を負わないものとします。</li>
        </ol>
      </section>
       
    </article>

    <article>
      <h3 id="cat03">3.クッキーポリシー</h3>
      <h4>Cookieの利用について</h4>
      <p>
        本サービスでは、お客様により良いサービスを提供するため、Cookieを利用しています。
      </p>
      <h4>Cookieとは</h4>
      <p>
        Cookieとは、本サービス利用時に利用者のコンピューター、スマートフォン、タブレットなどのインターネット接続可能な機器上に保存される小さなテキストファイルのことです。<br>
        Cookieはお客様ご自身でブラウザの設定を操作することで、削除や拒否を行うことができます。ただし、Cookieを削除や拒否することで、本サービスの一部を利用できない場合や、ページが正しく表示されないなどが起こる場合がございます。
      </p>
      <p class="mb-1">
        Cookieには以下の種類があります。
      </p>
      <table>
        <tr>
          <th>必須Cookie</th>
          <td>主にサービスを動作する上で必須となるCookie</td>
        </tr>
        <tr>
          <th>パフォーマンスCookie</th>
          <td>主に各種サービスの利便性を向上させる目的で必要なCookie</td>
        </tr>
        <tr>
          <th>機能性Cookie</th>
          <td>主に利用者ごとのパーソナライズなど利便性を向上させる目的のCookie</td>
        </tr>
        <tr>
          <th>ターゲティングCookie</th>
          <td>主に利用者の興味関心に基づいたターゲティング広告等での利用を目的としたCookie</td>
        </tr>
      </table>
      <p class="mb-1">
        当サイトでは、以下のCookieを取得しています。
      </p>
      <table>
        <tr>
          <th>Cookieタイプ</th>
          <th>Cookie名</th>
          <th>提供元</th>
          <th>有効期限</th>
        </tr>
        <tr>
          <td>必須Cookie</td>
          <td>token</td>
          <td>hayabusaband.trafficmanager.net</td>
          <td>3時間</td>
        </tr>
        <tr>
          <td>必須Cookie</td>
          <td>_GRECAPTCHA</td>
          <td>google.com</td>
          <td>180日</td>
        </tr>
        <tr>
          <td>パフォーマンスCookie</td>
          <td>_ga</td>
          <td>google.com</td>
          <td>2年</td>
        </tr>
        <tr>
          <td>パフォーマンスCookie</td>
          <td>_ga_G9692VFRMT</td>
          <td>google.com</td>
          <td>2年</td>
        </tr>
      </table>
      <h4>Cookieに関する選択</h4>
      <p class="mb-1">
        Cookieの削除、またはCookieを削除・拒否するようにブラウザを設定する場合は、以下の各ブラウザのヘルプページを参照ください。<br>
        ただし、Cookieの削除や拒否することで、当サイト上のすべてのサービスを利用できない場合や、一部のページが正しく表示されない場合がございます。
      </p>
      <p class="ms-3">
        <a href="https://support.google.com/accounts/answer/32050" target="_blank" rel="noopener noreferrer">Google Chrome</a><br>
        <a href="https://support.microsoft.com/ja-jp/microsoft-edge/microsoft-edge-%E3%81%A7-cookie-%E3%82%92%E5%89%8A%E9%99%A4%E3%81%99%E3%82%8B-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noopener noreferrer">Microsoft Edge</a><br>
        <a href="https://support.mozilla.org/ja/products/firefox/protect-your-privacy/cookies" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a><br>
        <a href="https://support.apple.com/ja-jp/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Mac Safari</a><br>
        <a href="https://support.apple.com/ja-jp/HT201265" target="_blank" rel="noopener noreferrer">iPhone Safari</a>
      </p>
      <h4>利用するアクセス解析ツール、取得する情報および利用目的</h4>
      <p class="mb-1">
        本サービスでは、サイトの利用状況の把握とサービスの向上・改善のためアクセス解析ツールとしてGoogle Analyticsを利用しています。<br>
        Google Analyticsにより収集、記録、分析された利用者の情報は、Google社により同社のプライバシーポリシーに基づいて管理されます。<br>
        詳細については、以下のリンクをご確認ください。
      </p>
      <p class="ms-3">
        <a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank" rel="noopener noreferrer">Google Analytics利用規約</a><br>
        <a href="https://policies.google.com/technologies/partner-sites?hl=ja" target="_blank" rel="noopener noreferrer">Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用</a><br>
        <a href="https://policies.google.com/privacy?hl=ja" target="_blank" rel="noopener noreferrer">Googleのプライバシーポリシー</a><br>
        <a href="https://marketingplatform.google.com/about/analytics/" target="_blank" rel="noopener noreferrer">Google Analyticsに関する詳細情報</a>
      </p>
      <p>
        当社の個人情報の取扱いについては、「<a href="https://www.mbcnet.co.jp/pms/privacy.html" target="_blank" rel="noopener noreferrer">個人情報保護方針</a>」をご覧ください。
      </p>
      <p>
        最終更新日:&nbsp;2023年7月12日
      </p>
    </article>

  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
}

article {
  margin: 10px 0;
  padding: 10px;
}

article h3 {
  padding: 0.25rem;
  color: var(--color-navy);
  font-size: 16pt;
  border-bottom: solid 1px #1e72b1;
}
article h3.chapter {
  text-align: center;
  margin-top: 20px;
  border-bottom: none !important;
  background-color: #efefef;
}

article h4 {
  margin-bottom: 4px;
  color: var(--color-navy);
  font-size: 14pt;
}
  article h4::before {
    content: '■';
  }

article p {
  padding: 0 10px;
  /* font-size: 1.05rem; */
}

article table {
  margin: 0 20px 20px;
  border-collapse: collapse;
  border: solid 2px #1e72b1;
}

article table th{
  padding: 4px;
  border: solid 1px #1e72b1;
  background: #e9f6ff;
}

article table td {
  padding: 4px;
  border: solid 1px #1e72b1;
}

dl.definition{
  margin: 0 0 1rem 4em;
  display:flex;
  flex-wrap: wrap;
}
dl.definition dt,
dl.definition dd{
  padding: 0px;
}
dl.definition dt {
  width: 17%;
  font-weight: normal;
}
dl.definition dd {
  margin-left: 0;
  width: 83%;
}

ul.typenone{
  list-style-type: none;
  margin-left: 3em;
}
ul.typenone li{
  text-indent: -1.2em;
  margin-left: 0em;
}

ol.type0, ol.type0 ol{
  padding-left: 0em;
}
ol.type0 li{
  list-style-type:none;
  margin-left: 3em;
}
ol.type0 li:before{
  display: inline-block; 
  text-align: right;
  width: 2em; 
  margin-right: 1em; 
  margin-left: -3em;
}
ol.type0>li{
  counter-increment: level1;
}
ol.type0>li:before{
  content: "( " counter(level1)" )"; 
}
ol.type0>li>ol>li{
  counter-increment: level2;
}
ol.type0-nest{
  padding-left: 2em !important;
}
ol.type0-nest>li{
  margin-left: 0em !important;
  text-indent: -1.2em;
}
ol.type0-nest>li:before{
  /* content: counter(level2)!important;*/
  width: 4em;
  margin-right: 1em;
  margin-left: -9em;
}

</style>

<script>
export default {
  name: 'Tos',
  components: {
  },
  setup() {
    /*eslint no-irregular-whitespace:0 */ // ESLintで全角スペースを警告しない
    return {
    }
  },
}
</script>