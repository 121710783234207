import { computed, reactive, watch } from 'vue';
import backend from '@/services/backendApi';

/**
 *QandAマスター関連
 */
const useQandA = () => {
    const qanda = reactive({
        data: [],               // QandAデータ配列(生データ)
        list: [],               // 検索処理用QandAデータ配列
        length: computed(() => qanda.list.length),
        status: {
            get: {                          // 取得状況
                loaded: false,              // 取得済か否か
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            add: {                          // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            update: {                       // 更新状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            delete: {                       // 削除状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            }
        }
    });

    /** 新規追加用オブジェクト */
    const qandaObject = reactive({
        id: "",             // ID
        category: "",       // カテゴリー
        question: "",       // 質問
        answer: "",         // 回答
    });

    /** 検索条件 */
    const searchCondition = reactive({
        id: "",             // ID
        category: "",       // カテゴリー
        question: "",       // 質問
        answer: "",         // 回答
    });

    // 検索条件の変更を監視し、検索を実行させる
    watch(searchCondition, () => searchQandAData());


    /**QandAデータをIDの昇順にソートする処理 */
    const sortById = (a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
    }

    /**
     * QandAマスター取得
     * @param {Boolean} maintenanceMode マスター保守画面からの場合はtrue、それ以外の場合はfalse。デフォルト=false。
     */
    const getQandA = async (maintenanceMode = false) => {
        // メッセージ初期化、処理中フラグON
        qanda.status.get.message = "";
        qanda.status.get.inprogress = true;

        // データクリア
        clearQandAList();

        await backend.getQandA(maintenanceMode)
            .then(response => {
                //QandAデータがある場合はQandAデータ配列にセット
                if (response.status !== 204) {
                    const result = response.data;
                    // アカウントIDの昇順にソート
                    qanda.data = result.contents.slice().sort(sortById);

                    // 取得成功
                    qanda.status.get.success = true;
                }
            }).catch(error => {
                console.error(error);
                // 取得失敗
                qanda.status.get.success = false;
                qanda.status.get.message = error.message;
            }).finally(() => {
                // 読み込み済みに変更
                qanda.status.get.loaded = true;
                // 処理中フラグOFF
                qanda.status.get.inprogress = false;

                // 検索処理実施
                searchQandAData();
            });
    }

    /**
     * 新規QandAマスター追加処理
     * @param {Object} qandaObject QandAマスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const addQandA = async (qandaObject) => {
        // メッセージ初期化・処理フラグON
        qanda.status.add.message = "";
        qanda.status.add.inprogress = true;

        return await backend.addQandA(qandaObject)
            .then(response => {
                // 処理成功
                qanda.status.add.success = true;
                qanda.status.add.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                qanda.status.add.success = false;
                qanda.status.add.message = error.message;
            }).finally(() => {
                // 処理終了
                qanda.status.add.inprogress = false;
            });
    }

    /**
     *QandAマスター更新処理
     * @param {Object} qandaObject QandAマスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const updateQandA = async (qandaObject) => {
        // メッセージ初期化・処理フラグON
        qanda.status.update.message = "";
        qanda.status.update.inprogress = true;

        return await backend.updateQandA(qandaObject)
            .then(response => {
                // 処理成功
                qanda.status.update.success = true;
                qanda.status.update.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                qanda.status.update.success = false;
                qanda.status.update.message = error.message;
            }).finally(() => {
                // 処理終了
                qanda.status.update.inprogress = false;
            });
    }

    /**
     *QandAマスター削除処理
     * @param {String} id ID
     * @returns {Promise} Promiseオブジェクト
     */
    const deleteQandA = async (id) => {
        // メッセージ初期化・処理フラグON
        qanda.status.delete.message = "";
        qanda.status.delete.inprogress = true;

        return await backend.deleteQandA(id)
            .then(response => {
                // 処理成功
                qanda.status.delete.success = true;
                qanda.status.delete.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                qanda.status.delete.success = false;
                qanda.status.delete.message = error.message;
            }).finally(() => {
                // 処理終了
                qanda.status.delete.inprogress = false;
            });
    };

    /**
     *QandAデータ配列クリア処理
     */
    const clearQandAList = async () => {
        qanda.data = [];
        qanda.list = [];
        qanda.status.get.loaded = false;
        qanda.status.get.success = false;
    }

    /**
     *QandAデータ検索処理
     */
    const searchQandAData = () => {
        // 検索対象データを生データに戻す
        qanda.list = qanda.data.slice();

        // ID
        if (searchCondition.id !== "") {
            qanda.list = qanda.list.filter(item =>
                String(item.id).indexOf(searchCondition.id) !== -1
            );
        }

        // カテゴリー
        if (searchCondition.category !== "") {
            qanda.list = qanda.list.filter(item =>
                item.category.indexOf(searchCondition.category) !== -1
            );
        }

        // 質問
        if (searchCondition.question !== "") {
            qanda.list = qanda.list.filter(item =>
                item.question.indexOf(searchCondition.question) !== -1
            );
        }

        // 回答
        if (searchCondition.answer !== "") {
            qanda.list = qanda.list.filter(item =>
                item.answer.indexOf(searchCondition.answer) !== -1
            );
        }
    }

    return {
        qanda,
        qandaObject,         // QandAオブジェクト
        searchCondition,     // 検索条件
        getQandA,            // QandA取得処理
        addQandA,            // QandA追加処理
        updateQandA,         // QandA更新処理
        deleteQandA,         // QandA削除処理
        clearQandAList,      // QandAデータ配列クリア処理
    };
};

export default useQandA;
