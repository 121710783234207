import axios from 'axios';
import router from '@/router';
import store from '@/store';

/** axios設定 */
const config = {
  // APIベースURI
  baseURL: process.env.VUE_APP_API_ENDPOINT, // envファイルを参照する

  // Cookie送信許可
  withCredentials: true,

  // リクエスト中止フラグ
  cancelPreviousRequests: false,

  // リクエストヘッダ
  headers: {
    'Content-Type': 'application/json',
  },
}

/** axiosインスタンス */
const apiClient = axios.create(config);

/**
 * リクエストを管理するマップ
 * Key:エンドポイント(URL)、Value: axios::AbortController
 */
const requestControlMap = new Map();

/**
 * リクエスト管理マップから削除する処理
 * @param {String} url エンドポイントURL
 * @param {Boolean} withAbort 中止処理の有無。デフォルトはFalse。
 */
const removeReqest = (url, withAbort = false) => {
  // 不正なURL指定時は何もせず終了する
  if (!url) {
    return;
  }

  if (requestControlMap.has(url)) {
    if (withAbort) {
      // リクエストを中止する
      requestControlMap.get(url).abort();
    }

    // リクエスト管理マップから削除する
    requestControlMap.delete(url);
  }
}

/** リクエスト前処理 */
apiClient.interceptors.request.use(async request => {
  // 認証要件・状態チェック
  // (要認証ページ＆CSRFトークン無＆APIキー有、または非認証ページ＆認証情報送信する＆CSRFトークン無の場合)
  if ((!router.currentRoute.value.meta.isPublic && !store.getters.getCsrfToken && store.getters.getApiKey) ||
      (router.currentRoute.value.meta.isPublic && router.currentRoute.value.meta.sendCredentials && !store.getters.getCsrfToken)) {
    // 直接リンクorリロード発生時

    // CSRFトークン再取得処理実行（ループ防止のためapiClientは使わない）
    await axios.get("RepublishingToken", config)
      .then(response => {
        const data = response.data;

        // CSRFトークンをstoreに保存する
        store.dispatch("saveCsrfToken", data.csrfToken);

      }).catch(error => {
        // 要認証ページの場合
        if (!router.currentRoute.value.meta.isPublic) {
          // 再取得失敗時は、サインイン画面に遷移させる
          console.error(`ERROR:CSRFトークン再取得失敗,${error.message}`);
          router.push({
            name: "Signin",
            query: { reason: "timeout" }
          });
        }
      });

  } else if (!router.currentRoute.value.meta.isPublic &&                   // 要認証ページ
             !store.getters.getCsrfToken && !store.getters.getApiKey) {    // CSRFトークン無・APIキー無

    // 未サインインのため、サインイン画面に遷移させる
    router.push({ name: "Signin" });
  }

  // ヘッダーに認証情報追加
  request.headers['x-csrf-token'] = store.getters.getCsrfToken;  // CSRFトークン
  request.headers['x-functions-key'] = store.getters.getApiKey;  // FunctionsKey

  if (request?.cancelPreviousRequests && request?.url && !request.signal) {
    // リクエスト管理マップから削除する（既存のリクエストを中止処理を行う）
    removeReqest(request.url, true);

    // 中止処理用のAbortControllerを初期化する
    const abortController = new AbortController();

    // リクエストにAbortControllerのシグナルを割り当てる
    request.signal = abortController.signal;

    // リクエスト管理マップに追加する
    requestControlMap.set(request.url, abortController); // キーをエンドポイントURLとし、値にAbortControllerを格納する
  }

  // コンソールにデバッグメッセージを出力する
  console.debug(`API:リクエスト(${request.url})`, request);

  return request;
});

/** リクエスト後処理 */
apiClient.interceptors.response.use(response => {
  // コンソールにデバッグメッセージを出力する
  console.debug(`API:レスポンス(${response.config.url})`, response);

  // リクエスト管理マップから削除する（正常終了なので中止処理はしない）
  removeReqest(response.config.url, false);

  // ステータスコードによって処理を分岐する
  switch (response.status) {
    case 200: // 成功レスポンス
      return response;
    case 204: // 該当データなし
      // メッセージが存在しないため生成
      response.data = JSON.stringify({message: "該当データがありません"});
      return response;
    default: // エラーとして処理
      return Promise.reject(response.data);
  }
}, error => {
  // コンソールにエラーメッセージを出力する
  console.error(`ERROR:APIコール失敗`, error);

  // リクエスト管理マップから削除する（エラー終了だが、リクエスト処理自体は正常終了なので中止処理はしない）
  removeReqest(error.config?.url, false);

  // バックエンドのエラーメッセージがある場合
  if (error.response?.data) {
    const responseData = error.response.data;
    responseData.statusCode = error.response.status;
    responseData.statusText = error.response.statusText;

    // セッション切れ、強制サインアウトの場合は、サインイン画面に遷移させる
    if (router.currentRoute.value.path !== '/signin' && error.response.status === 401) {
        switch (responseData.reason) {
          case "NotSignedIn":  // クッキー有効期限切れ（？）
          case "TokenExpired": // セッション切れの場合
            // サインイン画面にリダイレクト
            router.push({
              name: "Signin",
              query: {
                reason: "timeout",
                redirect: router.currentRoute.value.path
              }
            });
            // 保存済みデータ削除
            store.dispatch("deleteStoredData");
            break;
          case "EnforceSignout": // 強制サインアウトの場合
            // サインイン画面にリダイレクト
            router.push({
              name: "Signin",
              query: {
                reason: "revocation",
                redirect: router.currentRoute.value.path
              }
            });
            // 保存済みデータ削除
            store.dispatch("deleteStoredData");
            break;
        }
    }
    return Promise.reject(responseData);
  }

  return Promise.reject(error);
});

export default apiClient;