<template>
  <div class="container-xl mx-auto">
    <p class="my-3">
      <router-link to="/support/index">＜サポートメニューへ戻る</router-link>
    </p>

    <h2>サインインに関するよくあるご質問</h2>
    <div class="row mb-3">
      <p class="my-3">
        サインインやアカウントID・パスワードに関するよくあるご質問をご紹介しております。<br>
        当Q&amp;Aにて問題が解決されない場合は、お手数をおかけしますが<a href="/support/contact">サポート窓口</a>までお問い合わせをお願いいたします。<br>
      </p>
    </div>

    <article>
      <h3>仮登録・パスワードリセットのメールが届きません。</h3>
      <p>
        通信状況や処理状況により、メールが届くまで15分程度要する場合があります。それでも届かない場合は以下をご確認ください。
        <ul class="mb-1">
          <li>入力したアカウントID（メールアドレス）で当サービスの利用申請が行われていますか？</li>
          <li>入力したアカウントID（メールアドレス）は正しいですか？</li>
          <li>メールが迷惑フォルダーに振り分けられていませんか？</li>
          <li>ドメイン指定受信などの受信設定で当サービスからのメールがフィルターされていませんか？</li>
        </ul>
        上記をご確認いただいた後もメールを受信できない場合は、お手数ですが<a href="/support/contact">サポート窓口</a>までお問い合わせください。
      </p>
    </article>

    <article>
      <h3>パスワードを忘れてしまいました。パスワードのリセット方法を教えてください。</h3>
      <p>
        以下の内容をご確認ください。<br><br>
        【ご自身でパスワードのリセットを行う場合】<br>
        <router-link to="/forgot_password">パスワードリセット要求画面</router-link>よりパスワードリセットの手続きを行い、新たなパスワードを設定してください。
        詳しくは、［サポートメニュー］の［困ったときは］より「<a href="/support/help#help-01">パスワードを忘れてしまった場合</a>」をご参照ください。<br><br>
        【テナントユーザー管理者にパスワードのリセットを依頼する場合】<br>
        「テナントユーザー管理者」の権限を保持するユーザーにパスワードリセットの依頼を行ってください。
      </p>
    </article>

    <article>
      <h3>サインインパスワードの変更を行うことはできますか？</h3>
      <p>はい。サインインを行い、［ユーザー設定］メニュー内の［パスワード変更］欄にて「新しいパスワード」を入力し「パスワード変更」ボタンを押下してください。<br>
      詳しくは、［サポートメニュー］の［ご利用者向けマニュアル］より「<a href="/support/manual/#UsersPassword">パスワード変更</a>」をご参照ください。</p>
    </article>

    <article>
      <h3>アカウントがロックされてしまいました。アカウントロックを解除してください。</h3>
      <p>
        サインインの際に一定回数以上パスワードを間違えると、10分間アカウントがロックされます。10分後に自動で解除されますので、時間をおいて再度お試しください。<br>
        お急ぎの場合は、<a href="/support/contact">サポート窓口</a>までお問い合わせください。<br>
        アカウント保護のため恒久的にロックされている場合は、自動的にロックが解除されません。お手数ですが<a href="/support/contact">サポート窓口</a>までお問い合わせください。
      </p>
    </article>

    <article>
      <h3>「ユーザー登録時認証コード」とは何ですか？</h3>
      <p>
        ハヤブサバンドのご利用申込みをされたお客様毎に認証用のコードを発行しております。お申込み時にお渡しした「お客様番号発行通知書」に記載しておりますので、ご確認ください。<br>
        詳しくは、担当営業または<a href="/support/contact">サポート窓口</a>までお問い合わせください。
      </p>
    </article>

    <article class="mb-5">
      <h3>「ユーザー登録時認証コード」を再発行してください。</h3>
      <p>
        お手数ですが、<a href="/support/contact">サポート窓口</a>までお問い合わせください。
      </p>
    </article>

    <h2>サービスに関するよくあるご質問</h2>
    <article>
      <h3>ユーザーの追加方法を教えてください。</h3>
      <p>
        ユーザーの追加を行うには「テナントユーザー管理者」の権限を保持するユーザーで［管理者メニュー］の［ユーザーマスター保守画面］よりユーザーの追加を行ってください。<br>
        また、お手元にお客様番号発行通知書がありサインアップ有効期限を迎えていない場合は、「お客様番号」・「ユーザー登録時認証コード」を追加ユーザーに共有し、セルフサインアップを行っていただくことも可能です。
      </p>
    </article>

    <article id="cantsignup">
      <h3>「サービス利用可能ユーザー数の上限に達しています」と表示されサインアップできません。</h3>
      <p>サインアップ可能なユーザー数は利用可能ユーザー数上限により決定されます。新しくユーザーの追加を行うには、以下の内容をご確認ください。</p>
      <p>
        【利用されていないユーザーアカウントが存在する場合】<br>
        現在、自テナントに利用されていないユーザーアカウントが存在する場合は、ユーザーの削除を行ってください。詳しくは、「<a href="#deleteuser">使われていないユーザーの削除方法を教えてください。</a>」をご参照ください。<br><br>
        【全てのユーザーアカウントが利用中の場合】<br>
        現在、自テナントに利用されていないユーザーアカウントが存在しない場合は、ユーザー数の引き上げを行ってください。詳しくは、「<a href="#changeuserlimit">契約ユーザー数（上限数）の変更を行うにはどうすればよいでしょうか？</a>」をご参照ください。
      </p>        
    </article>

    <article id="deleteuser">
      <h3>使われていないユーザーの削除方法を教えてください。</h3>
      <p>
        ユーザーの削除を行うには「テナントユーザー管理者」の権限を保持するユーザーで［管理者メニュー］の［ユーザーマスター保守画面］よりユーザーの削除を行ってください。
      </p>
    </article>

    <article id="changeuserlimit">
      <h3>契約ユーザー数（上限数）の変更を行うにはどうすればよいでしょうか？</h3>
      <p>
        ご契約時のユーザー数の変更を行う場合は、担当営業にご相談いただくか、<a href="/support/supportrequest">お問い合わせフォーム</a>より変更内容をご送信ください。お問い合わせフォームからご依頼いただく場合のお問い合わせ区分は、「利用可能ユーザー数の引き上げ」をご選択ください。
      </p>
    </article>

    <article>
      <h3>契約ユーザー数（上限数）の変更を行うことで課金が発生するのでしょうか？</h3>
      <p>
        いいえ。契約ユーザー数の上限を変更するだけでは課金に影響はありません。ユーザーがサインアップした時点で課金対象として加算されます。<br>
        しかしながら、セキュリティ的にユーザーのサインアップ可能な利用枠が余っている状況は悪意のあるユーザーによる不正利用の温床となりますので、契約ユーザー数上限は適切なユーザー数をご申請ください。
      </p>
    </article>
    
    <article>
      <h3>ユーザー数の変更に伴う課金の仕組みを教えてください。</h3>
      <p>
        【ユーザーの追加時】<br>
        「テナントユーザー管理者」権限を保持するユーザーによるユーザー追加、新規ユーザーのセルフサインアップともに、当月内で行われた場合は、課金対象になります。<br>
        利用可能ユーザー数上限に達していてユーザーの追加ができない場合は、「<a href="#cantsignup">「サービス利用可能ユーザー数の上限に達しています」と表示されサインアップできません。</a>」をご参照ください。
        <br><br>
        【ユーザーの削除時】<br>
        ユーザーを削除した当月は、ユーザー課金対象になります。翌月から削除ユーザーはユーザー課金の対象から除外されます。<br><br>
        【ユーザーの削除復活時】<br>
        当該ユーザーの削除復活処理を行った場合は、課金が再度発生します。<br>
        ※削除ユーザーは一定期間後に自動的に消去されます。<br>        
      </p>
      <a href="/img/support/faq01.png" target="_blank">
        <img class="img" src="/img/support/faq01.png" alt="ユーザー数による課金例">
      </a>
    </article>

    <article>
      <h3>アカウントIDの変更を行うことはできますか？</h3>
      <p>アカウントIDの変更を行うことはできません。</p>
    </article>

    <article>
      <h3>サービスから送信されるメールをアカウントIDとは別のメールアドレスで受信することはできますか？</h3>
      <p>はい。サインインを行い、［ユーザー設定］メニュー内の［ユーザー情報］欄にある「連絡先メールアドレス」にサービスからのメールを受け取るメールアドレスを入力し「更新する」ボタンを押下してください。</p>
    </article>

    <article>
      <h3>請求書発行メールが届きません。</h3>
      <p>以下の内容をご確認ください。</p>
      <ul class="mb-1">
        <li>設定した連絡先メールアドレスが正しいこと</li>
        <li>メールが迷惑メールフォルダーに振り分けられていないこと</li>
        <li>ドメイン指定受信などのメール受信設定で当サービスからのメールがフィルターされていないこと</li>
        <li>管理者メニューのユーザー権限保守画面で［閲覧サービス］の「請求書閲覧者」権限が付与されていること</li>
      </ul>
      <p>上記をご確認いただいた後もメールを受信できない場合は、お手数ですが<a href="/support/contact">サポート窓口</a>までお問い合わせください。</p>
    </article>

    <article>
      <h3>受領請求書一覧での帳票掲載期間はどのくらいでしょうか？</h3>
      <p>当社で発行された請求書・納品書等の閲覧期間は、発行された日から「2年間」です。2年を経過すると、受領請求書一覧から削除され閲覧できなくなります。</p>
    </article>

    <article>
      <h3>パスワード付きPDFをアップロードすることはできますか？</h3>
      <p>アップロードすることは可能です。詳しくは、［サポートメニュー］の［困ったときは］より「<a href="/support/help#help-04">パスワード付きPDFをアップロードしたい場合</a>」をご参照ください。</p>
    </article>

    <article>
      <h3>スマホやタブレットでも利用することはできますか？</h3>
      <p>お客様がお持ちのスマートフォンやタブレットなどのモバイル端末からもハヤブサバンドをご利用可能です。詳しくは、［サポートメニュー］の［ご利用者向けマニュアル］より「<a href="/support/manual#Mobile">モバイル端末でハヤブサバンドを利用する</a>」をご参照ください。</p>
    </article>

    <article>
      <h3>ハヤブサバンドサポート担当者からパスワードを尋ねられました。答えても大丈夫でしょうか？</h3>
      <p>当社では、お客様のセキュリティを重要視しております。そのため、お客様のパスワードを含むアカウント情報を電話、メール、またはその他の通信手段を通じて尋ねることは絶対にありません。
        もし、当社を名乗る者からパスワードの提供を求められた場合は、パスワードを答える前に<a href="/support/contact">サポート窓口</a>までご連絡ください。</p>
    </article>


  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
  position: relative;
  margin-bottom: 20px;
  padding: 0.5rem 0;
  border-bottom: 5px solid;
}

h2:before {
  position: absolute;
  bottom: -0.6rem;
  left: 0rem;
  right: 0rem;
  border-bottom: 1px solid;
  content: '';
}

article {
  margin: 20px 0;
  padding: 0px 20px 0px;
  border: 1px dotted #1e72b1;
  border-radius: 4px;
}

article h3 {
  position: relative;
  padding-top: 0.6rem;
  padding-bottom: 0.28rem;
  margin: 10px auto 20px;
  font-weight: bold;
  font-size: 16pt;
  border-bottom: 5px solid skyblue;
}

article h3:after {
  position: absolute;
  border-bottom: 5px solid #1e72b1;
  content: " ";
  display: block;
  bottom: -4px;
  width: 10%;
}

article p {
  padding: 0 10px;
  font-size: 1.05rem;
}

article svg {
  margin: 20px;
}

h4 {
  font-size: 16px;
  position: relative;
  padding: 0.6em 2rem;
  margin-bottom: 2rem;
  width: auto;
  display: inline-block;
  background: #e0edff;
}

h4::after {
  position: absolute;
  content: '';
  top: 100%;
  left: 30px;
  margin-bottom: 0.2rem;
  border: 15px solid transparent;
  border-top: 15px solid #e0edff;
  width: 0;
  height: 0;
}

/* ボタン */
.button-navy {
  padding: 10px 20px;
  width: 100%;
  color: var(--color-white);
  font-size: 16pt;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: var(--color-navy);
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  display: block;
  cursor: pointer;
}
.button-navy:hover {
  background-color: var(--color-navy-lighten);
  transition: var(--anim-speed);
}
.button-navy:active {
  background-color: var(--color-navy-darken);
}
.img{
  width: 60%;
  margin: 0 20% 3%;
  border: 1px solid #666;
}

.ti {
  text-indent: -1.3em;
  margin-left: 1.5em;
}
</style>

<script>
export default {
  name: 'SpFAQ',
  components: {
  },
  setup() {
    return {
    }
  },
}
</script>