<template>
  <div class="m-3 d-none d-sm-block">
    <div class="card mb-3">
      <div class="card-body notice-list">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" style="color:#34a9dd" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
          </svg>
          <p>お知らせ</p>
        </div>
        <ol>
          <li v-for="(item, index) in notice.list" :key="index">
            <p>{{ item.startDateTime?.split(' ')[0] || item.startDate?.replace(/-/g, "/") }}</p>
            <p>
              <span v-if="item.importance === 1" class="mx-1 badge bg-danger">重要</span>
              <span v-if="item.area !== 0 && item.confirmation === 0 && item.contents !== ''" class="mx-1 badge rounded-pill text-bg-warning">未読</span>
              <span v-if="item.indication === 2 && item.confirmation === 0" class="mx-1 badge rounded-pill text-bg-warning">未読</span>
              <span v-if="item.indication === 2" title="クリックでPDFファイルが開きます" @click="downloadNoticePDF(item)" class="link-cursor"><u>{{ item.title }}</u></span>
              <span v-else-if="item.contents === ''">{{ item.title }}</span>
              <span v-else @click="linkMode = true, selectNotice(item)" class="link-cursor"><u>{{ item.title }}</u></span>
            </p>
            <!-- リンクプレビュー ダイアログフォーム -->
            <div v-if="linkMode && item.creationDate === noticeObject.creationDate && item.history === noticeObject.history"
                 class="dialog-overlay-link" @click="linkMode = false">
              <div class="card mb-3" @click.stop="false">
                <div class="dialog-form-link">
                  <p class="datetime">{{ noticeObject.startDateTime}}</p>
                  <h4>{{ noticeObject.title }}</h4>
                  <br>
                  <p><span v-html="noticeObject.contents"></span></p>
                </div>
                <div class="card-footer d-flex justify-content-end">
                  <button type="button" class="ms-2 btn btn-dark" @click="linkMode = false">閉じる</button>
                </div>
              </div>
            </div>
          </li>
          <li v-if="notice.length === 0">
            <p v-if="notice.status.get.inprogress"><span class="spinner-border text-primary" title="読み込んでいます..."></span></p>
            <p v-else-if="notice.status.get.message !== ''" class="text-danger">{{ "エラー: " + notice.status.get.message }}</p>
            <p v-else-if="notice.status.get.loaded" >現在、お知らせはありません</p>
          </li>
        </ol>
      </div><!-- card-body -->
    </div><!-- card -->
  </div>

  <!-- スマホ版 お知らせ -->
  <div class="mb-3 card-sm d-sm-none">
    <div class="card-body notice-list-mobile">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" style="color:#34a9dd" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
        </svg>
        <p>お知らせ</p>
      </div>
      <ol>
        <li v-for="(item, index) in notice.list" :key="index">
          <p>{{ item.startDateTime?.split(' ')[0] || item.startDate?.replace(/-/g, "/") }}</p>
          <p>
            <span v-if="item.importance === 1" class="mx-1 badge bg-danger">重要</span>
            <span v-if="item.area !== 0 && item.confirmation === 0 && item.contents !== ''" class="mx-1 badge rounded-pill text-bg-warning">未読</span>
            <span v-if="item.indication === 2 && item.confirmation === 0" class="mx-1 badge rounded-pill text-bg-warning">未読</span>
            <span v-if="item.indication === 2" title="クリックでPDFファイルが開きます" @click="downloadNoticePDF(item)" class="link-cursor"><u>{{ item.title }}</u></span>
            <span v-else-if="item.contents === ''">{{ item.title }}</span>
            <span v-else @click="linkMode = true, selectNotice(item)" class="link-cursor"><u>{{ item.title }}</u></span>
          </p>
          <!-- リンクプレビュー ダイアログフォーム -->
          <div v-if="linkMode && item.creationDate === noticeObject.creationDate && item.history === noticeObject.history"
                class="dialog-overlay-link" @click="linkMode = false">
            <div class="card mb-3" @click.stop="false">
              <div class="dialog-form-link">
                <p class="datetime">{{ noticeObject.startDateTime}}</p>
                <h4>{{ noticeObject.title }}</h4>
                <br>
                <p><span v-html="noticeObject.contents"></span></p>
              </div>
              <div class="card-footer d-flex justify-content-end">
                <button type="button" class="ms-2 btn btn-dark" @click="linkMode = false">閉じる</button>
              </div>
            </div>
          </div>
        </li>
        <li v-if="notice.length === 0">
          <p v-if="notice.status.get.inprogress"><span class="spinner-border text-primary" title="読み込んでいます..."></span></p>
          <p v-else-if="notice.status.get.message !== ''" class="text-danger">{{ "エラー: " + notice.status.get.message }}</p>
          <p v-else-if="notice.status.get.loaded" >現在、お知らせはありません</p>
        </li>
      </ol>
    </div><!-- card-body -->
  </div><!-- card -->
</template>

<style scoped>
p {
  margin: 0;
}

/** お知らせ一覧Div */
.notice-list {
  padding: 0;
  font-size: 0.9rem;
  display: flex;
}

/** お知らせ一覧 アイコンDiv */
.notice-list > div {
  margin: auto;
  padding: 10px 30px;
}
.notice-list > div svg {
  margin: 0 auto;
  display: block;
}
.notice-list > div p {
  padding-top: 4px;
  font-weight: bold;
  word-break: keep-all;
}

/** お知らせ一覧 リスト */
.notice-list ol {
  margin: 0;
  padding: 0;
  max-height: 100px;
  list-style-type: none;
  flex-grow: 1;
  overflow-y: scroll;
}

/** お知らせ一覧 リストアイテム */
.notice-list ol li {
  margin: 4px 10px 4px 0;
  padding: 4px;
  display: flex;
}
.notice-list ol li:not(:last-child) {
  /** 最後のアイテム以外に下線を引く */
  border-bottom: 1px solid var(--border-gray);
}

/** お知らせ一覧 リストアイテム内P要素 */
.notice-list ol li p:first-child:not(:last-child) {
  /** 日付 */
  margin-right: 8px;
}

/** 読み込み中等メッセージ（li内のP要素が1つのみ）は
    中央に表示させる */
.notice-list ol li p:first-child:last-child {
  margin: auto;
}
.notice-list ol li:only-child {
  height: 90%;
}

/** メッセージ内のバッジの垂直位置を調節 */
.notice-list ol li span.badge {
  vertical-align: text-bottom;
}

/** dialog-form */
.dialog-form-link {
  padding: 20px;
  margin: 0 auto;
  width: 60vw;
  box-shadow: 0 0 12px #00000055;
  z-index: 10;
  white-space: pre-line;
  overflow-y: scroll;
}
.dialog-overlay-link .card {
  margin: 0 0.5px;
  padding: 0;
  max-height: 90vh;
  box-sizing: border-box;
}

.dialog-overlay-link {
  padding: 10px 0 0;
  margin: 0;
  width: 100%;
  background-color: #000000aa;
  position: fixed;
  inset: 0;
  margin: auto;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-cursor{
  cursor: pointer;
}

h4{
  clear:both;
  margin-bottom: 30px;
}
.datetime{
  margin-bottom: 10px;
  float: right;
}

/** スマホ版 お知らせ一覧Div */

/* 576サイズ以下の場合 */
@media (max-width: 576px) {
  .card-sm {
  background-color:#ffffff; 
  border-radius: 5px;
  border: solid 1px #b6b6b58c;
  }
}
.notice-list-mobile {
  padding: 0;
  font-size: 0.7rem;
  display: flex;
}

/** スマホ版 お知らせ一覧 アイコンDiv */
.notice-list-mobile > div {
  margin: auto;
  padding: 10px 10px;
}
.notice-list-mobile > div svg {
  margin: 0 auto;
  display: block;
}
.notice-list-mobile > div p {
  padding-top: 4px;
  font-weight: bold;
  word-break: keep-all;
}

/** スマホ版 お知らせ一覧 リスト */
.notice-list-mobile ol {
  margin: 0;
  padding: 0;
  max-height: 100px;
  list-style-type: none;
  flex-grow: 1;
  overflow-y: scroll;
}

/** スマホ版 お知らせ一覧 リストアイテム */
.notice-list-mobile ol li {
  margin: 4px 10px 4px 0;
  padding: 4px;
  display: flex;
}
.notice-list-mobile ol li:not(:last-child) {
  /** 最後のアイテム以外に下線を引く */
  border-bottom: 1px solid var(--border-gray);
}

/** スマホ版 お知らせ一覧 リストアイテム内P要素 */
.notice-list-mobile ol li p:first-child:not(:last-child) {
  /** 日付 */
  margin-right: 8px;
}

/** 読み込み中等メッセージ（li内のP要素が1つのみ）は
    中央に表示させる */
.notice-list-mobile ol li p:first-child:last-child {
  margin: auto;
}
.notice-list-mobile ol li:only-child {
  height: 90%;
}

/** メッセージ内のバッジの垂直位置を調節 */
.notice-list-mobile ol li span.badge {
  vertical-align: text-bottom;
}
</style>

<script>
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import useNotice from "@/composable/notice";
import utilities from "@/services/utilities.js"

export default defineComponent({
  name: "NoticeList",
  props: {
    /** 表示画面 (サインイン画面=0, ダッシュボード=1) */
    displayArea: {
      type: Number,
      default: -1,
      required: true,
    },
    /** プレビュー用データ */
    previewData: {
      type: Array,
      default: () => []
    },
  },
  setup(props) {
    // vuex::store
    const store = useStore();

    // 共通ユーティリティ読み込み
    const util = utilities;

    // notice.jsの関数を読み込み
    const { notice, noticeObject, getNoticeData,getNoticePDF, getAnnounce, updateReadState } = useNotice();

    // プレビュー用データの有無
    const hasPreviewData = props.previewData.length > 0;

    // 「本文」プレビューモード（true:表示, false:未表示）
    const linkMode = ref(false);

    // お知らせ配信データ 選択行データセット処理
    const selectNotice = (notice) => {
      // データをセット
      noticeObject.title = notice.title;                  // タイトル
      noticeObject.contents = notice.contents;            // 内容
      noticeObject.startDateTime = notice.startDateTime;  // 公開開始日時
      noticeObject.creationDate = notice.creationDate;    // 作成日時
      noticeObject.history = notice.history;              // 履歴番号

      if (notice.area !== 0 && notice.confirmation === 0 && notice.contents !== ""){
        // 既読に更新
        updateReadState(store.getters.getOwnInfo.accountId, notice.creationDate,notice.history)

        // メモリー上も既読に更新する
        notice.confirmation = 1;
      } 
    };

    // お知らせ一覧 高さ変更処理
    const resizeHeight = () => {
      try {
        document.querySelector(".notice-list ol").style.maxHeight = "initial",
        document.querySelector(".notice-list ol").style.height = (document.querySelector(".info").clientHeight - 30) + "px"
      } catch (_e) {
        // do nothing
      }
    }

    // お知らせPDFダウンロード処理
    const downloadNoticePDF = async (notice) => {
      // プレビューモードの場合、お知らせPDFは内部で表示する
      if (hasPreviewData && notice.file !== "") {
        const link = document.createElement("a");       // リンク生成
        link.href = URL.createObjectURL(notice.file);   // オブジェクトURLをセット
        link.target = "_blank";                         // 新しいタブで開く
        link.rel = "noopener noreferrer";               // セキュリティ対策
        link.click();                                   // クリック実行
        URL.revokeObjectURL(link.href);                 // オブジェクトURLを解放
      } else {
        // ダウンロード実行（承認モード時はダウンロードせず開くのみ）
        await getNoticePDF(notice).then(() => {
          // 既読に更新
          updateReadState(store.getters.getOwnInfo.accountId, notice.creationDate,notice.history);

          // メモリー上も既読に更新する
          notice.confirmation = 1;
        }).catch(() => {
          // nothing
        });
      }
    }
    
    // 初期化処理
    const initialize = () => {
      if (hasPreviewData) {
        // プレビューデータがある場合はそれをセット
        notice.list = props.previewData;
      } else {
        // ない場合、お知らせデータをバックエンドより取得
        getNoticeData(props.displayArea);
      }
    };

    // サインイン画面の場合
    if (props.displayArea === 0) {
      // サインイン画面用お知らせ取得
      getAnnounce();

      // お知らせ一覧の高さ変更を実施
      onMounted(() => {
        resizeHeight();                                   // 初回
        window.addEventListener("resize", resizeHeight);  // 画面サイズ変更イベント
      });
      onBeforeUnmount(() => {
        window.removeEventListener("resize", resizeHeight);  // 画面サイズ変更イベント
      });
    } else {
      // それ以外の場合（ダッシュボード等）
      // ユーザー情報取得状況をチェック
      if (store.state.fetched) {
        // ユーザー情報取得済の場合は初期化処理実行
        initialize();
      } else {
        // ユーザー情報未取得の場合、取得状況を監視
        const stopHandle = store.watch(state => state.fetched, fetched => {
          if (fetched) {
            // ユーザー情報取得完了したら初期化処理実行
            initialize();
            // 監視を停止
            stopHandle();
          }
        });
      }
    }

    return {
      util,                 // ユーティリティ
      notice,               // お知らせオブジェクト
      noticeObject,         // お知らせデータオブジェクト
      linkMode,
      downloadNoticePDF,    // PDFダウンロード処理
      selectNotice,         // お知らせ配信データ選択処理
    };
  }
});
</script>
