<template>
  <div class="signup container-xl mx-auto">
    <!-- ローディングスピナー -->
    <Loading v-model:active="status.inprogress" :can-cancel="false" color="var(--color-blue)" />

    <!-- フォーム -->
    <form @submit.prevent="changePassword" v-if="!status.executed" class="mb-3"><!-- 入力画面 -->
      <div v-if="reason === 'regist'">
        <h2>本登録</h2>
        <p class="my-3">下記の項目を入力し、「登録を完了する」をクリックしてください。</p>
      </div>
      <div v-else-if="reason === 'forgot'">
        <h2>パスワード再設定</h2>
        <p class="my-3">下記の項目を入力し、「パスワードを再設定する」をクリックしてください。</p>
      </div>
      <div v-else-if="reason === 'required'">
        <h2>パスワード再設定</h2>
        <p class="my-3">パスワードの再設定が必要です。<br>下記の項目を入力し、「パスワードを再設定する」をクリックしてください。</p>
      </div>
      <div v-else>
        <h2>エラー</h2>
        <p class="my-3">正しいリクエストではありません。</p>
      </div>

      <table class="mb-3" v-if="['regist', 'forgot', 'required'].includes(reason)">
        <tr>
          <th>アカウントID（メールアドレス）</th>
          <td>
            <input type="text" class="wide" :value="accountId" autocomplete="username" maxlength="100" tabindex="-1" readonly>
          </td>
        </tr>
        <tr v-if="reason === 'regist'">
          <th>仮パスワード</th>
          <td>
            <input type="password" class="wide" v-model="codeOrPassword" autocomplete="current-password" required>
            <div class="form-text">※仮パスワードは、お送りしたメールに記載されています。</div>
          </td>
        </tr>
        <tr v-else-if="reason === 'forgot'">
          <th>セキュリティコード</th>
          <td>
            <input type="text" class="wide" v-model="codeOrPassword" autocomplete="one-time-code" minlength="8" maxlength="8" required>
            <div class="form-text">※セキュリティコードは、お送りしたメールに記載されています。</div>
          </td>
        </tr>
        <tr v-else-if="reason === 'required'">
          <th>現在のパスワード</th>
          <td>
            <input type="password" class="wide" v-model="codeOrPassword" autocomplete="current-password" required>
          </td>
        </tr>
        <tr>
          <th>新しいパスワード</th>
          <td>
            <input type="password" class="wide" v-model="password" autocomplete="new-password" required>
            <div class="form-text" v-show="passwordRules.minimum > 0">
              ※新しいパスワードは、以下を確認し入力してください。<br>
              【パスワード設定ルール】<br>
              ・{{ passwordRules.minimum }}～{{ passwordRules.maximum }}文字であること。<br>
              ・「英小文字」{{ passwordRules.requiredUpper ? "「英大文字」" : "" }}「数字」{{ passwordRules.requiredSign ? "「記号」" : "" }}を含んでいること。<br>
              ・過去{{ passwordRules.historyLimit }}世代前まで利用していたパスワードではないこと。
            </div>
          </td>
        </tr>
        <tr>
          <th>新しいパスワード（確認）</th>
          <td>
            <input type="password" class="wide" v-model="passwordConfirm" autocomplete="new-password" required>
          </td>
        </tr>
      </table>

      <!-- エラーメッセージ -->
      <div v-if="status.isError" class="alert alert-danger" role="alert">
        <template v-if="status.isInvalidPolicy">
          <b>新しいパスワードは要件を満たしていません。以下のパスワード設定ルールに従って再入力してください。</b><br>
          【パスワード設定ルール】<br>
          ・{{ passwordRules.minimum }}～{{ passwordRules.maximum }}文字であること。<br>
          ・「英小文字」{{ passwordRules.requiredUpper ? "「英大文字」" : "" }}「数字」{{ passwordRules.requiredSign ? "「記号」" : "" }}を含んでいること。<br>
          ・過去{{ passwordRules.historyLimit }}世代前まで利用していたパスワードではないこと。
        </template>
        <template v-else>
          {{ status.message }}
        </template>
      </div>

      <button v-if="reason === 'regist'" type="submit" class="button-navy">登録を完了する</button>
      <button v-else-if="reason === 'forgot'" type="submit" class="button-navy">パスワードを再設定する</button>
      <button v-else-if="reason === 'required'" type="submit" class="button-navy">パスワードを再設定する</button>

      <router-link to="/signin" class="button">サインイン画面に戻る</router-link>

    </form>
    <div v-else><!-- 完了画面 -->

      <div v-if="reason === 'regist'">
        <h2>本登録を完了しました</h2>
        <p>アカウントID「{{ accountId }}」の本登録が完了しました。</p>
      </div>
      <div v-else>
        <h2>パスワードを再設定しました</h2>
        <p>アカウントID「{{ accountId }}」のパスワード再設定が完了しました。</p>
      </div>

      <button type="button" class="button-navy" @click="authentication">ダッシュボード画面へ</button>

    </div>
  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
}

.signup {
  overflow: auto;
}

/* 入力フォーム */
form input:not([type="radio"], [type="checkbox"], [type="date"], [type="text"]:read-only) {
  padding: 0.25rem;
  background-color: #ffe6e6;
}

form input:not(:read-only),
form select,
form textarea {
  color: var(--text-primary);
  font-family: var(--font-family) !important;
  font-size: 100%;
  border: 1px solid var(--border-gray);
  background-color: var(--background-primary);
}

form input:read-only {
  border: none;
  background-color: transparent !important;
  outline: none;
}

form select option {
  color: var(--text-primary);
  background-color: var(--background-primary);
}

form input:focus:not(:read-only),
form select:focus,
form textarea:focus,
input.indicate-focus:focus {
  border: 1px solid var(--color-blue);
  background-color: var(--hover) !important;
  box-shadow: 0 0 8px -4px var(--color-blue);
  outline: none;
}

form .validate input:invalid:not(:read-only),
form .validate select:invalid,
form .validate textarea:invalid {
  color: var(--color-red);
  border: 1px solid var(--color-red);
  background-color: var(--background-error);
  box-shadow: 0 0 8px -4px var(--color-red);
  outline: none;
}

form input:read-only:disabled {
  border: none;
  background-color: transparent !important;
}

input.wide {
  width: 90%;
}

/* テーブル */
table {
  width: 100%;
  border: 1px solid #ccc;
}
table th {
  padding: 0.75rem 1.25rem;
  font-weight: normal;
  border-bottom: 1px solid #ccc;
  background-color: #eee;
}
table td {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #ccc;
}

/* 個人情報の取り扱いについて */
ul {
  padding: 0.75rem 1.75rem;
  border: 1px solid #ccc;
}

/* ボタン */
button,
.button {
  margin: 10px auto;
  padding: 0.4rem 0.6rem;
  width: 50%;
  color: var(--text-primary);
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: var(--color-gray);
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  display: block;
  cursor: pointer;
}
button:hover,
.button:hover {
  background-color: var(--color-gray-lighten);
}
button:active,
.button:active {
  background-color: var(--color-gray-darken);
}
button:disabled,
.button:disabled {
  color: var(--text-secondary) !important;
  background-color: var(--color-gray) !important;
  cursor: not-allowed;
}
button.button-navy {
  color: var(--color-white);
  background-color: var(--color-navy);
}
button.button-navy:hover {
  background-color: var(--color-navy-lighten);
}
button.button-navy:active {
  background-color: var(--color-navy-darken);
}
</style>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useReCaptcha } from 'vue-recaptcha-v3';
import Loading from "vue-loading-overlay";
import utilities from "@/services/utilities"
import backend from "@/services/backendApi";

export default defineComponent({
  name: "ResetPassword",
  components: {
    Loading
  },
  setup() {
    // vuex::store
    const store = useStore();
    // vue-router::route
    const route = useRoute();
    // vue-router::route
    const router = useRouter();

    // 共通ユーティリティ読み込み
    const util = utilities;

    // reCAPTCHA
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    // 処理ステータス
    const status = reactive({
      inprogress: false,  // 処理状況
      message: "",        // 結果メッセージ
      isError: false,     // エラーか否か
      executed: false,    // 実行済みか否か
    });

    // リセット対象アカウントの情報（URLから取得）
    const accountId = route.params.accountId; // アカウントID
    const urlHash = route.params.hash;           // ハッシュ

    // リセット理由
    const reason = route.meta.reason;

    // 入力項目
    const codeOrPassword = ref("");  // セキュリティコードor現在のパスワード
    const password = ref("");        // 新しいパスワード
    const passwordConfirm = ref(""); // 確認用

    // パスワード要件
    const passwordRules = reactive({
      minimum: -1,
      maximum: -1,
      requiredUpper: false,
      requiredSign: false,
    });

    // パスワード再設定処理
    const changePassword = async () => {
      // ステータスを初期化し、処理中に変更
      status.inprogress = true;
      status.message = "";
      status.isError = false;
      status.isInvalidPolicy = false;

      // パスワード入力一致チェック
      if (password.value !== passwordConfirm.value) {
        // 不一致
        status.inprogress = false;
        status.message = "新しいパスワードと確認用パスワードが一致しません";
        status.isError = true;
        return;
      }

      // reCAPTCHAトークン取得
      await recaptchaLoaded();
      const recaptchaToken = await executeRecaptcha('password_change');

      // API呼び出し
      backend.changePassword(route.meta.reason, accountId, password.value, urlHash, codeOrPassword.value, recaptchaToken)
        .then(async () => {
          // 成功
          // 実行済に変更
          status.executed = true;
        })
        .catch(error => {
          // 失敗
          switch (error.message) {
            case "入力されたデータ内容にエラーがあります。エラー詳細=新しいパスワードは要件を満たしていません。":
                status.isInvalidPolicy = true;
                break;
            default:
                status.message = error.message;
                break;
          }
          status.isError = true;
        }).finally(() => {
          // 処理中状態OFF
          status.inprogress = false;
        });
    }

    // 認証＆ダッシュボードリダイレクト処理
    const authentication = async () => {
      // 処理中状態ON
      status.inprogress = true;

      // reCAPTCHAトークン取得
      await recaptchaLoaded();
      const recaptchaToken = await executeRecaptcha('login');

      // 認証情報
      const payload = {
        accountId: accountId,
        password: password.value,
        recaptchaToken: recaptchaToken
      };

      // 認証実行
      await store.dispatch("doSignin", payload).then(async () => {
        // サインインしたユーザの情報を取得し保存
        await store.dispatch("fetchOwnInfo").then(() => {
          //ダッシュボード表示
            router.push({ name: "Dashboard" });
          }).catch(error => {
            // エラーメッセージ表示
            status.message = error.message;
            status.isError = true;
          });
      }).catch(error => {
        // エラーメッセージ表示
        status.message = error.message;
        status.isError = true;
      }).finally(() => {
        // 処理中状態OFF
        status.inprogress = false;
      });
    };

    // 初期化処理
    const initialize = async () => {
      status.inprogress = true;

      // パスワード要件取得
      await backend.getPasswordRule().then((response) => {
        // 要件をセット
        passwordRules.minimum = response.data.content.minimum;
        passwordRules.maximum = response.data.content.maximum;
        passwordRules.requiredUpper = response.data.content.requiredUpper;
        passwordRules.requiredSign = response.data.content.requiredSign;
        passwordRules.historyLimit = response.data.content.historyLimit;
      }).catch(() => {
        // do nothing
      });

      status.inprogress = false;
    }

    // 初期化実行
    initialize();

    return {
      util,                     // ユーティリティ
      status,                   // ステータス
      accountId,                // リセット対象アカウントID
      changePassword,           // パスワード変更処理
      authentication,           // 認証処理
      codeOrPassword,           // セキュリティコードor現在のパスワード
      password,                 // 新しいパスワード
      passwordConfirm,          // 新しいパスワード（確認）
      reason,                   // リセット理由
      passwordRules,            // パスワード要件
    };
  },
  mounted() {
    // reCAPTCHAバッジ表示
    setTimeout(() => {
      this.$recaptchaInstance.value.showBadge();
    }, 1000);
  },
  unmounted() {
    // reCAPTCHAバッジ非表示
    this.$recaptchaInstance.value.hideBadge();
  }
});
</script>
