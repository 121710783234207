<template>
  <div class="d-none d-sm-block">
    <ul class="my-2 nav nav-tabs" >
      <li
      v-if="isStorable && $store.getters.getOwnPermissions.canAccessEvidences"
      class="nav-item"
    >
        <router-link class="nav-link evidence" to="/evidence">
          【保管】保管帳票一覧
        </router-link>
      </li>

      <li
      v-if="isBrowsable && $store.getters.getOwnPermissions.canAccessInvoices"
      class="nav-item"
    >
        <router-link class="nav-link invoice" to="/invoice">
        {{ isDeliverable ? "【発行】発行請求書一覧" : "【閲覧】受領請求書一覧" }} <!-- Notice: 暫定的な表示切替 -->
        </router-link>
      </li>

      <li
      v-if="isStorable && $store.getters.getOwnPermissions.canAccessEntryAgentMenu && ($store.getters.getIsHQ || $store.getters.getOwnPermissions.isAdministrator)"
      class="nav-item"
    >
        <router-link class="nav-link agent" to="/agent">
          【代行】帳票一覧
        </router-link>
      </li>

      <li
      class="nav-item ms-auto"
    >
        <router-link class="nav-link" to="/settings">
          ユーザー設定
        </router-link>
      </li>

      <li
      v-if="$store.getters.getOwnPermissions.canAccessAdminMenu"
      class="nav-item"
    >
        <router-link class="nav-link" to="/maintenance">
          管理者メニュー
        </router-link>
      </li>

      <li
      class="nav-item"
    >
        <button class="nav-link" id="signoutButton" @click="signoutConfirm">サインアウト</button>
      </li>
    </ul>
  </div>
  <ConfirmDialog
    id="SignoutConfirmDialog"
    content="サインアウトしますか？"
    buttons="YesNo"
    :positiveAction="signout"
    :canCancelable="true"
  />
</template>

<style scoped>
/** 請求書 */
a.invoice.active,
a.invoice.active:hover {
  background-color: #e7f1ff;
}
a.invoice:hover {
  background-color: #deecff;
}

/** 帳票保管 */
a.evidence {
  color: #7c2900;
}
a.evidence:hover {
  color: #501b00;
}
a.evidence.active {
  color: #574e49;
}

a.evidence.active,
a.evidence.active:hover {
  background-color: #ffefe7;
}
a.evidence:hover {
  background-color: #ffe8dc;
}

/**代行入力 */
a.agent {
  color: #525E42;
}
a.agent:hover {
  background-color: #c6e0b3;
}
a.evidence.active {
  color: #574e49;
}
a.agent.active,
a.agent.active:hover {
  background-color: #c6e0b3;
}
</style>

<script>
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import backend from "@/services/backendApi";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default defineComponent({
  name: "NavMenu",
  components: {
    ConfirmDialog,
  },
  setup() {
    /** vuex::store */
    const store = useStore();
    /** vue-router::router */
    const router = useRouter();

    /** サインアウト確認ダイアログ表示 */
    const signoutConfirm = () => {
      new Modal(document.getElementById('SignoutConfirmDialog')).show();
    };

    /** サインアウト処理 */
    const signout = async () => {
      // サインアウトボタンを処理中に変更
      const signoutButton = document.getElementById("signoutButton");
      signoutButton.disabled = true;
      const buttonString = signoutButton.textContent;
      signoutButton.textContent = "サインアウト中...";

      // Cookie消去API呼び出し(成功の有無は問わない)
      await backend.doSignout()
        .finally(() => {
          // 保存済みデータ削除
          store.dispatch("deleteStoredData");

          // サインインページにリダイレクト
          router.push({
            name: "Signin",
            query: { reason: "signout" }
          });
          // サインアウトボタンをもとに戻す
          signoutButton.textContent = buttonString;
        });
    }

    /** 帳票保管契約状況(デフォルトテナントの契約状況を確認する) */
    const isBrowsable = computed(() => {
      return store.getters.getDefaultTenant?.isBrowsable ?? false;
    });

    /** 帳票保管契約状況(デフォルトテナントの契約状況を確認する) */
    const isStorable = computed(() => {
      return store.getters.getDefaultTenant?.isStorable ?? false;
    });

    /** 請求書発行契約状況(デフォルトテナントの契約状況を確認する) */
    const isDeliverable = computed(() => {
      return store.getters.getDefaultTenant?.isDeliverable ?? false;
    });

    return {
      signout,
      signoutConfirm,
      isBrowsable,    // 請求書閲覧契約状況
      isStorable,     // 帳票保管契約状況
      isDeliverable,  // 請求書発行契約状況
    };
  },
});
</script>
