import { reactive } from 'vue';
import backend from '@/services/backendApi';
import store from '@/store/index.js';
import useEvidenceTag from "@/composable/evidenceTag";

const useUserInfo = () => {
    const userInfo = reactive({
        user: {                 // ユーザー情報
            accountId: '',
            name: '',
            nameKana: '',
            mailAddress: '',
            startupPage: 0,
            tags: [],
            fileSizeThreshold: 0,
        },
        status: {
            inprogress: true,   // 処理状況
            message: '',        // APIレスポンスメッセージ
            isError: false,     // APIレスポンスエラー有無
        },
        evidenceTags: [],   // デフォルトユーザータグ
    });

    /** ユーザー情報フォーム初期化処理 */
    const initialize = async () => {
        // ユーザー情報をセット
        setUserInfo(store.getters.getOwnInfo); 

        // evidenceTag.jsの関数を読み込み
        const { evidenceTag, getEvidenceTag } = useEvidenceTag();

        // 帳票アップロード権限がある場合
        if (store.getters.getDefaultTenant?.isStorable &&
            store.getters.getOwnPermissions.canUploadEvidence) {
            // 帳票タグマスターが未取得＆取得成功ではない場合
            if (!evidenceTag.status.get.loaded && !evidenceTag.status.get.success) {
                // 既存の通知を削除
                store.dispatch("removeNotification", "getEvidenceTag");

                // 帳票タグマスターを取得
                getEvidenceTag().then(() => {
                    if (evidenceTag.status.get.success) {
                        // 成功の場合
                        userInfo.evidenceTags = evidenceTag.data.slice();
                    } else {
                        // 失敗の場合
                        store.dispatch("addNotification", {
                            id: "getEvidenceTag",
                            title: "帳票タグマスター取得失敗",
                            message: "タグマスターを正しく取得出来ないため処理を続行できません。"
                                + "画面を再読み込みしてください。（" + evidenceTag.status.get.message + "）",
                            type: "danger"
                        });
                    }
                });
            }
        }

        // 処理中フラグをOFF
        userInfo.status.inprogress = false;
    }

    /** ユーザーオブジェクト代入処理 */
    const setUserInfo = userObj => {
        userInfo.user.accountId = userObj.accountId;                 // アカウントID
        userInfo.user.name = userObj.name;                           // 氏名（漢字）
        userInfo.user.nameKana = userObj.nameKana;                   // 氏名（カナ）
        userInfo.user.mailAddress = userObj.mailAddress;             // 連絡先メールアドレス
        userInfo.user.startupPage = userObj.startupPage;             // スタートアップページ
        userInfo.user.tags = userObj.tags?.map(i => i?.id) ?? [];    // デフォルトユーザータグ
        userInfo.user.fileSizeThreshold = userObj.fileSizeThreshold; // アップロードファイルサイズ閾値
    }

    const updateUserInfo = async () => {
        // メッセージを初期化
        userInfo.status.message = "";
        userInfo.status.isError = false;

        // 処理中フラグON
        userInfo.status.inprogress = true;

        // API呼び出し
        await backend.updateUser(userInfo.user).then(response => {
            const userObj = { ...userInfo.user };

            // ユーザーデフォルトタグとして選択されたタグをセットする
            // ※タグIDの配列からタグオブジェクトの配列に変換する
            userObj.tags = userInfo.evidenceTags.filter(i => userInfo.user.tags.includes(i.id));

            // storeのユーザー情報を更新
            store.dispatch("updateStoredOwnInfo", userObj);

            // 完了メッセージをセット
            userInfo.status.message = response.data.message;
        }).catch(error => {
            console.error(error);
            // エラーメッセージをセット
            userInfo.status.message = error.message;
            userInfo.status.isError = true;
        }).finally(() => {
            // 処理中フラグをOFF
            userInfo.status.inprogress = false;
        });
    }

    return {
        userInfo,
        initialize,
        updateUserInfo,
    };
};

export default useUserInfo;