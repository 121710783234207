<template>
  <!-- ローディングスピナー -->
  <Loading v-model:active="status.inprogress" :can-cancel="false" color="var(--color-blue)" />
  <!-- ローディングスピナー ここまで -->
  <div class="m-3">
    <form @submit.prevent="updatePassword" class="align-items-center">
      <a href="#" class="arrow mobile-back d-sm-none mb-3" @click="$router.back()">戻る</a>
      <h2>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-key" viewBox="0 0 16 16">
          <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
          <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
        パスワード変更
      </h2>
      <!-- エラーメッセージ -->
      <div v-if="status.message" v-bind:class="[status.isError ? 'alert-danger' : 'alert-success']" class="alert" role="alert">
        <span v-html="status.message"></span>
      </div>
      <!-- エラーメッセージ ここまで -->
      <div class="container">
        <p v-show="passwordRules.minimum > 0">
          ※パスワードは、以下を確認し入力してください。<br>
              ・{{ passwordRules.minimum }}～{{ passwordRules.maximum }}文字であること。<br>
              ・「英小文字」{{ passwordRules.requiredUpper ? "「英大文字」" : "" }}「数字」{{ passwordRules.requiredSign ? "「記号」" : "" }}を含んでいること。<br>
              ・過去{{ passwordRules.historyLimit }}世代前まで利用していたパスワードではないこと。
        </p>
        <div class="row mb-2">
          <!-- アクセシビリティ対応のためをセット -->
          <div class="col-lg-3 col-xl-2">
            <label class="col-form-label">アカウントID</label>
          </div>
          <div class="col-lg-5 col-xl-5">
            <input type="text" name="accountId" class="w-100" style="vertical-align: sub;" :value="$store.getters.getOwnInfo.accountId" autocomplete="username" tabindex="-1" readonly>
          </div>
          <div class="col-lg-4 col-xl-5">
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-3 col-xl-2">
            <label class="col-form-label">新しいパスワード</label>
          </div>
          <div class="col-lg-5 col-xl-5">
            <input type="password" class="form-control" v-model="password" autocomplete="new-password" required>
          </div>
          <div class="col-lg-4 col-xl-5">
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-3 col-xl-2">
            <label class="col-form-label">新しいパスワード<br>（確認）</label>
          </div>
          <div class="col-lg-5 col-xl-5">
            <input type="password" class="form-control" v-model="passwordConfirm" autocomplete="new-password" required>
          </div>
          <div class="col-lg-4 col-xl-5">
          </div>
        </div>
      </div><!-- container -->
      <div class="text-end">
        <button type="submit" class="btn btn-primary">変 更</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
/* 画面サイズ576px以下の時 */
@media (max-width: 576px) {
  /* 戻るボタン */
  .arrow{
	position: relative;
	display: inline-block;
	padding: 0 0 0 16px;
	color: #000;
	vertical-align: middle;
	text-decoration: none;
	font-size: 17px;
  border-bottom: 1px solid #696969;
}
.arrow::before,
.arrow::after{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	content: "";
	vertical-align: middle;
}
.mobile-back::before{
	box-sizing: border-box;
	width: 14px;
	height: 14px;
	border: 1px solid #696969;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.mobile-back::after{
	left: 5px;
	width: 6px;
	height: 6px;
	border-top: 1px solid #696969;
	border-right: 1px solid #696969;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
}
h2 {
  padding-bottom: 8px;
  color: var(--text-primary);
  font-size: 1.25rem;
  text-indent: 2px;
  border-bottom: 2px solid var(--theme-primary);
}

h2 svg {
  vertical-align: sub;
}

button.btn {
  padding: 2px 8px;
}

/* 入力フォーム */
form input:not(:read-only),
form select:not(:read-only),
form textarea:not(:read-only) {
  color: var(--text-primary);
  font-family: var(--font-family) !important;
  font-size: 100%;
  border: 1px solid var(--border-gray);
  background-color: var(--background-primary);
}

form select option {
  color: var(--text-primary);
  background-color: var(--background-primary);
}

form input:focus:not(:read-only),
form select:focus:not(:read-only),
form textarea:focus:not(:read-only),
input.indicate-focus:focus:not(:read-only) {
  border: 1px solid var(--color-blue);
  background-color: var(--hover) !important;
  box-shadow: 0 0 8px -4px var(--color-blue);
  outline: none;
}

form input:read-only {
  border: none;
  background-color: transparent !important;
  outline: none;
}
</style>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import utilities from "@/services/utilities"
import backend from "@/services/backendApi";

export default defineComponent({
  name: "ChangePassword",
  components: {
    Loading
  },
  setup() {
    // vuex::store
    const store = useStore();

    // 共通ユーティリティ読み込み
    const util = utilities;

    // 処理ステータス
    const status = reactive({
      inprogress: false,  // 処理状況
      message: "",        // 結果メッセージ
      isError: false,     // エラーか否か
      executed: false,    // 実行済みか否か
    });

    // パスワード変更対象アカウントの取得
    const password = ref(""); // 新しいパスワード
    const passwordConfirm = ref(""); // 確認用

    // パスワード要件
    const passwordRules = reactive({
      minimum: -1,
      maximum: -1,
      requiredUpper: false,
      requiredSign: false,
    });

    // パスワード変更処理
    const updatePassword = () => {
      // ステータスを初期化し、処理中に変更
      status.inprogress = true;
      status.message = "";
      status.isError = false;

      // パスワード入力一致チェック
      if (password.value !== passwordConfirm.value) {
        // 不一致
        status.inprogress = false;
        status.message = "新しいパスワードと確認用パスワードが一致しません";
        status.isError = true;
        return;
      }

      // API呼び出し
      backend.updatePassword(store.getters.getOwnInfo.accountId, password.value)
        .then(async () => {
          // 成功
          // 実行済に変更
          status.executed = true;
          // 更新成功 メッセージ表示
          store.dispatch("addNotification", {
            id: "updatePassword",
            title: "パスワード変更",
            message: "パスワードを変更しました。",
            type: "success",
            autoClose: true
          });

          // 入力値クリア
          password.value = "";
          passwordConfirm.value = "";
        })
        .catch(error => {
          // 失敗
          switch (error.message) {
            case "入力されたデータ内容にエラーがあります。エラー詳細=新しいパスワードは要件を満たしていません。":
                status.message = "<b>新しいパスワードは要件を満たしていません。以下のパスワード設定ルールに従って再入力してください。</b><br>"
                break;
            default:
                status.message = error.message;
                break;
          }
          status.isError = true;

          store.dispatch("addNotification", {
            id: "updatePassword",
            title: "パスワード変更",
            message: "パスワード変更時にエラーが発生しました。",
            type: "danger"
          });

        }).finally(() => {
          // 処理中状態OFF
          status.inprogress = false;
        });
    }

    // 初期化処理
    const initialize = async () => {
      status.inprogress = true;

      // パスワード要件取得
      await backend.getPasswordRule().then((response) => {
        // 要件をセット
        passwordRules.minimum = response.data.content.minimum;
        passwordRules.maximum = response.data.content.maximum;
        passwordRules.requiredUpper = response.data.content.requiredUpper;
        passwordRules.requiredSign = response.data.content.requiredSign;
        passwordRules.historyLimit = response.data.content.historyLimit;
      }).catch(() => {
        // do nothing
      });

      status.inprogress = false;
    }

    // 初期化実行
    initialize();

    return {
      util,                     // ユーティリティ
      status,                   // ステータス
      password,                 // 新しいパスワード
      passwordConfirm,          // 新しいパスワード（確認）
      passwordRules,            // パスワード要件
      updatePassword,           // パスワード変更処理関数
    };
  }
});
</script>
