<template>
  <div class="container-xl mx-auto">
    <p class="my-3">
      <router-link to="/support/index">＜サポートメニューへ戻る</router-link>
    </p>

    <h2 id="contact" class="mt-3">お問い合わせ</h2>
    <div style="max-width: 1000px;">

      <article>
        <h3>お問い合わせ方法</h3>
        <div class="mb-3">
          <p class="my-3">
            お電話でのお問い合わせと、WEBフォームからのお問い合わせの2種類ございます。<br>
            お電話でお問い合わせいただく場合は、&nbsp;&nbsp;027-322-4673&nbsp;&nbsp;までお問い合わせください。
          </p>
          <div class="my-2 notice d-flex align-items-center border border-warning border-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="text-warning bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            <p class="my-3">
              お問い合わせへのご回答は、当サービスの機能・操作方法やご契約に関する内容に限らせていただきます。<br>
              電子帳簿保存法や税法・税務に関するお問い合わせへのサポートは行っておりません。税務や法律に関する事項については、お手数ではございますが、専門の税理士や法律顧問にご相談いただくことをおすすめいたします。
            </p>
          </div>
        </div>

        <button
          class="btn btn-primary"
          data-bs-toggle="collapse"
          data-bs-target="#contacttail"
          aria-expand="false"
          aria-controls="contacttail"
        >
          WEBフォームからお問い合わせいただく場合は、こちらをクリックしてご参照ください。
        </button>

        <div class="collapse" id="contacttail">
          <div class="card card-body">

            <div class="row mb-3">
              <div class="ti">
                <p>①&nbsp;サポートリクエストフォームの「必須」とついている項目を全て埋めてください。<br>
                  ※入力欄にパスワードの記載はしないでください。</p>
                <a href="/img/support/img_contact_3.png" target="_blank">
                  <img src="/img/support/img_contact_3.png" alt="お問い合わせ" class="mb-5 frame_img">
                </a>
              </div>
              <div class="ti">
                <p>②&nbsp;内容の入力が終わったら、一番下までスクロールし、弊社個人情報の取り扱いにご同意いただき、「個人情報の取り扱いに同意する」にチェックを入れてください。<br>
                  入力内容がよろしければ「入力内容を確認する」を押下します。</p>
                <a href="/img/support/img_contact_4.png" target="_blank">
                  <img src="/img/support/img_contact_4.png" alt="お問い合わせ" class="mb-5 frame_img">
                </a>
              </div>
              <div class="ti">
                <p>③&nbsp;確認画面に切り替わるので、入力内容をご確認ください。<br>
                  確認後、「サポートリクエストを送信する」を押下していただくと、ハヤブサバンドサポート担当へお問い合わせ内容が送信されます。<br>
                  修正したい場合は、「修正する」を押下し、修正後に送信してください。
                </p>
                <a href="/img/support/img_contact_5.png" target="_blank">
                  <img src="/img/support/img_contact_5.png" alt="お問い合わせ" class="mb-5 frame_img">
                </a>
                <p class="ti">※お問い合わせに対する回答は、担当者より順次ご回答申し上げます。</p>
              </div>
            </div>

          </div>
        </div>

      </article>

      <GlobalContact />

    </div>
  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
  position: relative;
  margin-bottom: 20px;
  padding: 0.5rem 0;
  border-bottom: 5px solid;
}

h2:before {
  position: absolute;
  bottom: -0.6rem;
  left: 0rem;
  right: 0rem;
  border-bottom: 1px solid;
  content: '';
}

article {
  margin: 10px 0;
  padding: 10px 0;
}

article h3 {
  position: relative;
  padding-top: 0.6rem;
  padding-bottom: 0.28rem;
  margin: 10px auto 20px;
  font-weight: bold;
  font-size: 16pt;
  border-bottom: 5px solid skyblue;
}

article h3:after {
  position: absolute;
  border-bottom: 5px solid #1e72b1;
  content: " ";
  display: block;
  bottom: -4px;
  width: 13%;
}

h4 {
  margin-bottom: 4px;
  color: var(--color-navy);
  font-size: 14pt;
}
h5 {
  margin-bottom: 4px;
  color: var(--color-navy);
  font-size: 12pt;
}

article svg {
  margin: 20px;
}

.col-md-6{
  padding-right:20px;
  padding-left:20px;
}

/* ボタン */
.button-navy {
  padding: 10px 20px;
  width: 100%;
  color: var(--color-white);
  font-size: 16pt;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: var(--color-navy);
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  display: block;
  cursor: pointer;
}
.button-navy:hover {
  background-color: var(--color-navy-lighten);
  transition: var(--anim-speed);
}
.button-navy:active {
  background-color: var(--color-navy-darken);
}
.contact-frame {
  display: flex;
  width: 97%;
  margin: 20px 1em;
  padding: 15px 10px 0px;
  border: 1px solid #1e72b1;
  border-radius: 4px;
}
.contact-frame h4 {
  border-bottom: 2px solid #1e72b1;
}

.notice {
  
  padding:0 20px;
  margin:0 5em 0 1em;
  width:98%;
  border-radius: 20px;
  background-color: rgb(255,227,157);
}
.notice svg {
  margin:0 20px 0 0;
}

.ti {
  text-indent: -1.3em;
  padding-right: 2em;
  margin-left: 1.5em;
}
.ti2 {
  text-indent: -1.0em;
  margin-left: 1.0em;
}
li.list-group-item {
  text-indent: -1.3em;
  margin-left: 1.5em;
  border:none;
}
li.list-group-item ul {
  padding-left: 0em
}
li.list-group-item ul li {
  list-style: disc;
  padding-left: 0em;

  text-indent: -0.3em;
  margin-left: 1.5em;
}
table {
  border: 1px solid rgb(68,114,196);
}
table th {
  background-color: rgb(68,114,196);
}
table tr:nth-child(2n+1) {
  background-color: rgb(217,226,243);
}

table.num {
	counter-reset: rowCount;
}
table.num > tbody > tr {
	counter-increment: rowCount;
}
table.num > tbody > tr > td:first-child::before {
  content: counter(rowCount);
}
table.num td {
  border-right: 1px solid rgb(68,114,196);
}

.border-double {
  border-bottom: 3px double #666;
}

.font-small {
  font-size: 80%;
}

.frame_img {
  width: 60%;
  margin-left: 10%;
  border: 1px solid #666;
}
</style>

<script>
import { defineComponent } from "vue";

import GlobalContact from "@/components/GlobalContact.vue";
export default defineComponent({
  name: 'SpContact',
  components: {
    GlobalContact,
  },
  setup() {
    return {
    }
  },
});
</script>