export default {
  state: {
    cacheData: {},
  },
  mutations: {
    saveCache(state, obj) {
      const key = Object.keys(obj)[0];
      const value = Object.values(obj)[0];
      state.cacheData[key] = value;
    },
    update(state, obj) {
      const key = obj.key;
      const index = obj.index;
      const value = obj.value;
      state.cacheData[key][index] = value;
    },
    clear(state, key) {
      state.cacheData[key] = null;
    },
    clearAll(state) {
      state.cacheData = {};
    },
  },
  actions: {
    saveCache(context, data) {
      context.commit('saveCache', data);
    },
    updateCache(context, data) {
      context.commit('update', data);
    },
    clearCache(context, key) {
      context.commit('clear', key);
    },
    clearAllCache(context) {
      context.commit('clearAll');
    },
  },
  getters: {
    getCache(context) {
      return context.cacheData;
    },
  }
}
