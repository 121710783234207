<template>
  <div class="container-xl mx-auto">
    <p class="my-3">
      <router-link to="/support/index">＜サポートメニューへ戻る</router-link>
    </p>

    <!-- main contents -->
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <nav class="nav nav-pills flex-column">
            <a class="nav-link active" href="#releasenotes">hayabusaBanD リリースノート</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ms-5 my-1" href="#release-20240829">リリース日：2024年8月29日</a>
              <a class="nav-link ms-5 my-1" href="#release-20240628">リリース日：2024年6月28日</a>
              <a class="nav-link ms-5 my-1" href="#release-20240423">リリース日：2024年4月23日</a>
              <a class="nav-link ms-5 my-1" href="#release-20240328">リリース日：2024年3月28日</a>
              <a class="nav-link ms-5 my-1" href="#release-20240129">リリース日：2024年1月29日</a>
              <a class="nav-link ms-5 my-1" href="#release-20231227">リリース日：2023年12月27日</a>
            </nav>
          </nav>
      </div>

      <div class="col-md-9 col-sm-12">
        <div class="p-5 overflow-y-auto" data-bs-spy="scroll" data-bs-target="#navbar" data-bs-smooth-scroll="true" tabindex="0" style="height:70vh">
          <div id="releasenotes">
            <h2>hayabusaBanD リリースノート</h2>
          </div>
          <p>hayabusaBanDに、以下の新機能および修正プログラムをリリースいたしました。</p>
          <div class="row mb-3">
            <div id="release-20240829">
              <article>
                <h3>リリース日：2024年8月29日</h3>
                  <h4>新機能／機能改善</h4>
                  <h5>【帳票保管機能】</h5>
                  <ul class="mb-3">
                    <li class="mb-2">帳票アップロード時に、アップロードを行う帳票がスキャナ保存分なのか電子取引受領分なのかを選択可能にしました。これにより、電子取引受領として取引先がスキャンして送付した請求書が「スキャナ保存要件を満たさない。」といった警告表示されてしまうのを抑制することが可能になります。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#UploadMode">ご利用者向けマニュアル：[帳票保管機能編]－[帳票保管の方法]の『②(1)モードに関して』</a>
                      </li>
                    </ul>
                  </ul>

                  <ul class="mb-3">
                    <li class="mb-2">上記の変更に伴い帳票詳細画面に電帳法区分を追加しました。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#Denchoho">ご利用者向けマニュアル：[帳票保管機能編]－[帳票保管の便利な機能]の『電帳法区分の変更方法』</a>
                      </li>
                    </ul>
                  </ul>

                  <ul class="mb-3">
                    <li class="mb-2">帳票アップロード時に、グループ解除を1クリックで実施できていましたが、これを廃止し、「グループを編集する」ボタンを押下したのちに、アップロード帳票へのグループ割り当て、および、割り当て解除を行えるように変更しました。これにより、意図しないクリックなどでグループが解除されてしまうことを防ぐことが可能になります。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#UploadGroup">ご利用者向けマニュアル：[帳票保管機能編]－[帳票保管の方法]の『②(2)アップロード先グループ設定に関して』</a>
                      </li>
                    </ul>
                  </ul>

                  <ul class="mb-3">
                    <li class="mb-2">アップロード時のグループ変更に関する操作をグループ変更履歴、アクティビティ履歴、承認履歴に記録を行うようにしました。（当リリース以降の操作から記録が行われます。）</li>
                  </ul>

                  <ul class="mb-3">
                    <li class="mb-2">モバイル端末でのPDFファイルプレビューに対応しました。これにより、スマートフォンやタブレットなど各種モバイル端末で、帳票アップロード時のアップロード対象の確認と、帳票詳細画面での入力作業がPDFプレビューを確認しながら行うことができるようになりました。（※PDFファイルのプレビューは先頭ページのみ閲覧可能です。）</li>
                  </ul>

                  <h5>【帳票閲覧機能】</h5>
                  <ul class="mb-3">
                    <li class="mb-2">当社発行請求書の帳票保管連携時、グループを指定して保管連携を行うことができるようになりました。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#InvoiceLinkage">ご利用者向けマニュアル：[帳票閲覧機能編]の『（保管機能契約済のお客様）当社発行請求書・納品書の帳票保管への保存方法』</a>
                      </li>
                    </ul>
                  </ul>
                  <h4>その他の変更点</h4>
                  <ul class="mb-3">
                    <li class="mb-2">帳票ファイルの差替に関するマニュアルを追加しました。 </li>
                    <ul class="ul-ext mb-3">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#Replacement">ご利用者向けマニュアル：[帳票保管機能編]－[帳票保管の便利な機能]の『帳票ファイルの差替方法』</a>
                      </li>
                    </ul>
                    <li class="mb-2">帳票閲覧機能において、PDFファイルのダウンロード手順に関するマニュアルを追加しました。 </li>
                    <ul class="ul-ext mb-3">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#InvoiceUsage">ご利用者向けマニュアル：[帳票閲覧機能編]の『帳票閲覧の方法』</a>
                      </li>
                    </ul>
                  </ul>
              </article>
            </div><!-- release-20240829 ここまで -->

            <div id="release-20240628">
              <article>
                <h3>リリース日：2024年6月28日</h3>
                  <h4>新機能／機能改善</h4>
                  <ul class="mb-3">
                    <li class="mb-2">ナラティブマップ機能をリリースしました。以前よりリリースされていたマップコードに任意の文字列を登録していただくことで、同一マップコードの帳票と帳票同士を紐づけ「ナラティブマップ」として可視化を行うことができます。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#NarrativeMap">ご利用者向けマニュアル：[帳票保管機能編]の『ナラティブマップの活用』</a>
                      </li>
                    </ul>
                  </ul>
                  <ul class="mb-3">
                    <li class="mb-2">モバイル端末からの利用に対応しました。これにより、スマートフォンやタブレットなど各種モバイル端末のカメラ機能から領収書やレシートなどを撮影してアップロードを行うことが可能になります。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#Mobile">ご利用者向けマニュアル：『モバイル端末でハヤブサバンドを利用する』</a>
                      </li>
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#ScannerSaving">ご利用者向けマニュアル：[hayabusaBanDでできること]－[電子帳簿保存法に関して]の『スキャナ保存での利用に関して』</a>
                      </li>
                    </ul>
                  </ul>
                  <ul class="mb-3">
                    <li class="mb-2">帳票保管機能において、電子帳簿保存法のスキャナ保存要件のうち「一定水準以上の解像度及びカラー画像による読み取り」要件を満たさない帳票データに警告表示を行うようにしました。これにより、要件を満たさない帳票データに関しては差し替え等を行うことが可能になります。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#ScannerAlert">ご利用者向けマニュアル：[帳票保管機能編]の『電子帳簿保存法のスキャナ保存要件に関わる警告に関して』</a>
                      </li>
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#ScannerSaving">ご利用者向けマニュアル：[hayabusaBanDでできること]－[電子帳簿保存法に関して]の『スキャナ保存での利用に関して』</a>
                      </li>
                    </ul>
                  </ul>
                  <ul class="mb-3">
                    <li class="mb-2">帳票定義マスターに「スキャナ保存要件に関する設定」項目を追加しました。これにより、電子帳簿保存法のスキャナ保存対象として取り扱わない帳票種類に関しては警告表示を行わないように制御が可能です。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#EvidenceSettings">ご利用者向けマニュアル：[マスター管理編]の『帳票定義マスター保守』</a>
                      </li>
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#ScannerSaving">ご利用者向けマニュアル：[hayabusaBanDでできること]－[電子帳簿保存法に関して]の『スキャナ保存での利用に関して』</a>
                      </li>
                    </ul>
                  </ul>
                  <ul class="mb-3">
                    <li class="mb-2">ユーザー設定画面、および、ユーザーマスター保守画面に「アップロード警告閾値」設定を追加しました。これにより、モバイル端末からサイズの大きなファイルがアップロードされることを防ぐことが可能です。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#MobileAlert">ご利用者向けマニュアル：[モバイル端末でハヤブサバンドを利用する]の『モバイル端末利用時の警告表示に関して』</a>
                      </li>
                    </ul>
                  </ul>

                  <h4>その他の変更点</h4>
                  <ul class="mb-3">
                    <li class="mb-2">電子帳簿保存法に関するページを追加しました。 </li>
                    <ul class="ul-ext mb-3">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#ElectronicBook">ご利用者向けマニュアル：『電子帳簿保存法に関して』</a>
                      </li>
                    </ul>
                  </ul>
              </article>
            </div><!-- release-20240628 ここまで -->

            <div id="release-20240423">
              <article>
                <h3>リリース日：2024年4月23日</h3>
                  <h4>新機能／機能改善</h4>
                  <ul class="mb-3">
                    <li class="mb-2">ユーザーマスター保守画面で、契約ユーザー数上限と現在の利用ユーザー数を表示するように変更しました。</li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#UserMaster">ご利用者向けマニュアル：[マスター管理編]－[ユーザーマスター保守]の『利用ユーザーを追加する場合』</a>
                      </li>
                    </ul>
                  </ul>

                  <h4>その他の変更点</h4>
                  <ul class="mb-3">
                    <li class="mb-2">帳票保管一覧画面に関するマニュアルを追加しました。 </li>
                    <ul class="ul-ext mb-3">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#StorageList">ご利用者向けマニュアル：[帳票保管機能編]の『帳票保管一覧画面について』</a>
                      </li>
                    </ul>
                    <li class="mb-2">取引先マスター保守画面に関するマニュアルを追加しました。 </li>
                    <ul class="ul-ext mb-3">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#Partner">ご利用者向けマニュアル：[マスター管理編]の『取引先マスター保守』</a>
                      </li>
                    </ul>
                  </ul>

                  <h4>不具合修正</h4>
                  <ul class="mb-3">
                    <li class="mb-2">
                      帳票保管詳細画面で、承認権限と入力権限両方を保持するユーザーが、取引先マスターへの登録後、「更新」ボタンを押下せずに、「承認」ボタンで承認を行うことができてしまう不具合を修正しました。
                    </li>
                    <li class="mb-4">
                      帳票保管詳細画面で、閲覧機能から保管連携を行った当社発行請求書が、取引先マスターの取引先名に「株式会社本島ビジネスセンター」と登録されているにもかかわらず、「マスター登録なし」と扱われる不具合を修正しました。<br>
                      ※「マスター登録済」として扱うには、事前に取引先マスターの取引先名に「株式会社本島ビジネスセンター」のレコード登録をお願いいたします。
                    </li>
                  </ul>
              </article>
            </div><!-- release-20240328 ここまで -->

            <div id="release-20240328">
              <article>
                <h3>リリース日：2024年3月28日</h3>
                  <h4>新機能／機能改善</h4>

                  <h5>【帳票保管機能】</h5>
                  <ul class="mb-3">
                    <li class="mb-2">帳票詳細画面において、帳票種類ドロップダウンの並び替えに対応しました。帳票種類の並び替えを行う場合は、帳票定義マスターの「表示順」項目に1～999の値を設定してください。「表示順」を設定しないか、同じ値の場合はID順での表示が行われます。 </li>
                    <ul class="ul-ext mb-4">
                      ＊＊＊ 関連リンク ＊＊＊
                      <li class="li-ext">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                          <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                        </svg>
                        <a href="/support/manual#EvidenceSettings">ご利用者向けマニュアル：[マスター管理編]－[帳票定義マスター保守]の『表示順』</a>
                      </li>
                    </ul>
                  </ul>

                  <h4>ユーザビリティ向上</h4>
                  <ul class="mb-3">
                    <li class="mb-2">帳票承認権限と帳票更新権限双方を保持するユーザーにおいて、帳票詳細画面で入力が行われた場合には更新を行ってからではないと「承認ボタン」を押下できないように動作を変更しました。</li>
                    <li class="mb-4">帳票詳細画面で「登録ボタン」、および、「更新ボタン」の振る舞いを改善し、「登録」と「登録して閉じる」、および、「更新」と「更新して閉じる」の動作に変更します。これにより、帳票承認権限と帳票更新権限双方を保持するユーザーが、入力内容の登録・更新を行ってから一覧画面に戻らずに承認を行う、といった動作が可能になります。最後に行った動作が自動的に保存され、次回の既定の動作になります。</li>
                  </ul>

                  <h4>その他の変更点</h4>
                  <ul class="mb-3">
                    <li class="mb-2">パスワード付きPDFのパスワードを解除してハヤブサバンドにアップロードする参考手順を記述しました。 </li>
                    <ul class="ul-ext mb-3">
                        ＊＊＊ 関連リンク ＊＊＊
                        <li class="li-ext">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                          </svg>
                          <a href="/support/help#help-04">困ったときは：『パスワード付きPDFをアップロードしたい場合』</a>
                        </li>
                      </ul>
                  </ul>
              </article>
            </div><!-- release-20240328 ここまで -->

            <div id="release-20240129">
              <article>
                <h3>リリース日：2024年1月29日</h3>
                  <h4>新機能／機能改善</h4>

                  <h5>【帳票保管機能】</h5>
                  <ul class="mb-4">
                    <li class="mb-2">保管帳票一覧の「帳票保管ステータス」に表示される使用容量の集計を、リアルタイムから1日ごとに変更しました。 </li>
                  </ul>

                  <h5>【帳票閲覧機能】</h5>
                  <ul class="mb-3">
                    <li>
                      <p class="mb-2">
                        保管連携機能にて帳票を保管する際、取引日として扱う日付を「発行日」と「締日」のいずれかから選択できるようにしました。<br>
                        ※保管連携時のダイアログでの選択は初回のみ可能です。２回目以降に設定を変更する場合は、管理者メニューの「帳票種類変換マスター保守」にて変更してください。（テナントマスター管理者権限が必要です。）
                      </p>
                      <ul class="ul-ext mb-4">
                        ＊＊＊ 関連リンク ＊＊＊
                        <li class="li-ext">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z"/>
                          </svg>
                          <a href="https://youtu.be/YNXWtG1h1aQ" title="YouTube video player" target="_blank">動画でみるhayabusaBanDの紹介とマニュアル：YouTube動画 『帳票閲覧機能編』</a>
                        </li>
                        <li class="li-ext">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                          </svg>
                          <a href="/support/manual#InvoiceLinkage">ご利用者向けマニュアル：[帳票閲覧機能編]の『（保管機能契約済のお客様）当社発行請求書・納品書の帳票保管への保存方法』</a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <h4>ユーザビリティ向上</h4>
                  <ul class="mb-2">
                    <li class="mb-2">帳票詳細画面でPDFファイルを表示したとき、左右方向へのスクロールバーが画面内に表示されるように調整しました。</li>
                    <li class="mb-2">帳票詳細画面で画像ファイルを表示したとき、画像を拡大・縮小・回転できるようにしました。画像をクリックすることで、拡大したり回転したりすることができます。（ただし、拡大・縮小・回転した結果は保存されません。）</li>
                    <li class="mb-2">保管帳票一覧画面のパフォーマンス改善を行いました。</li>
                  </ul>
              </article>
            </div><!-- release-20240129 ここまで -->

            <div id="release-20231227">
              <article>
                <h3>リリース日：2023年12月27日</h3>
                  <h4>新機能／機能改善</h4>

                  <h5>【帳票保管機能】</h5>
                  <ul class="mb-3">
                    <li>
                      <p class="mb-2">「グループ機能」を追加しました。グループマスター保守では、お客様テナント内に「グループ」と呼ばれる帳票データへのアクセス制限を設定することができます。</p>
                      <ul class="ul-ext mb-3">
                        ＊＊＊ 関連リンク ＊＊＊
                        <li class="li-ext">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z"/>
                          </svg>
                          <a href="https://youtu.be/-UEVb7RF80c" title="YouTube video player" target="_blank">動画でみるhayabusaBanDの紹介とマニュアル：YouTube動画 『グループ機能の利用方法』</a>
                        </li>
                        <li class="li-ext">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                          </svg>
                          <a href="/support/manual#Administrator">ご利用者向けマニュアル：[マスター管理編]－[テナント管理者によるユーザーの管理]の『グループに関する説明』</a>
                        </li>
                        <li class="li-ext">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                          </svg>
                          <a href="/support/manual#Group">ご利用者向けマニュアル：[マスター管理編]の『グループマスター保守』</a>
                        </li>
                        <li class="li-ext">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                          </svg>
                          <a href="/support/manual#EvidenceNocontract">ご利用者向けマニュアル：[帳票保管機能編]の『帳票保管の方法』</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p class="mb-2">「マップコード機能」を追加しました。マップコード入力欄に任意のコードや文字列などをご登録いただくことで、他の帳票データとのつながりを考慮した検索を行うことが可能です。</p>
                      <ul class="ul-ext mb-3">
                        ＊＊＊ 関連リンク ＊＊＊
                        <li class="li-ext">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                          </svg>
                          <a href="/support/manual#NarrativeMap">ご利用者向けマニュアル：[帳票保管機能編]の『ナラティブマップの活用』</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p class="mb-2">（OCRオプションご契約のお客様）<br>
                      帳票OCRに関する動作を改善しました。帳票定義マスターに「帳票判別判定値」を追加し、帳票種類の誤判別が少なくなるようにお客様側で閾値を設定していただくことを可能にしました。また、適格請求書発行事業者登録番号のOCRに対応し、高い精度で取引先名称を取得することを可能にしました。</p>
                      <ul class="ul-ext mb-3">
                        ＊＊＊ 関連リンク ＊＊＊
                        <li class="li-ext">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                          </svg>
                          <a href="/support/manual#OcrEvidenceSettings">ご利用者向けマニュアル：[マスター管理編]－[帳票定義マスター保守]の『（OCRオプション契約済のお客様）OCR用設定に関して』</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p class="mb-2">（OCRオプションご契約のお客様）<br>
                      帳票アップロード時に、OCRを実行せずにアップロードを行えるように対応しました。あわせて、当月内でのOCR実行件数を表示するようにしました。</p>
                    </li>
                  </ul>

                  <h5>【帳票閲覧機能】</h5>
                  <ul class="mb-5">
                    <li>
                      弊社から発行された請求書をワンクリックで帳票保管側に保存することが可能になりました。請求書一覧画面で対象請求書・納品書の右端に表示されている縦三点リーダー（︙）をクリックし、「保管連携」を選択してください。
                    </li>
                  </ul>

                  <h4>ユーザビリティ向上</h4>
                  <ul class="mb-2">
                    <li class="mb-2">
                      帳票保管の帳票詳細画面での帳票タグ設定に関して、「登録」、「更新」ボタンなどを押下した際に、帳票タグを反映させるように変更しました。
                    </li>
                    <li class="mb-5">
                      認証トークンの有効期限を延長しました。これにより、セッションタイムアウトの回数を少なくし、快適にご利用いただくことが可能になります。例えば、サービスを1時間以上利用しない場合でも、サインイン状態を維持できます。しかしながら、認証トークンの有効期限が長いと、トークンが盗まれたり、不正に利用されたりするセキュリティリスクも高まります。共用PCでサービスを利用した後、サインアウトせずに放置した場合、他の人があなたのアカウントにアクセスできる可能性があります。そのため、サービスのご利用が済んだ場合は「サインアウト」していただくことを推奨します。サインアウトを行うことで、認証トークンは削除されます。
                      <div class="info d-flex align-items-center border border-info mt-2 mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 18 18">
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg>
                        <p class="my-3">認証トークンとは、ユーザーがログインした際に発行される一時的なパスワードのようなもので、これがあれば、ユーザーは再度ログインすることなく、サービスを利用できます。</p>
                      </div>
                      <div class="info d-flex align-items-center border border-info mt-2 mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 18 18">
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg>
                        <p class="my-3">セッションタイムアウトとは、認証トークンの有効期限が切れ、ユーザーが再度サインインを行う必要がある状態のことをいいます。</p>
                      </div>
                    </li>
                  </ul>

                  <h4>不具合修正</h4>
                  <ul class="mb-5">
                    <li>
                      アカウントロックされてしまったユーザーに対して、テナント管理者（※テナントユーザー管理者権限保持者）がユーザーマスター保守画面からパスワードリセットを行うことができない不具合を修正しました。
                    </li>
                    <li>
                      帳票保管機能で、保管期限を迎えた帳票ファイルの消込は行われるが、入力データが残ってしまう不具合を修正しました。
                    </li>
                  </ul>
              </article>
            </div><!-- release-20231227 ここまで -->

          </div>
        </div>
      </div>
    </div>
    <!-- main contents -->

  </div>
</template>

<style scoped>

h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
  position: relative;
  margin-bottom: 20px;
  padding: 0.5rem 0;
  border-bottom: 5px solid;
}

h2:before {
  position: absolute;
  bottom: -0.6rem;
  left: 0rem;
  right: 0rem;
  border-bottom: 1px solid;
  content: '';
}

article {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

article h3 {
  position: relative;
  padding-top: 0.6rem;
  padding-bottom: 0.28rem;
  margin: 10px auto 20px;
  font-weight: bold;
  font-size: 16pt;
  border-bottom: 5px solid skyblue;
}

article h3:after {
  position: absolute;
  border-bottom: 5px solid #1e72b1;
  content: " ";
  display: block;
  bottom: -4px;
  width: 15%;
}

h4 {
  margin-bottom: 4px;
  color: var(--color-navy);
  font-size: 14pt;
  position: relative;
  padding: 0.6rem 1rem;
  margin-bottom: 0.2rem;
  margin: 0px 0px 20px;
  overflow: hidden;
  font-weight: bold;
  font-size: 16pt;
  display: inline-block;
  border-top: solid 2px #5989cf;
  border-bottom: solid 2px #5989cf;
  width: auto;
}

h5 {
  margin-bottom: 10px;
  color: var(--color-navy);
  font-size: 14pt;
}

article svg {
  margin: 5px 10px;
}

.overflow-y-auto {
  /** bootstrap5.3へ更新前の暫定対応 */
  overflow-y: auto;
  overflow-x: hidden;
}

.ul-ext {
  font-size: 11pt;
  list-style-type: none;
}
.li-ext {
  position: relative;
}
/* .li-ext::after {
  content: '';
  display: block;
  position: absolute;
  top: .5em;
  left: -1.5em;
  width: 10px;
  height: 5px;
  border-left: 2px solid #25AF01;
  border-bottom: 2px solid #25AF01;
  transform: rotate(-45deg);
} */

a.nav-link{
  padding: 2px;
}

.info{
  font-size: 11pt;
  padding:0 20px;
  margin:0 5em 0 0;
  width:85%;
  border-radius: 20px;
  background-color: #f3fafe;
}
.info svg{
  color: #87cefa;
  margin:0 20px 0 0;
}

.notice{
  font-size: 11pt;
  padding:0 20px;
  margin:0 5em 0 0;
  width:85%;
  border-radius: 20px;
  background-color: rgb(255,227,157);
}
.notice svg{
  margin:0 20px 0 0;
}

</style>

<script>
export default {
  name: 'SpReleaseNotes',
  components: {
  },
  setup() {
    return {
    }
  }
}
</script>